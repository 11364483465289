import React, { ReactElement, useEffect, useState } from 'react'

import styled from 'styled-components'

import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'

import { FaTrashAlt } from 'react-icons/fa'
import { BsPencil } from 'react-icons/bs'

import { StampsBackgroundImages } from '../stamps/components'
import { ButtomBack, StampsHeader, StampsHeaderSpan } from '../stamps/Stamps'
import { CardCollection } from './components'
import { CollectionStampsCarrousel } from './components/collection_stamps_carrousel'

import { CollectionClientAPI } from '@api/collection/CollectionClientAPI'
import { ICollection } from '@api/collection/types'
import { ConfirmModal, LoadingRenderIf, RenderIf, SuccessModal } from '@ui/components'

import { isAdmin } from '@src/contexts'
import ModalCreateCollection, { FormCollectionValues } from './components/modal_create_collection/ModalCreateCollection'
import { ModalEditCollection } from './components/modal_edit_collection'
import { IEditCollectionValues } from './components/modal_edit_collection/ModalEditCollection'
import StampClientAPI from '@src/api/stamp/StampClientAPI'
import { IStamp } from '@src/api/stamp/types'

const CreateButton = styled.button `
  background-color: #1A1A1A;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float:right;
  margin-right:8vw;
  margin-top:2vw;

  @media(max-width:800px){
    font-size:13px;
    width:100%;
    margin-top:5vw;

  }
`

export const CollectionOptions = styled.div `
  align-itens:right;
  position:relative;
  width:100%;
  display:block;

  @media (max-width: 800px)
  {
    width:100%;
    display:block
  }
`

export const DeleteCollectionButton = styled.button `
  background: transparent;
  color: red;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5%;
  border: 1px solid red;
  padding: 4px 10px;
  width:5%;
  height:40px;
  float:right;

  &:hover {
    background: red;
    color: white;
  }

  @media(max-width:800px){
    align-itens:center;
    margin-right:15px;
    width:30%;
  }
`

export const EditCollectionButton = styled.button `
  background: transparent;
  color: black;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5%;
  border: 1px solid black;
  padding: 4px 10px;
  width:5%;
  height:40px;
  float:right;
  
  &:hover {
    background: black;
    color: white;
  }

  margin-right:10px;

  @media(max-width:800px){
    align-itens:center;
    margin-right:15px;
    width:30%;
  }
`

const SectionDescriptionMobile = styled.div `
  width:100vw;
  text-align:center;
  justify-content:center;

`

const collectionClientAPI = new CollectionClientAPI()
const stampClientAPI = new StampClientAPI()

const Collections = (): ReactElement => {

  const [collections, setCollections] = useState<ICollection[] | []>([])
  const [allStamps, setAllStamps] = useState<IStamp[] | []>([])
	const [loading, setLoading] = useState<Boolean>(true)

  const [modalCreateCollection, setModalCreateCollection] = useState<boolean>(false)
  const [modalEditCollection, setModalEditCollection] = useState<boolean>(false)

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

  const [collectionId, setCollectionId] = useState<number>(0)
  const [haveStamps, setHaveStamps] = useState<boolean>(false)
  const [editFields, setEditFields] = useState<IEditCollectionValues>()

  const { innerWidth: width, innerHeight: height } = window;

  const navigate = useNavigate();

  const handleModalCreate = (create:boolean) => {
    setModalCreateCollection(create)
  }

  const handleModalEdit = (edit:boolean) => {
    setModalEditCollection(edit)
  }


  const handleSuccessAction = (show:boolean) => {

    setShowSuccessModal(show)
    
    if(show === false){
      navigate(0)
    }
  }

  const handleConfirmAction = (show:boolean) => {
    setShowConfirmModal(show)
  }

  const createCollection = (values: FormCollectionValues) => {
    collectionClientAPI.sendCollection(values)
    .then(() => {
      handleModalCreate(false)
      setShowSuccessModal(true)
    })
  }

  const editCollection = (values: IEditCollectionValues) => {
    collectionClientAPI.updateCollection(values)
    .then(() => {
      handleModalEdit(false)
      setShowSuccessModal(true)
    })
  }

  const deleteCollection = () => {

    collectionClientAPI.deleteCollection(collectionId)
    .then(() => {
      handleConfirmAction(false)
      setShowSuccessModal(true)
    })
  }

  const CollectionOptionsComponent = (props: ICollection) => {

    const haveStamps = props.stamp.length ? true : false;

    return (
      <CollectionOptions>
        <DeleteCollectionButton onClick={() => {
          setCollectionId(props.id)
          setHaveStamps(haveStamps)
          handleConfirmAction(true)
        }}>
          <FaTrashAlt />
        </DeleteCollectionButton>

        <EditCollectionButton onClick={() => {
          setEditFields({
            id: props.id,
            name_collection: props.name_collection,
            description_collection: props.description_collection,
            image_collection: props.image_collection
          })
          handleModalEdit(true)
        }}>
          <BsPencil />
        </EditCollectionButton>
      </CollectionOptions>
    )
  }

  useEffect(() => {
    setLoading(true)

    collectionClientAPI.getCollections()
      .then((response) => setCollections(response))
      .then(() => stampClientAPI.getStamps())
      .then((response) => setAllStamps(response))
      .then(() => setLoading(false))
  }, [])

  return (
    <>
      <StampsBackgroundImages />

      <StampsHeader>
        <ButtomBack onClick={() => navigate(-1)}>
          <FaAngleLeft style={{ color: '#252525', marginBottom: 10, marginRight: 1 }} />
        </ButtomBack>
        <StampsHeaderSpan>
          COLEÇÕES
        </StampsHeaderSpan>
      </StampsHeader>

      <RenderIf condition={isAdmin()}>
        <CreateButton onClick={() => handleModalCreate(true)}>Criar Coleção</CreateButton>
      </RenderIf>

      <RenderIf condition={isAdmin()}>
        <ModalCreateCollection
          isOpen={modalCreateCollection}
          onClose={() => handleModalCreate(false)}
          onSubmit={(values) => createCollection(values)}
          initialValues={{
            name_collection: '',
            description_collection: '',
            image_collection: '',
          }}
        />
      </RenderIf>

      <RenderIf condition={isAdmin()}>

        {editFields &&
          <ModalEditCollection
            isOpen={modalEditCollection}
            onClose={() => handleModalEdit(false)}
            onSubmit={(values) => editCollection(values)}
            initialValues={editFields}
          />
        }
        
      </RenderIf>

      <Container style={{ marginTop: '16vh' }}>
        <LoadingRenderIf condition={!loading && Boolean(collections)}>
          {collections.map((collection: ICollection) => (
            <div key={collection.id}>
              <Row
                style={{ paddingTop: 20, paddingBottom: 20, borderTop: 'solid 1px black' }}
              >
                <RenderIf condition={isAdmin()}>
                  <CollectionOptionsComponent 
                    id={collection.id}
                    name_collection={collection.name_collection}
                    description_collection={collection.description_collection}
                    stamp={collection.stamp ? collection.stamp : []}
                    image_collection={collection.image_collection}
                    created_at={collection.created_at}
                    updated_at={collection.updated_at}
                  />
                </RenderIf>
                <Col xs={4}>
                  <CardCollection
                    key={collection.id}
                    id={collection.id}
                    srcImg={collection.image_collection}
                  />
                </Col>

                <Col>


                  <RenderIf condition={innerWidth <= 800}>
                    <SectionDescriptionMobile>
                      <Row>
                        <Col>
                          <h1>{collection.name_collection}</h1>
                        </Col>
                      </Row>

                      <Row>
                        <p>{collection.description_collection}</p>
                      </Row>
                    </SectionDescriptionMobile>
                  </RenderIf>

                  <RenderIf condition={innerWidth > 800}>
                    <Row>
                      <Col>
                        <h1>{collection.name_collection}</h1>
                      </Col>
                    </Row>

                    <Row>
                      <p>{collection.description_collection}</p>
                    </Row>
                  </RenderIf>

                  <Row style={{ width: '96%', marginLeft: '5%' }}>
                    <CollectionStampsCarrousel collectionId={collection.id} stamps={collection.stamp} allStamps={allStamps}/>                        
                  </Row>
                </Col>
              </Row>
            </div>
            
          ))}
        </LoadingRenderIf>

        <SuccessModal
          message='Operação realizada com sucesso!'
          show={showSuccessModal}
          onClose={() => handleSuccessAction(false)}
        />

        <ConfirmModal
          title={'Deseja excluir a coleção?'}
          message={haveStamps ? 'Essa coleção possui estampas cadastradas!' : ''}
          show={showConfirmModal}
          onConfirm={() => deleteCollection()}
          onClose={() => {
            setCollectionId(0)
            setHaveStamps(false)
            handleConfirmAction(false)
          }}
        />
        
      </Container>
    </>
  )
}

export { Collections }
export default Collections
