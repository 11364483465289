import {
  DivAboutUs,
  TitleAboutUs,
  DescriptionAboutUs,
  ButtonAboutUs,
  Container,
  Image,
} from './TencenciesStyledComponents'

export {
  DivAboutUs,
  TitleAboutUs,
  DescriptionAboutUs,
  ButtonAboutUs,
  Container,
  Image,
}
