import { createContext, useContext, useEffect, useState } from 'react';

type User = {
  isLoggedIn: boolean;
  token?: string;
}

type UserContextType = [User, (user: User) => void];

const UserContext = createContext<UserContextType>([
  { isLoggedIn: false },
  () => {}
]);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const initialUser = localStorage.getItem('user');
  const [user, setUser] = useState<User>(initialUser ? JSON.parse(initialUser) : { isLoggedIn: false });

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);