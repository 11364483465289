import React, { ReactElement, useState } from 'react'

import { Carousel, Stack } from 'react-bootstrap'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { IStamp, IStampBackEnd } from '@api/stamp/types'
import { NextIconCarrouselCollection, PrevIconCarrouselCollection, CardPrint, ModalIncludeStamps } from './components'

const CarrouselStyled = styled(Carousel)`
  a {
    width: 0;
  }
`

interface ICollectionStampsCarrousel {
  collectionId: number,
  stamps: IStampBackEnd[],
  allStamps: IStamp[]
}

const NoStampsDiv = styled.div`
  font-size: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 9%;
  margin-left: -5%;

  @media (max-width: 800px)
  {
    width:100%;
    display:block;
  }
`

const CollectionStampsCarrousel = (props: ICollectionStampsCarrousel): ReactElement => {

  const stacks = [];
  const navigate = useNavigate();

  const { innerWidth: width, innerHeight: height } = window;
  var quant = innerWidth <= 800 ? 1 : 3

  for (let i = 0; i < props.stamps.length; i += quant) {
    const stack = props.stamps.slice(i, i + quant);
    stacks.push(stack);
  }

  return (
    <>
      <ModalIncludeStamps
        collectionId={props.collectionId}
        allStamps={props.allStamps} 
        collectionStamps={props.stamps}
      />
      {props.stamps.length ? (
        <CarrouselStyled
        nextIcon={<NextIconCarrouselCollection />}
        prevIcon={<PrevIconCarrouselCollection />}
        indicators={false}
        style={{ paddingTop: 20 }}
        >
          {renderStacks(stacks)}
        </CarrouselStyled>
      ) : (
        <NoStampsDiv>
          <div>
            Esta coleção não possui estampas.
          </div>
        </NoStampsDiv>
      )}
    </>
  )
}

const renderStacks = (stacks: IStampBackEnd[][]) => {
  return stacks.map((stack, index) => (
    <Carousel.Item key={index}>
      <Stack
        direction="horizontal"
        className="h-100 justify-content-center align-items-center"
        gap={5}
      >
        {stack.map((item) => (
          <CardPrint
            key={item.id}
            stampId={item.id}
            srcImg={process.env.PUBLIC_URL + item.image_stamp}
            printTitle={item.name_stamp}
          />
        ))}
      </Stack>
    </Carousel.Item>
  ));
}

export { CollectionStampsCarrousel }
export default CollectionStampsCarrousel
