import React from 'react'
import styled from 'styled-components';

import { IShowInterface } from '@src/interfaces';
import { useNavigate } from 'react-router-dom';

const TransparentButton = styled.button<IShowInterface>`
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-size: 1em;
  padding: 0.5em 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 23%;
  display: ${props => props.show ? 'show' : 'none'};
  &:hover {
    background-color: white;
    color: black;
  }
`;

interface IViewMoreButtonCardPrint {
  showButton: boolean,
  id: number,
}

const ViewMoreButtonCardPrint = (props: IViewMoreButtonCardPrint) => {
  const navigate = useNavigate();

  return (
    <>
      <TransparentButton
        show={props.showButton}
        onClick={() => navigate('stamps/'+props.id)}
      >
        Ver mais
      </TransparentButton>
    </>
  )
}

export { ViewMoreButtonCardPrint }
export default ViewMoreButtonCardPrint
