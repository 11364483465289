import React, { useState } from "react";

import { BlogClientAPI } from "@api/blog";

import { IPost } from "@src/api/blog/types";
import { ConfirmModal, RenderIf, SuccessModal } from "@src/ui/components";

interface IEditPostModalProps {
  onClose(): void;
  actualIndex: number,
  handleIndex(index: number): void,
  post: IPost,
  openSucessModal(): void
}

const blogClientAPI = new BlogClientAPI()

export const DeletePostModal = (props: IEditPostModalProps) => {

  const handleSubmit = (id: number) => {
    
    blogClientAPI.deletePost(id)
    props.handleIndex(props.actualIndex + 1)
    props.onClose()
    props.openSucessModal()
  };

  return (
    <>
      <ConfirmModal 
        show={true} 
        onClose={props.onClose}
        onConfirm={() => handleSubmit(props.post.id)}
        title={"Deseja excluir a postagem?"} 
        message={""}
      />
    </>
  );
};