import { AxiosResponse } from 'axios'

import { ClienteAPI } from '@api/ClientAPI'

class PdfClientAPI extends ClienteAPI {
  public baseURL: string = 'api/generate-pdf'

  async generatePdf (id: number): Promise<any> {
    const response : AxiosResponse<any> = await this.axios.get(
      `${this.baseURL}/${id}`
      )

    return response.data
  }
}

export { PdfClientAPI }
export default PdfClientAPI
