import React, { FC } from 'react';

import styled from 'styled-components';

import { MdClose } from 'react-icons/md';

interface ErrorModalProps {
  message: string;
  onClose: () => void;
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  background-color: #fff;
  padding: 50px 120px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
  max-width: 500px;
  text-align: center;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 20px;
`;

const CloseIcon = styled(MdClose)`
  color: #ff0000;
  font-size: 30px;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const CloseButtonStyled = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #ff0000;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
`;

const ErrorModal: FC<ErrorModalProps> = ({ message, onClose }) => {
  return (
    <Overlay>
      <Modal>
        <CloseIconWrapper>
          <CloseIcon />
        </CloseIconWrapper>
        <ErrorMessage>{message}</ErrorMessage>
        <CloseButtonStyled onClick={onClose}>Fechar</CloseButtonStyled>
      </Modal>
    </Overlay>
  );
};

export { ErrorModal };
export default ErrorModal;
