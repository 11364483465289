import React from 'react';
import { IState } from '../types';

const Paraiba = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick} id="shape_pb"  d="m 435.46824,161.79394 c 0.65739,-0.73516 1.07303,-1.80029 1.97217,-2.20548 2.0088,-0.90524 4.46314,0.4591 6.60993,-0.0369 1.57348,-0.36354 3.75635,-0.6053 4.36558,-2.10091 0.51702,-1.26924 2.82492,-1.23439 4.28731,-1.19248 1.25048,-0.0523 2.29431,0.003 2.91741,-0.78802 0.98953,-1.25694 0.18214,-3.19857 0.12889,-4.79739 -0.0386,-1.15931 -0.58704,-4.25305 -1.20857,-5.23243 -6.00145,-0.38968 -5.99459,-0.46153 -11.09039,-0.851 -1.08612,-0.58812 -2.4624,-1.42422 -3.52352,-0.79211 -1.01054,0.60198 0.49904,2.46362 0,3.52876 -0.5712,1.21916 -1.75887,2.51662 -3.10347,2.58498 -1.61996,0.0824 -3.06381,-1.8957 -4.0356,-2.71908 -1.45584,0.12447 -3.20042,0.63419 -4.49199,-0.12447 -0.67682,-0.39756 -1.15483,-1.24077 -1.20322,-2.02422 -0.1606,-2.60036 5.11782,-5.46716 3.13655,-7.15897 -1.61282,-1.37719 -3.48887,2.42832 -5.35575,3.43459 -1.91683,1.03319 -3.83588,2.21652 -5.96885,2.65485 -1.53499,0.31544 -3.34699,-0.85106 -4.70078,-0.0618 -1.0562,0.61577 -1.48907,2.02417 -1.76484,3.21525 -0.65131,2.8131 -1.30395,6.32669 0.40662,8.653 1.33064,1.80962 2.76066,2.90313 6.33074,2.30863 2.50944,-0.41788 3.89965,-1.5985 6.58421,-3.85948 1.47824,-1.42636 3.14028,-2.07873 4.62493,-1.44319 0.89568,0.38342 1.21031,1.35593 1.29598,2.61988 0.0929,1.3707 -2.34295,3.38993 -2.04408,5.24118 0.23406,1.44981 1.28661,2.93117 2.81116,3.39234 1.19484,-0.003 2.67685,-1.30563 3.01958,-2.24549 z"></path>
      <circle onClick={props.onClick}  r="12" cx="448" cy="151"></circle>
      <text x="441" y="155">PB</text>
    </>
  );
};

export { Paraiba };
export default Paraiba;