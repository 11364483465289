import React, { useState } from "react";

import { useFormik } from "formik";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";


import { ICarouselImage } from "@api/home/types";
import { CarrouselImageClientAPI } from "@api/carrousel_image";
import { SuccessModal } from "@src/ui/components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const PhotoContainer = styled.div`
  position: relative;
  width: 15vw;
`;

const Photo = styled.img`
  width: 100%;
  height: 30vh;
  cursor: pointer;
  transition: filter 0.5s ease;
  &:hover {
    filter: brightness(70%);
  }
  border: 1px solid black;
`;


const InputFile = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

interface Values {
  inputFilePhoto1: File | null;
  inputFilePhoto2: File | null;
  inputFilePhoto3: File | null;
}

const initialValues: Values = {
  inputFilePhoto1: null,
  inputFilePhoto2: null,
  inputFilePhoto3: null,
};

interface ICarrouselEdit {
  carrouselImages: ICarouselImage[]
}

const carrouselImageClientAPI = new CarrouselImageClientAPI()

const CarrouselEdit = (props: ICarrouselEdit) => {
  const [imageOne, setImageOne] = useState<string>(props.carrouselImages[0].image_base)
  const [imageTwo, setImageTwo] = useState<string>(props.carrouselImages[1].image_base)
  const [imageThree, setImageThree] = useState<string>(props.carrouselImages[2].image_base)
  const [imageOneFile, setImageOneFile] = useState<File>()
  const [imageTwoFile, setImageTwoFile] = useState<File>()
  const [imageThreeFile, setImageThreeFile] = useState<File>()
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false)
  const navigate = useNavigate();

  const formik = useFormik<Values>({
    initialValues,
    onSubmit: () => {
      const valuesToSave = [
        {
          id: props.carrouselImages[0].id,
          image_base: imageOneFile
        },
        {
          id: props.carrouselImages[1].id,
          image_base: imageTwoFile
        },
        {
          id: props.carrouselImages[2].id,
          image_base: imageThreeFile
        },
      ]

      valuesToSave.map((carrousel) => {
        if (carrousel.image_base) {
          carrouselImageClientAPI.updateImage(carrousel.id, carrousel.image_base)
        }
      })
      setShowModalSuccess(true)
    },
  });

  const handleFileInputChange = (e:any) => {
    const file = e.target.files[0]
    const reader = new FileReader()
  
    reader.readAsDataURL(file)
  
    reader.onloadend = () => {
  
      const base64data = ''+reader.result
      switch (e.target.name) {
        case 'inputFilePhoto1':
          setImageOne(base64data)
          setImageOneFile(file)
          break;
      
        case 'inputFilePhoto2':
          setImageTwo(base64data)
          setImageTwoFile(file)
          break;
      
        case 'inputFilePhoto3':
          setImageThree(base64data)
          setImageThreeFile(file)
          break;
      
        default:
          break;
      }
    }
  }

  return (
    <>
      <Container>
        <PhotoContainer>
          <Photo src={process.env.PUBLIC_URL + imageOne} />
          <InputFile name="inputFilePhoto1" type="file" accept="image/*" onChange={handleFileInputChange} />
        </PhotoContainer>

        <PhotoContainer>
          <Photo src={process.env.PUBLIC_URL + imageTwo} />
          <InputFile name="inputFilePhoto2" type="file" accept="image/*" onChange={handleFileInputChange} />
        </PhotoContainer>

        <PhotoContainer>
          <Photo src={process.env.PUBLIC_URL + imageThree} />
          <InputFile name="inputFilePhoto3" type="file" accept="image/*" onChange={handleFileInputChange} />
        </PhotoContainer>
      </Container>
      <Form onSubmit={formik.handleSubmit}>
        <Button type="submit">Salvar</Button>
      </Form>

      <SuccessModal
        message='Operação realizada com sucesso!'
        show={showModalSuccess}
        onClose={() => {
          navigate(0)
        }}
      />
    </>
  );
};

export { CarrouselEdit };
export default CarrouselEdit;
