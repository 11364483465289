import React from 'react';
import { IState } from '../types';

const MatoGrossoDoSul = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick}  d="m 215.67236,335.104 c 0.62652,2.69297 0.80879,11.82233 3.03708,13.03775 7.2336,-0.99637 4.95537,-6.5452 13.20226,-2.51297 1.05732,-4.34407 1.20806,-5.72127 2.44472,-8.34159 0.98037,-2.07728 2.17261,-4.15524 3.87284,-5.69972 2.55591,-2.32179 6.37629,-2.83487 9.08651,-4.97452 1.73754,-1.37174 3.13512,-3.14829 4.45411,-4.92621 2.17287,-2.92891 3.04404,-4.57267 5.63855,-9.3758 2.59451,-4.80313 4.99914,-8.55852 6.88199,-12.68748 0.77236,-1.69373 1.13522,-3.29844 1.7337,-5.30863 -6.13992,-2.75355 -13.18855,-5.62998 -16.15794,-7.08836 -2.13509,-1.01069 -4.09573,-1.09848 -5.25683,-2.49201 -0.80513,-0.96631 1.07519,-2.79664 -0.69745,-3.4367 -1.36032,-0.49118 -3.7665,-1.83089 -3.29639,-3.44234 0.81018,-2.61565 -2.36246,-1.56903 -3.27156,-3.07405 -0.61435,-1.01707 -0.74951,-4.66026 -1.93081,-4.52935 -6.84967,0.75908 -12.46432,1.76908 -15.80513,1.62815 -3.95692,-0.32485 -7.33897,-3.53384 -11.30298,-3.75595 -2.18739,-0.12256 -4.16633,0.58219 -6.42962,1.36286 -2.99202,1.03199 -6.98146,6.0135 -8.85255,10.28454 -0.61856,1.85544 -2.14561,12.59856 -5.36109,18.49732 0.85746,1.29388 1.71492,2.58777 2.57238,3.88165 -0.81957,1.08663 -2.22464,2.04782 -2.67306,3.21397 0.97546,3.12462 2.1644,6.21915 3.00727,9.36245 -0.24612,3.81344 0.0512,5.64047 -1.19167,9.30686 4.34941,1.26963 7.46571,-0.40941 11.96819,-0.0833 0.89986,-0.66632 4.9804,-3.95962 6.14124,-3.14951 6.37353,3.87286 6.0294,6.70349 8.18624,14.30294"></path>
      <text x="218" y="305">MS</text>
    </>
  );
};

export { MatoGrossoDoSul };
export default MatoGrossoDoSul;
