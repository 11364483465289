import React from 'react'
import styled from 'styled-components'

import { isAdmin, isRepresentative, removeUserAuth } from '@src/contexts/user_context'
import { TitleHome } from './components'
import { useNavigate } from 'react-router-dom'
import { RenderIf } from '@src/ui/components'

const HeaderStyled = styled.div`

  top: 0;
  width: 100%;
  height: 9wv;
  display: block;
 
  background-color: #252525;


  @media(max-width:800px){
    width:100%;
  }
`

const DivStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const DivLogoutStyled = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`

const LogoutButton = styled.button`
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #6d6d6d;
  }
`;

const Header = () => {
  const navigate = useNavigate();

  const logOut = () => {
    removeUserAuth()
    navigate(0)
  }

  return (
    <>
      <DivStyled>
        <HeaderStyled>
          <TitleHome />
        </HeaderStyled>
        <RenderIf condition={isAdmin() || isRepresentative()}>
          <DivLogoutStyled>
            <LogoutButton onClick={() => logOut()}>Desconectar</LogoutButton>
          </DivLogoutStyled>
        </RenderIf>
      </DivStyled>
    </>
  )
}

export { Header }
export default Header
