import { IRepresentative } from '@src/api/representative';
import React from 'react'

import styled from 'styled-components';

const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
`;

const ContactName = styled.h3`
  font-size: 18px;
  margin-bottom: 5px;
`;

const ContactInfo = styled.p`
  margin: 5px;
`;

const ContactImage = styled.div`
  img {
    width:15%;
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
`;

interface IRepresentativeList {
  representative: IRepresentative,
}

const prepareCellPhoneNumber = (cellPhoneNumber: string) => {

  const cellPhone = cellPhoneNumber.match(/^(\d{2})(\d{5})(\d{4})$/);

  if(cellPhone){
    return '(' + cellPhone[1] + ') ' + cellPhone[2] + '-' + cellPhone[3];
  }
}

const RepresentativeList = (props: IRepresentativeList) => {
  return (
    <>
      <ContactCard>
        <ContactName>{props.representative.name_representative}</ContactName>
        <ContactInfo>{props.representative.email_representative}</ContactInfo>
        <ContactInfo>{prepareCellPhoneNumber(props.representative.cellphone_number_representative)}</ContactInfo>
        <ContactImage>
          <a target='_blank' href={'https://wa.me/55'+props.representative.cellphone_number_representative}>
            <img src={process.env.PUBLIC_URL + '/imgs/whatsapp.png'}></img>
          </a>
        </ContactImage>
      </ContactCard>
    </>
  )
}

export { RepresentativeList }
export default RepresentativeList
