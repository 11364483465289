import React from 'react';
import { IState } from '../types';

const EspiritoSanto = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick}  d="m 378.36793,315.90595 c 0.97738,-0.2519 1.91685,-0.5871 2.74917,-1.1577 0.9434,-0.46306 1.38603,-1.48549 2.0905,-2.21544 0.77555,-1.13012 1.35721,-2.41046 2.45475,-3.29106 0.41373,-0.40299 0.82744,-0.80597 1.24116,-1.20895 0.39424,-1.17102 -1.80739,-3.53766 0.22738,-3.67637 0.94553,0.27061 1.3906,-0.44046 1.89196,-1.06085 1.04255,-1.09399 1.53763,-2.54565 2.31159,-3.81513 0.40861,-0.78315 0.25755,-1.73225 0.42901,-2.58572 0.39907,-3.72064 2.58716,-8.56152 2.98624,-12.28216 -2.12173,-1.46667 -4.32303,-2.5334 -6.7949,-3.08718 -1.96951,-0.44123 -4.23818,-1.26826 -5.87649,-0.19259 -0.7789,0.51141 -0.68443,1.73333 -1.02664,2.6 -0.88976,0 -2.52282,-0.48189 -3.05262,0.37341 -0.49196,0.7942 0.036,0.89693 1.00663,2.60424 -0.25409,1.47236 -0.42599,2.26436 -2.01336,3.15012 0.5256,1.34392 2.28431,3.73075 2.15185,5.77435 -0.1992,3.07336 -2.40677,5.77578 -4.3317,8.21866 -1.80788,2.29435 -5.19579,4.23722 -6.60309,5.82393 -1.20027,1.35327 -0.59023,2.84706 -0.24628,4.21329 0.35919,1.42677 0.93786,1.71742 1.8705,4.15836 3.46069,0.49466 4.38495,0.704 6.42374,1.08052 1.56428,-1.42029 1.14909,-1.81741 2.1106,-3.42373 z"></path>
      <circle onClick={props.onClick} r="12" cx="391" cy="304"></circle>
			<text x="384" y="308">ES</text>
    </>
  );
};

export { EspiritoSanto };
export default EspiritoSanto;
