import React from 'react';
import { IState } from '../types';

const DistritoFederal = (props: IState) => {
  return (
    <>
      <circle onClick={props.onClick}  r="12" cx="301" cy="251"></circle>
      <text x="294" y="255">DF</text>
    </>
  );
};

export { DistritoFederal };
export default DistritoFederal;