import styled from "styled-components"

const MissionVisionAndValuesSection = styled.div `
  width: 80%;
  display:block;
  margin-left:auto;
  margin-right:auto;
  background-color: #252525;
  height: auto;
  margin-top:10vh;
  padding-top: 90px;
  padding-bottom: 90px;

  @media(max-width:800px){
    width:100%;
    padding:5px;
    margin:20;
    font-size:10px;
  }
`

const MissionVisionAndValuesImage = styled.img `
  width:30%;
  display:block;
  margin-left:auto;
  margin-right:auto;
  margin-top:10px;
  margin-bottom:10px;


  @media(max-width:800px){
    width:50%;
    padding:0;
    margin:0;
    margin-top:20px;
    margin-bottom:20px;
    margin-left:auto;
    margin-right:auto;
    display:block;
  }

`

const MissionVisionAndValuesSubTitle = styled.h2`
  width: 100%;
  font-size: 24px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-top:5vh
`

const MissionVisionAndValuesDescription = styled.p `
  width: 100%;
  font-size: 16px;
  color: #fff;
  margin-left: 0px;
  padding-top: 40px;
  text-align: center;
  padding-left: 5vw;
  padding-right: 5vw;

  @media(max-width:800px){
    width:80%;
    padding:0;
    margin:0;
    font-size: 16px;
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
`
export {
    MissionVisionAndValuesSection,
    MissionVisionAndValuesSubTitle,
    MissionVisionAndValuesDescription,
    MissionVisionAndValuesImage
}
  