import React, { ReactElement, useState } from 'react'

import { Card } from 'react-bootstrap'
import styled from 'styled-components'

import { ViewMoreButtonCardPrint } from './components'

const CardStyled = styled(Card)`
  width: 28%;
  height: 290px;
  transition: 400ms;
  &:hover{
    opacity: 0.5;
  }

  @media(max-width:800px){
    width:100%;
    height:100%;
  }
`

const CardImgStyled = styled(Card.Img)`
  width: 100%;
  height: 100%;
  border-radius: 30px;
`

const CardImgOverlayStyled = styled(Card.ImgOverlay)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
`

interface ICardPrint {
  srcImg: string,
  printTitle: string,
  id: number,
}

const CardPrint = (props: ICardPrint): ReactElement => {
  const [showButton, setShowButton] = useState(false)

  return (
    <>
      <CardStyled
        className='text-white'
        style={{ borderRadius: 30 }}
        onMouseOver={() => setShowButton(true)}
        onMouseOut={() => setShowButton(false)}
      >
        <CardImgStyled
          src={process.env.PUBLIC_URL + props.srcImg}
        />
        <CardImgOverlayStyled>
          <ViewMoreButtonCardPrint id={props.id} showButton={showButton} />

          <Card.Title style={{ wordBreak: 'break-all', textAlign: 'center' }}>
            <p>
              {props.printTitle}
            </p>
          </Card.Title>
        </CardImgOverlayStyled>
      </CardStyled>
    </>
  )
}

export { CardPrint }
export default CardPrint
