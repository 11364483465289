import React, { useState } from 'react';


import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { StyledForm, StyledField, StyledErrorMessage, StyledButton } from './components';
import { useUser } from '@src/contexts';
import { LoginClientAPI } from '@api/login';
import { ErrorModal, RenderIf } from '@src/ui/components';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Endereço de e-mail inválido.')
    .required('E-mail é obrigatório.'),
  password: Yup.string()
    .min(6, 'Senha deve ter no mínimo 6 dígitos.')
    .required('Senha é obrigatória.')
});

interface IInitialValuesLogin {
  email: string,
  password: string,
}

const initialValuesLogin = {
  email: '',
  password: ''
}

const loginClientAPI = new LoginClientAPI()

const LoginForm = () => {
  const [user, setUser] = useUser();
  const [loading, setLoading] = useState<boolean>(false)
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const navigate = useNavigate();

  const handleSubmit = (values: IInitialValuesLogin) => {
    setLoading(true)

    loginClientAPI.login(values)
      .then((response) => {
        setUser({ isLoggedIn: true, token: response.token });
        navigate('/');
      }).catch((err) => {
        setLoading(false)
        setShowErrorModal(true)
      })
  };

  return (
    <>
      <RenderIf condition={showErrorModal}>
        <ErrorModal
          message="Falha ao logar!"
          onClose={() => setShowErrorModal(false)}
        />
      </RenderIf>

      <Formik
        initialValues={initialValuesLogin}
        validationSchema={LoginSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <StyledForm>
            <StyledField type="email" name="email" placeholder="Email" className={errors.email && touched.email ? 'error' : ''} />

            <StyledErrorMessage name="email" component="div" />

            <StyledField type="password" name="password" placeholder="Senha" className={errors.password && touched.password ? 'error' : ''}/>

            <StyledErrorMessage name="password" component="div" />

            <StyledButton type="submit" disabled={loading}>
              {loading ? 'Loading...' : 'Login'}
            </StyledButton>
          </StyledForm>
        )}
      </Formik>
    </>
  );
};

export { LoginForm };
export default LoginForm;
