import React from 'react'

import styled from 'styled-components';

import { ICollection } from '@api/collection/types';

const Select = styled.select`

  padding: 0.5rem 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  background-color: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: none;
    border-color: #6c63ff;
    box-shadow: 0 0 0 2px rgba(108, 99, 255, 0.2);
  }
`;

const Label = styled.label `
  font-size:1rem;
  width:100%;
  margin-top:10px;
  margin-bottom:10px;
`;

interface ISelectCollection {
  collections: ICollection[],
  setCollection(id: number): void,
}

const SelectCollectionAdminArea = (props: ISelectCollection) => {
  return (
    <>
      <div>
        
        <Label>
          Coleções:
        </Label>

        <Select name='collection_id' onChange={(e) => props.setCollection(Number(e.target.value))}>

          <option key={-1} value={'-1'}>{'Selecione uma coleção'}</option>

          {props.collections.map(option => (
            <option key={option.id} value={option.id}>{option.name_collection}</option>
          ))}
        </Select>
      </div>
    </>
  )
}

export { SelectCollectionAdminArea }
export default SelectCollectionAdminArea
