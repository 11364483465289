import {
  ImgStamp,
  ButtomBack,
  BackgroundImage1,
  BackgroundImage3,
  BackgroundStampSolidColor,
} from './StampDetailStyledComponents'

import { ModalEditStamp } from './modal_edit_stamp'

export {
  ImgStamp,
  ButtomBack,
  BackgroundImage1,
  BackgroundImage3,
  BackgroundStampSolidColor,
  ModalEditStamp,
}
