import React, { ReactElement, useState } from 'react'

import { FaAngleDown } from 'react-icons/fa';
import { IoMdSettings } from "react-icons/io";

import { LinkStyled } from '@src/styled_components';
import {
  MenuSection,
  MenuNav,
  List,
  Item,
  SubList,
  SubItem,
  AboutSubList,
  ItemConfig
} from './components'
import { RenderIf } from '@ui/components';
import { isAdmin, useUser } from '@src/contexts';

interface IMenuBar {
  menuFixed: boolean,
}

const MenuBar = (props: IMenuBar): ReactElement => {
  const [user] = useUser();
  const [showSubMenuProduct, setShowSubMenuProduct] = useState(false)  
  const [showAboutSubMenu, setShowAboutSubMenu] = useState(false)

  return (
    <>
      <MenuSection>
        <MenuNav menuFixed={props.menuFixed}>
          <List>
            <Item>
              <a href='#start'>
                Início
              </a>
            </Item>

            <Item
              onMouseOver={() => setShowSubMenuProduct(true)}
              onMouseOut={() => setShowSubMenuProduct(false)}
            >
              Produtos <FaAngleDown />

              <SubList
                show={showSubMenuProduct}
                onMouseOver={() => setShowSubMenuProduct(true)}
                onMouseOut={() => setShowSubMenuProduct(false)}
              >
                <SubItem>
                  <LinkStyled to={'/collections'}>
                    Coleções
                  </LinkStyled>
                </SubItem>

                <SubItem>
                  <LinkStyled to={'/stamps'}>
                    Tecidos
                  </LinkStyled>
                </SubItem>
              </SubList>
            </Item>

            <Item>
              <LinkStyled to={'/blog'}>
                Blog
              </LinkStyled>
            </Item>

            <Item
              onMouseOver={() => setShowAboutSubMenu(true)}
              onMouseOut={() => setShowAboutSubMenu(false)}
            >
              Sobre <FaAngleDown />

              <AboutSubList
                show={showAboutSubMenu}
                onMouseOver={() => setShowAboutSubMenu(true)}
                onMouseOut={() => setShowAboutSubMenu(false)}
              >
                <SubItem>
                  <a href='#about'>
                    Quem somos
                  </a>
                </SubItem>

                <SubItem>
                  <a href='#ourProcess'>
                    Nossos processos
                  </a>
                </SubItem>
              </AboutSubList>
            </Item>
            <Item>
              <a href='#contact'>
                Contato
              </a>
            </Item>
          </List>

          <RenderIf condition={isAdmin()}>
            <ItemConfig>
              <LinkStyled to={'/admin-area'}>
                <IoMdSettings size="1.5rem"/>
              </LinkStyled>
            </ItemConfig>
          </RenderIf>
        </MenuNav>
      </MenuSection>
    </>
  )
}

export { MenuBar }
export default MenuBar
