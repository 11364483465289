import React, { ReactElement } from 'react'

import styled from 'styled-components';

import {
  Container,
  TitleAboutUs,
  ImageAboutUs,
  DescriptionAboutUs
} from './components'

interface IAboutUsSection {
  title: string,
  description: string,
  textButton: string,
  imageSrc: string,
}

const AboutUsSectionMobile = (props: IAboutUsSection): ReactElement => {
  return (
    <>
      <div id='about' style={{ paddingTop: 70, paddingBottom: 10 }}>
        <Container>
        
            
            <TitleAboutUs>
              {props.title}
            </TitleAboutUs>

            <ImageAboutUs 
              src={process.env.PUBLIC_URL + props.imageSrc} 
              alt="Foto quem somos" 
            />
             
            <DescriptionAboutUs>
              {props.description}
            </DescriptionAboutUs>
    
        </Container>
      </div>
    </>
  )
}

export { AboutUsSectionMobile }
export default AboutUsSectionMobile
