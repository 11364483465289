import styled from "styled-components";

const FormStyle = styled.div `
  max-height:60vh;
  overflow-y:auto;
`

const ImagePreview  = styled.div `
  img {
    margin-top:3%;
    width:80%;
    margin-left: auto;
    margin-right: auto;
    display:block;
    margin-bottom:5vw;
    max-height:650px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 16px;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;

  &:focus {
    border-color: #333;
  }
`;

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: vertical;

  &:focus {
    border-color: #333;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top:1vw;

  button {
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #252525;
    color: #fff;
    font-weight: bold;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #979797;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export {
    ImagePreview,
    FormGroup,
    Input,
    TextArea,
    ModalActions,
    FormStyle
}