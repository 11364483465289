import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { IStamp } from '@api/stamp/types'
import { ICollection } from '@api/collection/types'

const SelectWrapper = styled.div`
  position: absolute;
  top:10%;
  left:9%;
  width:auto;
`;

const Select = styled.select`

  padding: 0.5rem 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  background-color: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: none;
    border-color: #6c63ff;
    box-shadow: 0 0 0 2px rgba(108, 99, 255, 0.2);
  }
`;

const Label = styled.label `
  font-size:1rem;
  width:100%;
  margin-top:10px;
  margin-bottom:10px;
`;

interface ISelectStampComponent {
  handleStamps(stamps: IStamp[]): void
  stamps: IStamp[],
  collections: ICollection[],
}

const SelectStampComponent = (props: ISelectStampComponent ) => {
  const [collectionId, setCollectionId] = useState<string>();

  useEffect(() => {

    var stamps = props.stamps

    if(Number(collectionId) !== -1){
      stamps = props.stamps.filter((stamp) => {
        return stamp.collectionId === Number(collectionId)
      })
    }

    props.handleStamps(stamps)

  }, [collectionId])

  return (
    <>
      <SelectWrapper>
        
        <Label>
          Coleções:
        </Label>

        <Select value={collectionId} onChange={(e) => setCollectionId(e.target.value)}>

          <option key={-1} value={'-1'}>{'Selecione uma coleção'}</option>

          {props.collections.map(option => (
            <option key={option.id} value={option.id}>{option.name_collection}</option>
          ))}
        </Select>
      </SelectWrapper>
    </>
  );
};

export default SelectStampComponent;