import styled from "styled-components"

const TitleAboutUs = styled.h1`
  width: 100%;
  float: left;
  font-size: 36px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: start;

  @media(max-width:800px){
    position:relative;
    width:100% !important;
    height:auto;
    margin:0 !important;
    padding:0px !important;
    margin-left:auto;
    margin-right:auto;
    display:block !important;

  }
`

const DescriptionAboutUs = styled.p`
  width: 100%;
  float: left;
  font-size: 16px;
  color: #ffffff;
  margin-left: 0px;
  padding-top: 40px;


  @media(max-width:800px){
    position:relative;
    width:100% !important;
    height:auto;
    margin:0 !important;
    padding:0px !important;
    margin-left:auto;
    margin-right:auto;
    display:block !important;
 
  }
`

const ButtonAboutUs = styled.div`
  width: 30%;
  float: left;
  margin-top: 70px;
  button {
    text-decoration: none;
    width: 100%;
    float: left;
    font-size: 18px;
    color: #ffffff;
    background-color: #070201;
    text-align: center;
    padding: 10px 0px;
    border-radius: 5px;
    text-transform: uppercase;
    border: 0;
    &:hover{
      color: #000;
      background-color: #ffffff;
    }
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #252525;
  color: white;
  padding: 35px;
  max-width: 60%;
  margin: 0 auto;
  position: relative;
  height: auto;
  justify-content: flex-start;

  @media(max-width:800px){
    display:block;
    align-itens:inherit;
    width:120% !important;
    height:auto !important;
    padding:0px;
    margin:0;
    margin-left:auto;
    margin-right:auto;
    font-size:14px;
    position:inherit;
    
    background-color:red;

  }
`;

const Image = styled.img`
  position: absolute;
  right: 0; /* altera para "left" para posicionar a imagem à esquerda */
  margin-right: -100px; /* ajusta a margem negativa para que a imagem ultrapasse a cor de fundo */
  max-width: 25vw;
  box-shadow: 1px 1px 1px 10px white;
  width: 400px;
  height: 300px;


  @media(max-width:800px){
    width:300px !important;
    height:auto !important;
    margin-right:100px;
    position:inherit;
  }
`;

const DivAboutUs = styled.div`
  display: flex;
  justify-content: center;
  width: 63%;
  flex-direction: column;

`

export {
  DivAboutUs,
  TitleAboutUs,
  DescriptionAboutUs,
  ButtonAboutUs,
  Container,
  Image,
}
