import React, { ReactElement, useEffect, useState } from 'react'

import styled from 'styled-components'
import { FcDecision } from 'react-icons/fc'

import { BrazilMap } from '@src/components/brasil_map'
import { StateNumber } from '@src/consts'
import { IRepresentative, RepresentativeClientAPI } from '@api/representative'
import { RepresentativeList } from './components'
import { RenderIf } from '@ui/components'

const HeadDivOurLocation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  hr {
    width: 20vw;
  }

  @media(max-width:800px){
    width:100%;
    margin:0;
    padding:0;
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
`
const BodyDivOurLocation = styled.div`
  input {
    border: none;
    outline: none;
    height: 45px;
    width: 100%;
    margin: 10px 0;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    padding-left: 15px;
  }

  height:30vw;
  overflow-y:auto;

  @media(max-width:800px){
    width:100%;
    margin:0;
    padding:0;
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
`

interface IOurRepresentativesSection {
  showTitle?: boolean,
}

const representativeClientAPI = new RepresentativeClientAPI()

const OurRepresentativesSection = (props: IOurRepresentativesSection): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true)
  const [stateSelected, setStateSelected] = useState<StateNumber>()
  const [representative, setRepresentative] = useState<IRepresentative[]|[]>([])
  const [representativeToShow, setRepresentativeToShow] = useState<IRepresentative[]|[]>([])
  
  const handleStateSelected = (id: StateNumber) => {
    setStateSelected(id);
  }

  useEffect(() => {
    setLoading(true)
    representativeClientAPI.getRepresentative()
    .then((response) => setRepresentative(response))
    .then(() => setLoading(false))
  }, [])

  useEffect(() => {
    setLoading(true)

    const representativeFiltered = Object.values(representative).filter(
      arr => arr.state_id === stateSelected
    )

    setRepresentativeToShow(representativeFiltered)
    setLoading(false)
  }, [stateSelected, representative])

  return (
    <>
      <div id="our-representatives-section" style={{ paddingTop: 100, paddingBottom: 100 }}>
        <div className="container-fluid">
          {props.showTitle ?? 
            (
              <>
                <div className="row" style={{ paddingBottom: 50 }}>
                  <div className="offset-lg-2 col-md-10 offset-md-1">
                    <HeadDivOurLocation>
                      <hr />
                      <h2>
                        Nossos Representantes
                      </h2>
                    </HeadDivOurLocation>
                  </div>
                </div>
              </>
            )
          }

          <div>
            <div className="row">
              <div className="col-lg-4 offset-lg-2 col-md-5 offset-md-1">
                <div className="contact_form-container">
                  <BodyDivOurLocation>
                    <RenderIf condition={Boolean(representativeToShow.length) && Boolean(stateSelected) && !loading}>
                      {representativeToShow.map((representative: IRepresentative) => (
                        <>
                          <RepresentativeList representative={representative}/>
                        </>
                      ))}
                    </RenderIf>

                    <RenderIf condition={Boolean(!representativeToShow.length) && !Boolean(stateSelected) && !loading}>
                      <div style={{ textAlign: 'center' }}>
                        <h1>Selecione <br /> uma região</h1>
                      </div>
                    </RenderIf>

                    <RenderIf condition={Boolean(!representativeToShow.length) && Boolean(stateSelected) && !loading}>
                      <div style={{ textAlign: 'center' }}>
                        <h1>Ainda não temos representantes nesta região</h1>
                        <FcDecision style={{ width: '15%', height: '15%' }}/>
                      </div>
                    </RenderIf>
                  </BodyDivOurLocation>
                </div>
              </div>

              <div className="col-md-6 px-0">
                <div className="map_container">
                  <div className="map-responsive">
                    <BrazilMap
                      handleStateSelected={handleStateSelected}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { OurRepresentativesSection }
export default OurRepresentativesSection
