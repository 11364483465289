import styled from "styled-components"

const TitleTendenciesSectionMobileUs = styled.h1`
  text-align:center;
  font-size: 20px;
`

const DescriptionTendenciesSectionMobile = styled.p`
  text-align: center;
  font-size: 16px;
`

const Container = styled.div`
  display:block;
  text-align:center;
  align-itens:center;
  justify-content:center;
  width:100vw;
`;

const ImageTendenciesSectionMobile = styled.img`
  width:100%;
  margin-left:auto;
  margin-rigth:auto;
  display:block;
`;

export {
 
  ImageTendenciesSectionMobile,
  DescriptionTendenciesSectionMobile,
  TitleTendenciesSectionMobileUs,
  Container,
}
