import {
  CertificatesSection,
  CertificatesTitle,
  CertificatesDescription,
  CertificatesImages
} from './CertificatesStyleComponents'

export {
  CertificatesSection,
  CertificatesTitle,
  CertificatesDescription,
  CertificatesImages
}
