import React, { useEffect, useState } from 'react'

import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import styled from 'styled-components';

import { LoadingRenderIf } from '@ui/components';
import { ICarouselImage, IHome } from '@src/api/home/types';

const DivCarrousel = styled.div`
	display: block;
`

const ImgCarrousel = styled.img`
	display: block !important;
	width: 100% !important;
	height: 565px !important;

	@media(max-width:800px){
		height:30vh !important;
	}
`

interface ICarrousel {
	home: IHome,
	loading: boolean,
}

const Carrousel = (props: ICarrousel) => {
  return (
		<>
			<LoadingRenderIf
        height={565}
        condition={!props.loading && Boolean(props.home.carousel.carousel_image)}
      >
				<DivCarrousel>
					<Carousel
						variant='dark'
						className='d-block w-100'
						indicators={false}
					>
						{props.home && props.home.carousel.carousel_image.map((image: ICarouselImage) => (
							<Carousel.Item key={image.id}>
								<ImgCarrousel
									src={process.env.PUBLIC_URL + image.image_base}
									alt="Image Carrousel"
								/>
							</Carousel.Item>
						))}
					</Carousel>
				</DivCarrousel>
			</LoadingRenderIf>
		</>
  );
}

export { Carrousel }
export default Carrousel
