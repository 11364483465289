import React from 'react'

import { FaAngleRight } from 'react-icons/fa'

import { DivIconCarrouselCollectionStyled } from './DivIconCarrouselCollectionStyled'

const NextIconCarrouselCollection = () => {
  return (
    <>
      <DivIconCarrouselCollectionStyled>
        <FaAngleRight />
      </DivIconCarrouselCollectionStyled>
    </>
  )
}

export { NextIconCarrouselCollection }
export default NextIconCarrouselCollection
