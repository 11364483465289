import styled from "styled-components"

const TitleAboutUs = styled.h1`
  text-align: center;
  font-size:20px;
`

const DescriptionAboutUs = styled.p`
  text-align:center;
  font-size:16px;
  padding:20px;
`

const Container = styled.div`
  width:100%;
  justify-content:center;
  align-itens:center;
`;

const ImageAboutUs = styled.img`
  margin-top:5%;
  margin-bottom:5%;
  width:80%;
  display: block;
  margin-left:auto;
  margin-right:auto;
  border-radius:20px;
`;



export {
  Container,
  ImageAboutUs,
  DescriptionAboutUs,
  TitleAboutUs,
}
