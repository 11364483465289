import React from 'react';
import { IState } from '../types';

const Maranhao = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick}  d="m 325.54664,168.10519 c 1.13397,-4.23882 3.85117,-6.75275 5.52766,-10.55434 0.60474,-2.32057 -0.214,-5.03098 1.43275,-5.87078 4.03248,-2.98188 6.80244,-2.17467 9.86537,-3.95531 3.03686,-1.76548 4.96398,-5.31222 8.24779,-6.55964 3.49622,-1.3281 7.91843,3.01591 11.20346,-0.60763 2.74103,-2.56399 -3.12201,-6.97387 -2.06711,-8.55256 3.1262,-4.67847 1.16327,-16.60461 2.33127,-19.64631 1.23507,-3.21629 3.68826,-6.57413 6.26256,-9.27333 1.84684,-1.93645 7.04758,-0.0247 6.43265,-4.802794 -3.07562,-0.94921 -6.38671,-1.656278 -9.38594,-3.216143 -2.33229,-0.902907 -4.46202,-2.716859 -7.09213,-2.28071 -1.9246,-0.594415 -1.6353,2.663168 -3.74844,1.740667 -1.73522,0.068 -3.71245,-0.557527 -4.43845,1.501322 -2.10132,4.374668 -6.43538,5.213598 -5.76586,1.460223 0.75011,-2.038948 -3.18895,-1.284628 -1.15412,-3.306771 1.12436,-1.504586 1.35376,-2.771109 -0.64704,-3.444444 0.007,-1.820306 1.69098,-3.915394 0.27288,-5.619897 -0.88823,-1.450265 -3.768,-5.142531 -5.41188,-3.123228 -1.08813,1.897358 -2.21634,-0.890671 -3.64917,-1.451452 -3.51121,-1.374216 -3.28289,-1.443441 -7.47914,-2.418729 -0.50748,3.364722 -1.12014,5.493937 -1.97565,8.157813 -1.09177,3.399573 -2.51379,6.690977 -4.01186,9.932098 -1.83062,3.960615 -3.62705,7.981655 -6.06805,11.598165 -1.89546,2.80824 -4.07199,5.47155 -6.59001,7.73839 -2.71237,2.44181 -6.45719,4.4168 -9.03308,6.18692 2.06303,0.51698 4.81429,0.97201 6.77487,2.33629 1.85089,1.28797 3.38994,3.16266 4.28936,5.22983 0.99467,2.28602 0.89991,4.92084 0.99777,7.41177 0.13064,3.32457 -1.00326,6.28377 -0.42835,9.35042 0.47746,2.54684 1.48443,5.11959 3.14435,7.10933 1.3245,1.58766 4.33864,1.57556 5.13102,3.48529 0.50567,1.21872 -0.3153,2.41162 -0.73795,3.889 -0.83255,2.91029 -1.8499,6.05101 -1.18248,9.0035 0.52571,2.32538 3.14155,3.73894 3.89122,6.00214 0.4113,1.24162 -0.37945,2.77058 0.253,3.91555 0.81528,1.4759 3.06478,3.5911 4.53063,3.47855 3.25167,-1.45242 -0.95782,-10.22315 0.2781,-14.8432 z"></path>
      <text x="325" y="122">MA</text>
    </>
  );
};

export { Maranhao };
export default Maranhao;