import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Image } from 'react-bootstrap';
import { FaAngleLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { StampsBackgroundImages } from '../stamps/components';
import { CollectionClientAPI } from '@api/collection/CollectionClientAPI';
import { Photo } from './components/Photo';
import { LoadingRenderIf, RenderIf } from '@ui/components';
import { ICollection } from '@api/collection/types';
import { IStamp } from '@src/api/home/types';
import { DownloadButton } from './components';
import { isRepresentative } from '@src/contexts';

// Define o estilo para a foto principal
const MainPhoto = styled(Image)`
  width: 65%;
  height: auto;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.541);
`;

// Define o estilo para o contêiner de fotos
const PhotosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
`;

const ButtomBack = styled.button`
  border: 1px solid #cccccc;
  padding-right: 12px;
  padding-bottom: 10px;
  border-radius: 50%;
  background-color: #cccccc;
  &:hover{
    border-color: #a3a3a3;
    background-color: #a3a3a3;
  }
`

const collectionClientAPI = new CollectionClientAPI()

const CollectionDetail = () => {
  const [collection, setCollection] = useState<ICollection>()
	const [loading, setLoading] = useState<Boolean>(true)
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setLoading(true)

    collectionClientAPI.getCollection(Number(params.id))
      .then((response) => {
        console.log(response)
        setCollection(response)
      })
      .then(() => setLoading(false))
  }, [])
  

  return (
    <>
      <StampsBackgroundImages />

      <LoadingRenderIf condition={!loading}>
        <div style={{ position: 'relative', top: 40, paddingLeft: 80 }}>
          <ButtomBack onClick={() => navigate(-1)}>
              <FaAngleLeft style={{ marginTop: 7, marginLeft: 3 }}/>
          </ButtomBack>
        </div>

        <Container style={{ paddingBottom: 100 }}>
          <Row style={{ textAlign: 'center' }}>
            <Col>
              <MainPhoto src={collection && process.env.PUBLIC_URL + collection.image_collection} />

              <RenderIf condition={isRepresentative()}>
                {collection && <DownloadButton collectionId={collection.id}/>}
              </RenderIf>

              <hr />

              <h1>{collection && collection.name_collection}</h1>
            </Col>
          </Row>
          <Row>
            <PhotosContainer>
              {collection && collection.stamp.map((stamp: IStamp) => (
                <>
                  <Photo
                    key={stamp.id}
                    id={stamp.id}
                    src={process.env.PUBLIC_URL + stamp.image_stamp}
                    alt={stamp.description_stamp}
                    name={stamp.name_stamp}
                  />
                </>
              ))}
            </PhotosContainer>
          </Row>
        </Container>
      </LoadingRenderIf>
    </>
  );
}

export { CollectionDetail };
export default CollectionDetail;
