import React, { ReactElement, useState } from 'react'
import styled from 'styled-components';

import { IoMdSettings } from "react-icons/io";

import { LinkStyled } from '@src/styled_components';
import {
  MenuSection,
  MenuNav,
  Button,
  List,
  Item,
  ItemConfig
} from './components'
import { RenderIf } from '@ui/components';
import { isAdmin } from '@src/contexts';

import { FaBars } from 'react-icons/fa';



interface IMenuBar {
  menuFixed: boolean,
}

const MenuBarMobile = (props: IMenuBar): ReactElement => {

  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggle = () => {
    setMenuOpen(!menuOpen);
  }

  return (
    <>
      <MenuSection>

        <Button onClick={handleToggle}>
          <FaBars />
        </Button>

        {menuOpen && (

            
          <List>
            <Item>
              <a href='#start'>
                Início
              </a>
            </Item>

            <Item>
              <LinkStyled to={'/collections'}>
                Coleções
              </LinkStyled>
            </Item>

            <Item>
              <LinkStyled to={'/stamps'}>
                Estampas
              </LinkStyled>
            </Item>
    

            <Item>
              <LinkStyled to={'/blog'}>
                Blog
              </LinkStyled>
            </Item>

            <Item>
              <a href='#about'>
                Quem somos
              </a>
            </Item>

            <Item>
              <a href='#ourProcess'>
                Nossos processos
              </a>
            </Item>
        
            <Item>
              <a href='#contact'>
                Contato
              </a>
            </Item>
          </List>
        )}
        
      
          <RenderIf condition={isAdmin()}>
            <ItemConfig>
              <LinkStyled to={'/admin-area'}>
                <IoMdSettings size="1.5rem"/>
              </LinkStyled>
            </ItemConfig>
          </RenderIf>
      </MenuSection>
    </>
  )
}

export { MenuBarMobile }
export default MenuBarMobile
