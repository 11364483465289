import { Link } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'

interface IDivCenter {
    menuFixed?: boolean,
}

const FormikStyles = createGlobalStyle`
    .error-input {
        border: 1px solid red;
    }
    
    .error-message {
        color: red;
    }
`

const DivCenter = styled.div<IDivCenter>`
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding-top: ${props => props.menuFixed ? '167px' : '100px'};
`

const DivCenterRow = styled.div`
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding-top: 100px;
`

const LinkStyled = styled(Link)`
    border: 0;
    background-color: transparent;
    text-decoration: none;
    color: white;
    &:hover {
      color: #707070;
    }
`

export { DivCenter, LinkStyled, DivCenterRow, FormikStyles }
