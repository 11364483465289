import { StateNumber } from '@src/consts';
import React from 'react';

import styled from 'styled-components';

import {
  Bahia,
  Alagoas,
  Acre,
  Sergipe,
  Pernambuco,
  Amazonas,
  Para,
  MatoGrosso,
  Rondonia,
  Amapa,
  RioDeJaneiro,
  RioGrandeDoSul,
  SantaCatarina,
  Parana,
  SaoPaulo,
  Goias,
  MinasGerais,
  EspiritoSanto,
  Roraima,
  Maranhao,
  RioGrandeDoNorte,
  Paraiba,
  DistritoFederal,
  Ceara,
  MatoGrossoDoSul,
  Piaui,
  Tocantins,
} from './states';
import { ISvgBrazilMap } from './types';

// https://codepen.io/fabiogoodoy/pen/KKyLQo

const SvgStyled = styled.svg<ISvgBrazilMap>`

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text {
    pointer-events: none;
  }

  path {
    fill: ${props => props.mainColor};
    &:hover{
      fill: ${props => props.hoverColor};
      cursor: pointer;
    }
  }

  circle {
    fill: ${props => props.secondaryColor};
    &:hover{
      fill: ${props => props.hoverColor};
      cursor: pointer;
    }
  }
`

interface IBrazilMap {
  handleStateSelected(id: StateNumber): void,
}

const BrazilMap = (props: IBrazilMap) => {
  return (
    <>
      <SvgStyled
        width='460'
        height='465'
        mainColor='#FFEC84'
        secondaryColor='#E3CD50'
        hoverColor='#f88d35'
      >

        <Bahia onClick={() => props.handleStateSelected(StateNumber.BA)}/>

        <Sergipe onClick={() => props.handleStateSelected(StateNumber.SE)}/>

        <Pernambuco onClick={() => props.handleStateSelected(StateNumber.PE)}/>

        <Alagoas onClick={() => props.handleStateSelected(StateNumber.AL)}/>

        <Amazonas onClick={() => props.handleStateSelected(StateNumber.AM)}/>

        <Para onClick={() => props.handleStateSelected(StateNumber.PA)}/>

        <MatoGrosso onClick={() => props.handleStateSelected(StateNumber.MT)}/>

        <Rondonia onClick={() => props.handleStateSelected(StateNumber.RO)}/>

        <Acre onClick={() => props.handleStateSelected(StateNumber.AC)}/>

        <Amapa onClick={() => props.handleStateSelected(StateNumber.AM)}/>

        <RioDeJaneiro onClick={() => props.handleStateSelected(StateNumber.RJ)}/>

        <RioGrandeDoSul onClick={() => props.handleStateSelected(StateNumber.RS)}/>

        <SantaCatarina onClick={() => props.handleStateSelected(StateNumber.SC)}/>

        <Parana onClick={() => props.handleStateSelected(StateNumber.PR)}/>

        <SaoPaulo onClick={() => props.handleStateSelected(StateNumber.SP)}/>

        <MatoGrossoDoSul onClick={() => props.handleStateSelected(StateNumber.MT)}/>

        <Goias onClick={() => props.handleStateSelected(StateNumber.GO)}/>

        <MinasGerais onClick={() => props.handleStateSelected(StateNumber.MG)}/>

        <EspiritoSanto onClick={() => props.handleStateSelected(StateNumber.ES)}/>

        <Piaui onClick={() => props.handleStateSelected(StateNumber.PI)}/>

        <Ceara onClick={() => props.handleStateSelected(StateNumber.CE)}/>

        <Roraima onClick={() => props.handleStateSelected(StateNumber.RR)}/>

        <Tocantins onClick={() => props.handleStateSelected(StateNumber.TO)}/>

        <Maranhao onClick={() => props.handleStateSelected(StateNumber.MA)}/>

        <RioGrandeDoNorte onClick={() => props.handleStateSelected(StateNumber.RN)}/>

        <Paraiba onClick={() => props.handleStateSelected(StateNumber.PA)}/>

        <DistritoFederal onClick={() => props.handleStateSelected(StateNumber.DF)}/>

      </SvgStyled>
    </>
  );
};

export { BrazilMap };
export default BrazilMap;
