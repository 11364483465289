import styled from "styled-components"

export const DivOurProcessSectionStyled = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 90px;
  padding-bottom: 55px;
  background-color: #252525;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom:6vw;

  @media(max-width:800px){
    width:100%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
    display:block;

    h4 {
      text-align:center !important;
      margin-bottom:20px;
    }
  }
`

export const TitleOurProcessSectionStyled = styled.h1`
  width: 100%;
  font-size: 36px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
`

export const SubTitleOurProcessSectionStyled = styled.h4`
  width: 100%;
  font-size: 18px;
  color: #ffffff;
  text-align: left;
  text-transform: uppercase;
  margin-top:5vh

`

export const DescriptionOurProcessSectionStyled = styled.p`
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  text-align: justify;
  margin-left: 0px;
  padding-bottom:5px;

  @media(max-width:800px){
    width:80%;
    margin:0;
    margin-left:auto;
    margin-right:auto;
    display:block;
    text-align:left;
    font-size:14px;
  }
`

export const DivButtonOurProcessSectionStyled = styled.div`
  width: 18vw;
  margin: 0 auto;
  text-align: justify;
  div {
    width: 100%;
    margin-top: 70px;
    button {
      text-decoration: none;
      width: 100%;
      font-size: 18px;
      color: #ffffff;
      background-color: #070201;
      text-align: center;
      padding: 10px 0px;
      border-radius: 5px;
      text-transform: uppercase;
      border: 0;
      &:hover {
        background-color: #666666;
        color: #000;
      }
      a {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }

  @media(max-width:800px){
    width:100%;
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
`