import React, { useState, useEffect } from "react";

import { FaAngleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

import { FaTrashAlt } from 'react-icons/fa'
import { BsPencil } from 'react-icons/bs'
import styled from "styled-components";

import { RenderIf, SuccessModal } from "@ui/components";
import { StampsBackgroundImages } from "../stamps/components";
import {
  Loading,
  Button,  
  Container as ContainerHead, 
  GlobalStyle, 
  Header,
  Logo,
  ButtomBack,
  CreatePostModal,
  EditPostButton,
  DeletePostButton,
  BlogOptions,
  ButtonShowInHome
} from "./components";

import { BlogClientAPI } from "@api/blog"
import { IPost } from "@api/blog/types"
import { EditPostModal } from './components/EditPostModal'
import { DeletePostModal } from "./components/DeletePostModal";
import { isAdmin } from "@src/contexts";
import { HomeClientAPI } from "@src/api/home";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 70vw;
  height: auto;
  background-color: #252525;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  padding-bottom:40px;
  color: white;
  margin-bottom: 50px;
  box-shadow: 7px 7px 8px #888888;
`;

const Image = styled.img`
  width: 80%;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius:10px;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
`;

const Description = styled.p`
  font-size: 16px;
  margin: 0;
  width:80%;
`;

const blogClientAPI = new BlogClientAPI()
const homeClientAPI = new HomeClientAPI()

const Blog = () => {

  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState<IPost[]>([]);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<Boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<Boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<Boolean>(false);

  const [showSucessModal, setShowSucessModal] = useState<boolean>(false)

  const [index, setIndex] = useState<number>(0)
  const [post, setPost] = useState<IPost>()

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
  }

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false)
  }

  const handleEditModalOpen = (post: IPost) => {
    setPost(post)
    setIsEditModalOpen(true);
  }

  const handleEditModalClose = () => {
    setIsEditModalOpen(false)
  }

  const handleDeleteModalOpen = (post: IPost) => {
    setPost(post)
    setIsDeleteModalOpen(true);
  }

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false)
  }

  const handleOpenSucessModal = () => {
    setShowSucessModal(true)
  }

  const handleCloseSucessModal = () => {
    setShowSucessModal(false)
  }

  const handleIndex = (index: number) => {
    setIndex(index)
  }

  const handleShowInHome = (id: number) => {
    homeClientAPI.updatePostHome(id)
    .then((res) => {
      setShowSucessModal(true)
    })
  }

  const BlogOptionsComponent = (post: IPost) => {

    return (
      <BlogOptions>
        <ButtonShowInHome onClick={() => handleShowInHome(post.id)}/>

        <EditPostButton onClick={() => handleEditModalOpen(post)}>
          <BsPencil />
        </EditPostButton>

        <DeletePostButton onClick={() => handleDeleteModalOpen(post)}>
          <FaTrashAlt />
        </DeletePostButton>
      </BlogOptions>
    )
  }

  useEffect(() => {
    blogClientAPI.getPosts()
      .then((data: IPost[]) => {
        setPosts(data);
        setLoading(false);
      });
  }, [index]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <GlobalStyle />

      <StampsBackgroundImages />

      <Header>
        <div>
          <Link to={'/'}>
            <ButtomBack>
              <FaAngleLeft style={{ color: '#252525', marginBottom: 6, marginLeft: 8 }} />
            </ButtomBack>
          </Link>
          
          <Logo>Blog</Logo>
        </div>

        <RenderIf condition={isAdmin()}>
          <Button onClick={handleCreateModalOpen}>Adicionar postagem</Button>
        </RenderIf>
      </Header>

      <RenderIf condition={isCreateModalOpen}>
        <CreatePostModal
          onClose={handleCreateModalClose}
          handleIndex={handleIndex}
          actualIndex={index}
          openSucessModal={handleOpenSucessModal}
        />
      </RenderIf>

      <RenderIf condition={isEditModalOpen && isAdmin()}>

        { post && 
          <EditPostModal
            onClose={handleEditModalClose}
            handleIndex={handleIndex}
            actualIndex={index}
            post={post}
            openSucessModal={handleOpenSucessModal}
          />}
        
      </RenderIf>

      <RenderIf condition={isDeleteModalOpen}>

      { post && 
        <DeletePostModal
          onClose={handleDeleteModalClose}
          handleIndex={handleIndex}
          actualIndex={index}
          post={post}
          openSucessModal={handleOpenSucessModal}
        />}

      </RenderIf>

      <ContainerHead key={index}>

        <Title>Últimas postagens</Title>

        <RenderIf condition={!loading && Boolean(posts)}>
          {posts.map((post: IPost) => (
            <div key={post.id}>
              <Container>
                <RenderIf condition={isAdmin()}>
                  
                  <BlogOptionsComponent
                    id={post.id}
                    name_post={post.name_post} 
                    description_post={post.description_post}
                    image_post={post.image_post}
                    created_at={post.created_at}
                    updated_at={post.updated_at}
                  />
                </RenderIf>

                <Title>{post.name_post}</Title>

                <Image src={process.env.PUBLIC_URL + post.image_post} alt={post.name_post} />

                <Description>{post.description_post}</Description>
              </Container>
            </div>
            
          ))}
        </RenderIf>

        <SuccessModal
          message='Operação realizada com sucesso!'
          show={showSucessModal}
          onClose={handleCloseSucessModal}
        />
      </ContainerHead>
    </>
  );
};

export { Blog };
export default Blog;
