import React, { ReactElement, useState } from 'react'

import { Card } from 'react-bootstrap'
import styled from 'styled-components'

import { ViewMoreButtonCardCollection } from './components'

const CardStyled = styled(Card)`
  transition: 400ms;
  border-radius: 30px;
  &:hover{
    opacity: 0.5;
  }
  filter: drop-shadow(6px 2px 6px #25252545);
  min-width: 100%;
  height: 360px;

  @media(max-width:800px){
    width:100vw;
    height:auto;
  }
`

const CardImgStyled = styled(Card.Img)`
  border-radius: 30px;
  width: 100%;
  height: 100%;

  @media(max-width:800px){
    width:100vw;
    height:auto;
  
  }
`

const CardImgOverlayStyled = styled(Card.ImgOverlay)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;

  @media (max-width: 800px)
  {
    width:100vw;
    height:auto;
    justify-content:center;
  }
`

interface ICardCollection {
  id: number,
  srcImg: string,
}

const CardCollection = (props: ICardCollection): ReactElement => {
  const [showButton, setShowButton] = useState(false)

  return (
    <>
      <CardStyled
        className='text-white'
        onMouseOver={() => setShowButton(true)}
        onMouseOut={() => setShowButton(false)}
      >
        <CardImgStyled
          src={process.env.PUBLIC_URL + props.srcImg}
        />
        <CardImgOverlayStyled>
            <ViewMoreButtonCardCollection
              id={props.id}
              showButton={showButton}
            />
        </CardImgOverlayStyled>
      </CardStyled>
    </>
  )
}

export { CardCollection }
export default CardCollection
