import { LinkStyled } from "@src/styled_components";
import React from "react";

import styled from "styled-components";

type IPhotoProps = {
  id: number,
  src: string;
  alt: string;
  name: string;
};

const PhotoContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: 10px;
`;

const PhotoImage = styled.img`
  width: 300px;
  height: 300px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.26);
`;

const PhotoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  &:hover {
    opacity: 1;
  }
`;

const PhotoButton = styled.button`
  background-color: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: black;
  }
`;

const Photo = (props: IPhotoProps) => {
  return (
    <PhotoContainer>
      <PhotoImage src={props.src} alt={props.alt} />

      <PhotoOverlay>
        <div>{props.name}</div>

      <LinkStyled to={'/stamps/'+props.id}>
        <PhotoButton>
          Ver Mais
        </PhotoButton>
      </LinkStyled>
      </PhotoOverlay>
    </PhotoContainer>
  );
};

export { Photo };
export default Photo;
