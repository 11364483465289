import { ReactElement, useEffect, useState } from 'react';

import styled from 'styled-components';

import {
  Header,
  Carrousel,
  MenuBar,
  // OurServicesSection,
  AboutUsSection,
  CollectionSection,
  OurProcessSection,
  Footer,
  OurRepresentativesSection,
  TendenciesSection,
} from './components';
import { Certificates } from './components/certificates/Certificates';

import { HomeClientAPI } from '@api/home';
import { InicialTextSection } from './components/inicial_text/InicialTextSection';
import { MissionVisionAndValues } from './components/mission_vision_values/MissionVisionAndValues';
import { IHome } from '@src/api/home/types';
import { RenderIf } from '@src/ui/components';
import TendenciesSectionMobile from './components/tendencies_section_mobile';
import AboutUsSectionMobile from './components/about_us_section_mobile';
import MenuBarMobile from './components/menu_bar_mobile';
import CertificatesMobile from './components/certificates _mobile/CertificatesMobile';
import OurRepresentativesSectionMobile from './components/our_representatives_section_mobile/OurRepresentativesSectionMobile';

const HorizontalBar = styled.div`
  width: 33%; /* define a largura da barra como 100% */
  height: 2px;
  margin-left: 33vw;
  background-color: black;
`;

const homeClientAPI = new HomeClientAPI();

const Home = (): ReactElement => {
  const [home, setHome] = useState<IHome>();
  const [loading, setLoading] = useState<boolean>(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [menuFixed, setMenuFixed] = useState(false);

  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
      homeClientAPI.getHome()
        .then((response) => setHome(response))
        .then(() => setLoading(false))
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 220) {
      setMenuFixed(true);
    } else {
      setMenuFixed(false);
    }
  }, [scrollPosition])

  return (
    <>

      <span id='start'></span>

      <Header />


      <RenderIf condition={innerWidth <= 800}>
        <MenuBarMobile
          menuFixed={menuFixed}
        />
      </RenderIf>

      <RenderIf condition={innerWidth > 800}>
        <MenuBar
          menuFixed={menuFixed}
        />
      </RenderIf>

      {home &&
        <Carrousel
          home={home}
          loading={loading}
        />
      }

      <InicialTextSection
        description='A Designtex é uma Indústria Nacional de tecidos planos, produzidos com fibras naturais, diferencial este que a tornou especializada em moda masculina, feminina e artesanato.'
      />

      <HorizontalBar />

      {home &&
        <CollectionSection
          home={home}
          loading={loading}
        />
      }


      <RenderIf condition={innerWidth <= 800}>

        {home &&
          <TendenciesSectionMobile
            home={home}
            title='Tendências'
            description="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believableThere are many variations of passages of Lorem Ipsum available, but the majority have able"
            imageSrc='https://fastly.picsum.photos/id/795/540/326.jpg?hmac=MTxYNY9DWrQ29bCC2O0ZzrffvUzL30OaDgjGQj2Dgg0'
            textButton='Saiba Mais'
          />
        }


        <AboutUsSectionMobile
          title='Quem somos'
          description="Fundada em 2010, A Designtex foi criada com propósito de mudar a forma de produzir tecidos planos no Brasil, perante a dificuldade que a Indústria Têxtil sofria diante a concorrência dos tecidos importados, enxergamos uma oportunidade para produzirmos tecidos diferenciados, com qualidade, agilidade e com preço competitivo. Assim somos a Designtex Tecidos, uma Empresa sólida com uma operação em grande escala, com 4 unidade fabris diretas e indiretas, produzindo tecidos 100% nacionais e atendendo toda cadeia têxtil no Brasil e América do Sul."
          imageSrc={process.env.PUBLIC_URL+'/imgs/quem_nos_somos.jpg'}
          textButton='Saiba Mais'
        />

      </RenderIf>


      <RenderIf condition={innerWidth > 800}>

        {home &&
          <TendenciesSection
            home={home}
            title='Tendências'
            description="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believableThere are many variations of passages of Lorem Ipsum available, but the majority have able"
            imageSrc='https://fastly.picsum.photos/id/795/540/326.jpg?hmac=MTxYNY9DWrQ29bCC2O0ZzrffvUzL30OaDgjGQj2Dgg0'
            textButton='Saiba Mais'
          />
        }

        <AboutUsSection
          title='Quem somos'
          description="Fundada em 2010, A Designtex foi criada com propósito de mudar a forma de produzir tecidos planos no Brasil, perante a dificuldade que a Indústria Têxtil sofria diante a concorrência dos tecidos importados, enxergamos uma oportunidade para produzirmos tecidos diferenciados, com qualidade, agilidade e com preço competitivo. Assim somos a Designtex Tecidos, uma Empresa sólida com uma operação em grande escala, com 4 unidade fabris diretas e indiretas, produzindo tecidos 100% nacionais e atendendo toda cadeia têxtil no Brasil e América do Sul."
          imageSrc={process.env.PUBLIC_URL+'/imgs/quem_nos_somos.jpg'}
          textButton='Saiba Mais'
        />

      </RenderIf>



      <MissionVisionAndValues/>

      <RenderIf condition={innerWidth <= 800}>
        <OurRepresentativesSectionMobile/>
      </RenderIf>

      <RenderIf condition={innerWidth > 800}>
        <OurRepresentativesSection />
      </RenderIf>

      <OurProcessSection
        title='Nossos Processos'
      />


      <RenderIf condition={innerWidth <= 800}>
        <CertificatesMobile/>
      </RenderIf>

      <RenderIf condition={innerWidth > 800}>
        <Certificates/>
      </RenderIf>

      <Footer />
    </>
  );
}

export { Home };
export default Home;
