import {
  DivAboutUs,
  TitleAboutUs,
  DescriptionAboutUs,
  ButtonAboutUs,
  Container,
  Image,
} from './AboutUsStyledComponents'

export {
  DivAboutUs,
  TitleAboutUs,
  DescriptionAboutUs,
  ButtonAboutUs,
  Container,
  Image,
}
