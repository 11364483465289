import React, { ReactElement, useState } from 'react'

import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ViewMoreButtonCardPrint } from './components'

const CardStyled = styled(Card)`
  transition: 400ms;
  &:hover{
    opacity: 0.5;
  }
  width: 33%;
  height: 100%;
  float: left;


  @media (max-width: 1366px)
  {
    width:12vw;
    height:12vw;
  }

  @media (max-width: 800px)
  {
    width:100vw;
    height:auto;
    display:block;
    margin-top:10vw;
    margin-bottom:10vw;
  }
`

const CardImgStyled = styled(Card.Img)`

  width: 100%;
  min-width: 120px;
  height: 230px;
  border-radius: 30px;

  @media (max-width: 1366px)
  {
    width:12vw;
    height:12vw;
  }

  @media (max-width: 800px)
  {
    width:100%;
    height:auto;
  }

`

const CardImgOverlayStyled = styled(Card.ImgOverlay)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
  color: white;

  @media (max-width: 800px)
  {
    width:100%;
    height:auto;
  }
`

interface ICardPrint {
  stampId: number,
  srcImg: string,
  printTitle: string,
}

const CardPrint = (props: ICardPrint): ReactElement => {
  const [showButton, setShowButton] = useState(false)

  return (
    <>
      <CardStyled
        className='text-white'
        style={{ borderRadius: 30 }}
        onMouseOver={() => setShowButton(true)}
        onMouseOut={() => setShowButton(false)}
      >
        <CardImgStyled
          src={props.srcImg}
        />
        <Link to={`/stamps/${props.stampId}`}>
          <CardImgOverlayStyled>
              <ViewMoreButtonCardPrint
                showButton={showButton}
              />

            <Card.Title style={{ fontSize: '.9em' }}>
              {props.printTitle}
            </Card.Title>
          </CardImgOverlayStyled>
        </Link>
      </CardStyled>
    </>
  )
}

export { CardPrint }
export default CardPrint
