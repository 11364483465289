import React, { ReactElement, useEffect, useState } from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'

import { DivCenter } from '@src/styled_components'
import { CardStamp, StampsBackgroundImages } from './components'
import { StampClientAPI } from '@api/stamp/StampClientAPI'
import { IStamp } from '@api/stamp/types'
import { LoadingRenderIf, RenderIf, SuccessModal } from '@ui/components'
import  SelectStampComponent  from './components/select/SelectStampComponent'
import { FormStampValues } from '../stamp_detail/components/modal_edit_stamp/ModalEditStamp'
import { ModalCreateStamp } from './components/modal_create_stamp'
import { CollectionClientAPI } from '@src/api/collection/CollectionClientAPI'
import { ICollection } from '@src/api/collection/types'
import { isAdmin } from '@src/contexts'

export const StampsHeader = styled.div`
  background-color: #252525;
  width: 100vw;
  height: 7vh;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 34px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  align-items: center;
`

export const StampsHeaderSpan = styled.span`
  font-size: 34px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;

  @media(max-width:800px){
    font-size:25px;
    margin-top:10px;
  }
`

export const StampBody = styled.div `
  margin-top: 2.5vw;
`;

export const ButtomBack = styled.button`
  border: 1px solid transparent;
  border-radius: 50%;
  height: 47px;
  background-color: white;
  &:hover {
    background-color: #999999;
  }
  position: relative;
  right: 30vw;


  @media(max-width:800px){
    right:10vw;
    height:15vw;
    margin-top:10px;
  }
`

const Button = styled.button`
  background-color: #252525;
  color: #fff;
  padding: 8px 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 14%;
  right:12%;
  width:auto;
  &:hover{
    background-color: #494949;
  }
`;

const stampClientAPI = new StampClientAPI();
const collectionClientAPI = new CollectionClientAPI()

const Stamps = (): ReactElement => {
  
  const [stamps, setStamps] = useState<IStamp[] | []>([])
  const [collections, setCollections] = useState<ICollection[] | []>([])
  const [showCreateStampModal, setShowCreateStampModal] = useState<boolean>(false)
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const [stampsFiltered, setStampsFiltered] = useState<IStamp[] | []>([])
	const [loading, setLoading] = useState<Boolean>(true)
  const navigate = useNavigate();

  const handleStamps = (stamps: IStamp[]) => {
    setStampsFiltered(stamps)
  }

  const handleModalCreate = (bool: boolean) =>{
    setShowCreateStampModal(bool)
  }

  useEffect(() => {
    setLoading(true)

    stampClientAPI.getStamps()
      .then((response) => {
        setStamps(response)
        setStampsFiltered(response)
      })
      .then(() => setLoading(false))
  }, [])

  useEffect(() => {

    collectionClientAPI.getCollections()
      .then((response) => setCollections(response))
  }, [])

  const createStamp = (values: FormStampValues) => {
    stampClientAPI.createStamp(values)
    .then(() => {
      handleModalCreate(false)
      setShowSuccessModal(true)
    })
  }

  const handleSuccessAction = () => {
    setShowSuccessModal(false)
    navigate(0)
  }

  return (
    <>
      <StampsBackgroundImages />

      <SelectStampComponent
        handleStamps={handleStamps}
        stamps={stamps}
        collections={collections}
      />
      <RenderIf condition={isAdmin()}>
        <Button onClick={() => handleModalCreate(true)}>Criar Estampa</Button>
      </RenderIf>

      <RenderIf condition={isAdmin()}>
        <ModalCreateStamp
          isOpen={showCreateStampModal}
          onClose={() => handleModalCreate(false)}
          onSubmit={(values) => createStamp(values)}
          initialValues={{
            name_stamp: '',
            description_stamp: '',
            image_stamp: '',
          }}
          collections={collections}
        />
      </RenderIf>

      <StampsHeader>
        <ButtomBack>
          <Link
            to={'/'}
          >
            <FaAngleLeft style={{ color: '#252525', marginBottom: 10, marginRight: 1 }} />
          </Link>
        </ButtomBack>

        <StampsHeaderSpan>
          ESTAMPAS
        </StampsHeaderSpan>
      </StampsHeader>

      <StampBody>
        <DivCenter>
          <Container>
            <Row xs={1} md={4}>
              <LoadingRenderIf condition={!loading && Boolean(stampsFiltered)}>
                {stampsFiltered.map((stamp: IStamp) => (
                  <Col key={stamp.id}>
                    <CardStamp
                      id={stamp.id}
                      srcImg={stamp.imageStamp}
                      printTitle={stamp.nameStamp}
                    />
                  </Col>
                ))}
              </LoadingRenderIf>
            </Row>
          </Container>
        </DivCenter>
      </StampBody>

      <SuccessModal
        message='Operação realizada com sucesso!'
        show={showSuccessModal}
        onClose={() => handleSuccessAction()}
      />
    </>
  )
}

export { Stamps }
export default Stamps
