import React from 'react';
import { IState } from '../types';

const Parana = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick} d="m 258.03551,378.95631 c 4.31029,-0.45585 9.25404,-3.89252 14.04739,-5.00703 2.46193,-0.57242 4.66331,0.69344 6.96337,0.35923 2.17501,-0.31604 4.04967,-1.82771 6.21582,-2.19969 1.91305,-0.32853 4.54939,-3e-5 5.82316,-3e-5 1.38104,-2.74255 -3.49097,-3.17463 -2.75505,-4.89689 0.88208,-2.06431 5.2776,0.19632 6.5241,-1.67068 0.34834,-0.52174 0.40079,-0.9668 -0.26773,-1.86289 -0.60774,-0.92323 -1.56,-0.774 -2.40263,-0.9547 -0.71134,-0.15255 -1.72622,0.47173 -2.18039,-0.0966 -0.86776,-1.08587 0.49247,-2.73605 0.73871,-4.10408 -2.45806,0 -4.84941,0.77734 -6.59715,-0.57079 -2.08604,-1.60908 1.88536,-2.74417 -2.78155,-7.01206 -1.70332,-1.91028 -2.43449,-4.50619 -2.13744,-6.98311 -0.10204,-2.78642 -0.004,-5.04655 -2.33085,-6.42397 -2.71703,-1.49686 -6.89649,-0.81036 -10.27647,-1.57556 -2.90932,-0.65865 -5.88557,-2.07685 -8.56707,-2.58589 -4.90733,-0.93157 -9.2481,-1.25017 -14.13938,-0.23773 -2.28712,0.47341 -5.57588,1.34239 -6.86956,3.61292 -2.50649,4.39913 -2.51066,8.96923 -4.08312,14.06505 -1.34584,4.36139 -3.17205,10.14306 -4.55925,13.27986 -0.4273,1.0844 -0.85459,2.16879 -1.28189,3.25319 1.6006,-0.35288 3.20119,-0.70575 4.80179,-1.05863 1.37647,2.2244 0.97019,4.43524 4.18228,6.46834 5.66008,3.58256 13.60998,6.59788 20.63988,6.26328 z"></path>
      <text x="249" y="358">PR</text>
    </>
  );
};

export { Parana };
export default Parana;
