import React from "react";

import styled from "styled-components";

const NotFoundPage: React.FC = () => {
  return (
    <Container>
      <Title>Página não encontrada</Title>
      <Image
        src="https://img.freepik.com/vetores-gratis/pagina-nao-encontrada-ilustracao-do-conceito_114360-1869.jpg"
        alt="Página não encontrada"
      />
      <Message>Não conseguimos encontrar a página que você está procurando.</Message>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
`;

const Title = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-size: 3rem;
  font-weight: bold;
  color: #616161;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const Image = styled.img`
  max-width: 20%;
  height: auto;
  margin-bottom: 1.5rem;
`;

const Message = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  color: #757575;
  text-align: center;
  margin-bottom: 3rem;
`;

export { NotFoundPage }
export default NotFoundPage
