import React, { ReactElement } from 'react'
import styled from 'styled-components'

const DivFooter = styled.div`
  width: 100%;
  float: left;
  background-color: #252525;
  height: auto;
  padding-bottom: 90px;
  color: white;

  @media(max-width:800px){

    width:100%;
    display:block;
    margin:0;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
  }
`

const ImgFooter = styled.div `
  img {
    width:120%;
    margin-left:-20%;
  }

  @media(max-width:800px){

    img {
      width:100%;
      display:block;
      margin:0;
      margin-left:auto;
      margin-right:auto;

    }
  }
`
const ImgFooterDtx = styled.div `
  img {
    width: 140%;
    margin-top:3%;
    margin-left:10%;
  }

  @media(max-width:800px){
    img {
      width:90%;
      display:block;
      margin:0;
      margin-left:auto;
      margin-right:auto;
    }
  }
`

const StyleText = styled.p`
  color:white;
  <font-size:18></font-size:18>px;
`

const Footer = (): ReactElement => {
  return (
    <>
      <DivFooter>
          <div id="contact" className="container" style={{ paddingTop: 100 }}>
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                    <div className="fooer_logo">
                      <ImgFooter><img src={process.env.PUBLIC_URL + '/imgs/logo_branca.png'}></img></ImgFooter>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <h1 className="customer_text">Informações de Contato</h1>
                    <p className="footer_lorem_text"></p>
                    <p className="footer_lorem_text">
                      Telefone: (31) 3286-2853 <br></br>
                      Endereço: Av. Barão Homem de Melo, 1275 <br></br>
                      Nova Granada, Belo Horizonte MG, 30431-285
                    </p>
                </div>

                <div className="col-lg-3 col-sm-6">
                    <ImgFooterDtx><img src={process.env.PUBLIC_URL + '/imgs/logo_dtx_branca.png'}></img></ImgFooterDtx>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <StyleText className="customer_text"><strong>Empresa do grupo Designtex</strong></StyleText>
                </div>
                
              </div>
          </div>
      </DivFooter>
    </>
  )
}

export { Footer }
export default Footer
