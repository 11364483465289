import React from 'react';
import styled from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa';

interface ModalProps {
  show: boolean;
  message: string;
  onClose: () => void;
}

const ModalOverlay = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
`;

const ModalIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  svg {
    font-size: 80px;
    color: #2ecc71;
  }
`;

const ModalMessage = styled.p`
  text-align: center;
  margin-bottom: 20px;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: center;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: #2ecc71;
  color: white;

  &:hover {
    background-color: #27ae60;
  }
`;

const SuccessModal = (props: ModalProps) => {
  return (
    <ModalOverlay show={props.show}>
      <ModalWrapper>
        <ModalIcon>
          <FaCheckCircle />
        </ModalIcon>

        <ModalMessage>{props.message}</ModalMessage>

        <ModalActions>
          <ModalButton onClick={() => props.onClose()}>Fechar</ModalButton>
        </ModalActions>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export { SuccessModal };
export default SuccessModal;
