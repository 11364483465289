import styled from 'styled-components'

import { IMenuNav, ISubListProps } from './types'

const MenuSection = styled.section`
 
  display: flex;
  justify-content: center;
  cursor: default;
  position:relative;

  @media(max-width:800px){
    width:100%;
  }
`

const MenuNav = styled.nav<IMenuNav>`
  padding: 10px 0;
  background-color: #252525;
  z-index: 999;
  position: ${props => props.menuFixed ? 'fixed' : 'relative'};
  top: ${props => props.menuFixed && 0};
  width: 100vw;
  height: 64px;
  display: flex;
  justify-content: center;


  @media(max-width:800px){
    width:100%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
`

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 80px;
  justify-content: space-between;
  margin: 0;

  @media(max-width:800px){
    width:100%;
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
`

const Item = styled.li`
  color: white;
  cursor: pointer;
  &:hover {
    color: #707070;
  }
  a {
    text-decoration: none;
    color: white;
    &:hover {
      color: #707070;
    }
  }

  @media(max-width:800px){
    width:100%;
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
`

const ItemConfig = styled.div`
  color: white;
  &:hover {
    color: #707070;
  }
  a {
    text-decoration: none;
    color: white;
    &:hover {
      color: #707070;
    }
  }
  position: relative;
  top: 22%;
  left: 18%;
`

const SubList = styled.ul<ISubListProps>`
  background-color: white;
  list-style: none;
  display: flex;
  padding: 13px 40px 13px 10px;
  position: absolute;
  flex-direction: column;
  border: 1px #C2C2C2 solid;
  border-radius: 5%;
  gap: 10px;
  top: 55px;
  left: 40vw;
  margin-right: 15px;
  display: ${props => props.show ? 'show' : 'none'};
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 140px;
    z-index: 9;
  }
  a {
    text-decoration: none;
    color: #252525;
    z-index: 10;
    &:hover {
      color: #707070;
    }
  }
`


const AboutSubList = styled.ul<ISubListProps>`
  background-color: white;
  list-style: none;
  display: flex;
  padding: 13px 60px 13px 20px;
  position: absolute;
  flex-direction: column;
  border: 1px #C2C2C2 solid;
  border-radius: 5%;
  gap: 10px;
  top: 55px;
  left: 55vw;
  margin-right: 30px;
  display: ${props => props.show ? 'show' : 'none'};
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 140px;
    z-index: 9;
  }
  a {
    text-decoration: none;
    color: #252525;
    z-index: 10;
    &:hover {
      color: #707070;
    }
  }
`

const SubItem = styled.li`
  color: #252525;
  z-index: 10;
  &:hover {
    color: #707070;
  }
`

export {
  MenuSection,
  MenuNav,
  List,
  Item,
  SubList,
  SubItem,
  AboutSubList,
  ItemConfig,
}