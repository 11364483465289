import React from 'react';
import { IState } from '../types';

const Acre = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick} d="m 51.426219,196.79147 c 2.043929,-0.0388 4.14835,-0.49209 6.153797,-0.26733 1.482067,0.55578 2.964135,1.11155 4.446203,1.66733 5.072314,-3.11365 10.501235,-5.75058 14.855078,-9.89121 1.690821,-1.60802 1.97387,-3.30296 3.354026,-6.13945 -9.693274,-4.60021 -13.216466,-6.23665 -19.949124,-9.07072 -7.886313,-3.31969 -15.728343,-6.85138 -23.931454,-9.28515 -8.485762,-2.51763 -22.593742,-4.68461 -25.98872,-5.45031 -2.8288599,-0.63802 -7.6460086,-4.07413 -7.6460086,-4.07413 -0.3975961,1.44846 -1.0037821,2.77385 -1.88621717,4.85922 1.50689257,2.19103 2.35347917,3.56816 2.78304527,5.95956 2.0106518,4.76245 6.0400075,8.14457 9.1093745,12.19219 -0.333333,1.13333 -0.666667,2.26667 -1,3.4 3.015066,-0.21103 5.981946,0.51921 8.7,1.8 0.666667,1.46667 1.333333,2.93333 2,4.4 3.066667,0 6.133333,0 9.2,0 2.2,-2.33333 3.346435,-3.64775 5.122246,-4.90651 1.775811,-1.25876 3.316434,-0.32138 3.354608,0.8299 0.158245,4.77248 0.123146,9.11744 0.123146,14.47661 0.680577,1.18446 1.579142,1.6591 2.840434,0.95261 2.794245,-0.71452 5.693869,-0.91583 8.559566,-1.15261 l -0.104798,-0.1572 z" />
      <text x="48" y="186">AC</text>
    </>
  );
};

export { Acre };
export default Acre;