import {
  ImageTendenciesSectionMobile,
  DescriptionTendenciesSectionMobile,
  TitleTendenciesSectionMobileUs,
  Container,
} from './TencenciesMobileStyledComponents'

export {
  ImageTendenciesSectionMobile,
  DescriptionTendenciesSectionMobile,
  TitleTendenciesSectionMobileUs,
  Container,
}
