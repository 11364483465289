import React from 'react'

import styled from 'styled-components'

import { FaUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const DivLoginButtonStyled = styled.div`
  position: absolute;
  right: 2%;
  top: 5px;
`

const LoginButtonStyled = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #252525;
  color: white;
  border: none;
  font-size: 24px;
  &:hover{
    background-color: #494949;
    cursor: pointer;
  }
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const LoginButton = () => {
  return (
    <>
      <DivLoginButtonStyled>
        <LoginButtonStyled>
          <i>
            <Link 
              style={{ textDecoration: 'none', color: 'white' }}
              to={'/login'}
            >
              <FaUser />
            </Link>
          </i>
        </LoginButtonStyled>
      </DivLoginButtonStyled>
    </>
  )
}

export { LoginButton }
export default LoginButton
