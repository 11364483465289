import React, { ReactElement } from 'react'

import { Carousel, Stack } from 'react-bootstrap'
import styled from 'styled-components'

import { IStamp } from '@api/home/types'
import { NextIconCarrouselCollection, PrevIconCarrouselCollection, CardPrint } from './components'

const CarrouselStyled = styled(Carousel)`
  a {
    width: 0;
  }

  @media(max-width:800px){
    display:block;
    margin-left:auto;
    margin-right:auto;
    width:90%;
    img{
      height:180px;
    }
  }
`

interface ICarrouselCollection {
  stamps: IStamp[],
}

const CarrouselCollection = (props: ICarrouselCollection): ReactElement => {

  const stacks = [];

  const { innerWidth: width, innerHeight: height } = window;

  var quant = innerWidth <= 800 ? 1 : 3

  for (let i = 0; i < props.stamps.length; i += quant) {
    const stack = props.stamps.slice(i, i + quant);
    stacks.push(stack);
  }

  return (
    <>
      <CarrouselStyled
        nextIcon={<NextIconCarrouselCollection />}
        prevIcon={<PrevIconCarrouselCollection />}
        indicators={false}
        style={{ paddingTop: 20 }}
      >
        {renderStacks(stacks)}
      </CarrouselStyled>
    </>
  )
}

const renderStacks = (stacks: IStamp[][]) => {
  return stacks.map((stack, index) => (
    <Carousel.Item key={index}>
      <Stack
        direction="horizontal"
        className="h-100 justify-content-center align-items-center"
        gap={5}
      >
        {stack.map((item) => (
          <CardPrint
            key={item.id}
            id={item.id}
            srcImg={item.image_stamp}
            printTitle={item.name_stamp}
          />
        ))}
      </Stack>
    </Carousel.Item>
  ));
}

export { CarrouselCollection }
export default CarrouselCollection
