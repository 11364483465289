import React, { ReactElement } from 'react'

import styled from 'styled-components'

import {
  CertificatesSection,
  CertificatesDescription,
  CertificatesImages
} from './components'

import { Carousel, CarouselItem } from 'react-bootstrap'

const CertificatesMobile = (): ReactElement => {

  return (
    <>

        <Carousel>
            <CarouselItem>
              <CertificatesSection>
                <div className='row'>
                  <div className='col-md-12'>
                    <CertificatesImages>
                      <img alt='imagem' src={process.env.PUBLIC_URL + '/imgs/sift_certificate.png'}></img>
                    </CertificatesImages>
                  </div> 
                </div>
              </CertificatesSection>
            </CarouselItem>

            <CarouselItem>
              <CertificatesSection>
                <div className='row'>
                  <div className='col-md-4'>
                    <CertificatesImages>
                      <img alt='imagem' src={process.env.PUBLIC_URL + '/imgs/sindimalhas_certificate.jpeg'}></img>
                    </CertificatesImages>
                  </div>  
                </div>
              </CertificatesSection>
            </CarouselItem>

            <CarouselItem>
              <CertificatesSection>
                <div className='row'>
                  <div className='col-md-4'>
                    <CertificatesImages>
                      <img alt='imagem' src={process.env.PUBLIC_URL + '/imgs/fiemg_certificate.png'}></img>
                    </CertificatesImages>
                  </div> 
                </div>
              </CertificatesSection>
            </CarouselItem>

            <CarouselItem>
              <CertificatesSection>
                <div className='row'>
                  <div className='col-md-12'>
                    <CertificatesImages>
                      <img alt='imagem' src={process.env.PUBLIC_URL + '/imgs/logo_fsc_branca.png'}></img>
                    </CertificatesImages>

                    <CertificatesDescription>
                      A Designtex, além de se preocupar com todas as questões sociais, como geração de emprego e renda, é uma Indústria certificada por vários órgãos internacionais como a Forest Stewardship Council – FSC, certificação esta, que em Português significa Conselho de Gestão Florestal, a qual deve garantir que a madeira utilizada em determinado produto é oriunda de um processo produtivo manejado de forma ecologicamente adequada, socialmente justa, economicamente viável e que cumpre todas as leis vigentes.
                    </CertificatesDescription>
                  </div>
                </div>
              </CertificatesSection>
            </CarouselItem>

            <CarouselItem>
              <CertificatesSection>
                <div className='row'>
                  <div className='col-md-12'>
                    <CertificatesImages>
                      <img alt='imagem' src={process.env.PUBLIC_URL + '/imgs/logo_bci_branca.png'}></img>
                    </CertificatesImages>

                    <CertificatesDescription>
                      Outra certificação da qual a empresa pode se orgulhar é a Better Cotton Iniciative - BCI, a qual promove melhores padrões e práticas de cultivo de algodão, atualmente essa iniciativa é o único padrão de sustentabilidade notável no setor de algodão que permite aos agricultores cultivar algodão geneticamente modificado.
                    </CertificatesDescription>
                  </div> 
                </div>
              </CertificatesSection>
            </CarouselItem>
        </Carousel>
    </>
  )
}

export { CertificatesMobile }
export default CertificatesMobile
