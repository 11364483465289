import React from 'react'
import { IState } from '../types'

const Rondonia = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick}  d="m 155.12778,222.33146 c 4.23103,-2.7517 7.34389,-8.02424 7.63402,-13.07604 0.29633,-5.15959 -2.02832,-12.20437 -5.68637,-14.38115 -2.8324,-1.61668 -7.34308,-1.01836 -9.58999,-3.12177 -1.82436,-1.70785 -3.62517,-4.49375 -3.64668,-6.59977 -0.028,-2.74438 1.43753,-5.52897 1.34644,-8.31932 -0.11157,-3.41768 -0.38776,-5.91638 -0.50578,-9.68933 -3.3538,-0.31377 -8.19759,-1.06113 -11.52574,-3.1709 -2.27715,-1.44353 -3.53913,-5.10488 -5.92265,-6.13152 -6.25052,-0.68917 -6.98581,0.97191 -9.77279,2.91908 -3.44743,2.40859 -4.66913,8.09839 -7.54863,10.10904 -3.06939,2.14325 -6.17712,4.78435 -9.86273,5.6929 -3.275425,0.80745 -6.354872,-0.28813 -10.112304,-0.40576 -1.380099,1.61046 -2.935304,2.76337 -4.591479,4.08556 12.932629,1.0805 15.031903,1.35296 16.469333,10.49751 -0.0551,3.26214 -1.08312,6.76866 0.13836,9.78297 3.11397,5.08396 7.74471,9.89173 12.81885,11.96593 4.13273,1.60416 9.12335,0.0237 13.1374,1.88333 1.82952,0.8476 2.73455,3.08814 4.5264,4.01272 1.77101,0.91383 4.0112,0.67214 5.8247,1.34788 1.18876,1.36 1.88272,3.42547 3.56627,4.08 4.15863,1.61678 9.56296,0.95125 13.30337,-1.48136 z"></path>
      <text x="121" y="198">RO</text>
    </>
  )
}

export { Rondonia }
export default Rondonia
