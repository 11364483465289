import { AxiosInstance } from 'axios'
import { crateAxiosHTTP } from './util/crateAxiosHTTP'

class ClienteAPI {
  protected baseURL: string = 'designtex.com.br'
  protected axios: AxiosInstance = crateAxiosHTTP()

  public hostBack: string = 'https://designtex.com.br';

  // TODO: cadastrar (dados: Object): Primite<Object>;
  // TODO: editar (dados: Object): Primite<Object>;
  // TODO: excluir (dados: Object): Primite<Object>;
}

export { ClienteAPI }
export default ClienteAPI
