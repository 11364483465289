import { AxiosResponse } from 'axios'

import { ClienteAPI } from '@api/ClientAPI'
import { IHome } from './types'

class HomeClientAPI extends ClienteAPI {
  protected baseURL: string = '/api/home'

  async getHome (): Promise<IHome> {
    const response : AxiosResponse<any> = await this.axios.get(
      `${this.baseURL}`
    )

    return {
      id: response.data.data[0].id,
      nameHome: response.data.data[0].name_home,
      inicialText: response.data.data[0].inicial_text,
      carouselId: response.data.data[0].carousel_id,
      collectionId: response.data.data[0].collection_id,
      postId: response.data.data[0].post_id,
      post: response.data.data[0].post,
      carousel: response.data.data[0].carousel,
      collection: response.data.data[0].collection,
      createdAt: response.data.data[0].created_at,
      updatedAt: response.data.data[0].updated_at,
    }
  }

  async updateCollectionHome (id_collection: number): Promise<any> {
    const response : AxiosResponse<any> = await this.axios.put(
      `${this.baseURL}/1`, {collection_id: id_collection}
    )

    return response.data.data;
  }

  async updatePostHome (idPost: number): Promise<any> {
    const response : AxiosResponse<any> = await this.axios.put(
      `${this.baseURL}/1`, {post_id: idPost}
    )

    return response.data.data;
  }
}

export { HomeClientAPI }
export default HomeClientAPI
