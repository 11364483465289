import React, { ReactElement, useEffect, useState } from 'react'

import { Row } from 'react-bootstrap'
import styled from 'styled-components'

import { IHome } from '@api/home/types'
import { DivCenter } from '@src/styled_components'
import { CarrouselCollection } from './components'
import { LoadingRenderIf } from '@src/ui/components'

const TitleCollectionSection = styled.h1`
  width: 100%;
  float: left;
  font-size: 36px;
  color: #121111;
  text-transform: uppercase;
  text-align: center;

  @media(max-width:800px){
    width:100%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
    display:block;
    font-size: 20px;
    padding:10px;
  }
`

const DescriptionCollectionSection = styled.p`
  width: 100%;
  float: left;
  font-size: 16px;
  color: #090808;
  text-align: center;
  margin-left: 0px;
  margin-top: 10px;

  @media(max-width:800px){
    width:80%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
    display:block;
    font-size: 16px;
    padding:10px;
  }
`

interface ICollectionSection {
	home: IHome,
	loading: boolean,
}

const CollectionSection = (props: ICollectionSection): ReactElement => {
  return (
    <>
      <LoadingRenderIf
        height={685}
        condition={!props.loading && Boolean(props.home.collection)}
      >
        <DivCenter>
          <div className="container" style={{ paddingTop: 40, paddingBottom: 100}}>
            <Row>
              <TitleCollectionSection>
                {props.home && props.home.collection.name_collection}
              </TitleCollectionSection>
            </Row>

            <Row>
              <DescriptionCollectionSection>
                {props.home && props.home.collection.description_collection}
              </DescriptionCollectionSection>
            </Row>

            <Row>
              {props.home && <CarrouselCollection stamps={props.home.collection.stamp}/>}
            </Row>
          </div>
        </DivCenter>
      </LoadingRenderIf>
    </>
  )
}

export { CollectionSection }
export default CollectionSection
