import React, { ReactElement, useState } from 'react'

import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ViewMoreButtonCardStamp } from './components'

const CardStyled = styled(Card)`
  transition: 400ms;
  border-radius: 30px;
  &:hover{
    opacity: 0.5;
  }
  margin-bottom: 70px;
  box-shadow: 2ch;
  filter: drop-shadow(6px 2px 6px #25252545);
  width: 100%;
  height: 80%;

  @media(max-width:800px){
    width:92vw;
    margin-top:10vw;
    display:block;
  }
`

const CardImgStyled = styled(Card.Img)`
  border-radius: 30px;
  width: 100%;
  height: 100%;
`

const CardImgOverlayStyled = styled(Card.ImgOverlay)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
`

interface ICardStamp {
  id: number,
  srcImg: string,
  printTitle: string,
}

const CardStamp = (props: ICardStamp): ReactElement => {
  const [showButton, setShowButton] = useState(false)

  return (
    <>
      <CardStyled
        className='text-white'
        onMouseOver={() => setShowButton(true)}
        onMouseOut={() => setShowButton(false)}
      >
        <CardImgStyled
          src={process.env.PUBLIC_URL + props.srcImg}
        />

        <CardImgOverlayStyled>
          <Link to={'/stamps/'+props.id}>
            <ViewMoreButtonCardStamp showButton={showButton} />
          </Link>

          <Card.Title style={{ wordBreak: 'break-all' }}>
            {props.printTitle}
          </Card.Title>
        </CardImgOverlayStyled>
      </CardStyled>
    </>
  )
}

export { CardStamp }
export default CardStamp
