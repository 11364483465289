import styled from "styled-components"

const TitleAboutUs = styled.h1`
  width: 100%;
  float: left;
  font-size: 36px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: start;

    
  @media(max-width:800px){
    display:block;
    width:100%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
    font-size: 1.0em;
  }
`

const DescriptionAboutUs = styled.p`
  width: 100%;
  float: left;
  font-size: 16px;
  color: #ffffff;
  margin-left: 0px;
  padding-top: 40px;

    
  @media(max-width:800px){
    display:block;
    width:100%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
    font-size: 1.0em;
  }
`

const ButtonAboutUs = styled.div`
  width: 30%;
  float: left;
  margin-top: 70px;
  button {
    text-decoration: none;
    width: 100%;
    float: left;
    font-size: 18px;
    color: #ffffff;
    background-color: #070201;
    text-align: center;
    padding: 10px 0px;
    border-radius: 5px;
    text-transform: uppercase;
    border: 0;
    &:hover{
      color: #000;
      background-color: #ffffff;
    }
  }

    
  @media(max-width:800px){
    display:block;
    width:100%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
    font-size: 1.0em;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #252525;
  color: white;
  padding: 20px;
  max-width: 60%;
  margin: 0 auto;
  margin-right: 15vw;
  position: relative;
  height: 506px;
  justify-content: flex-end;

  @media(max-width:800px){
    display:block;
    width:100%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
    font-size: 1.0em;
  }
`;

const Image = styled.img`
  position: absolute;
  left: 0; /* altera para "left" para posicionar a imagem à esquerda */
  margin-left: -100px; /* ajusta a margem negativa para que a imagem ultrapasse a cor de fundo */
  max-width: 25vw;
  box-shadow: 1px 1px 1px 10px white;

  @media(max-width:800px){
    display:block;
    width:100%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
    font-size: 1.0em;
  }
`;

const DivAboutUs = styled.div`
  display: flex;
  justify-content: center;
  width: 63%;
  flex-direction: column;

  @media(max-width:800px){
    display:block;
    width:100%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
    font-size: 1.0em;
  }
`

export {
  DivAboutUs,
  TitleAboutUs,
  DescriptionAboutUs,
  ButtonAboutUs,
  Container,
  Image,
}
