import React, { ReactElement } from 'react'

import {
  MissionVisionAndValuesSubTitle,
  MissionVisionAndValuesSection,
  MissionVisionAndValuesDescription,
  MissionVisionAndValuesImage
} from './components'

const MissionVisionAndValues = (): ReactElement => {
  return (
    <>
        <MissionVisionAndValuesSection>
          <div className='row'>
            <div className='col-md-4'>
              <MissionVisionAndValuesSubTitle>
                MISSÃO
              </MissionVisionAndValuesSubTitle>

              <MissionVisionAndValuesImage
                src={process.env.PUBLIC_URL + '/imgs/missao_branco.png'}
              >
              </MissionVisionAndValuesImage>

              <MissionVisionAndValuesDescription>
                Acreditar, investir e produzir produtos com qualidade de forma a atender as necessidades do mercado, criando valor e sustentabilidade para o negócio da empresa e para desenvolvimento social.
              </MissionVisionAndValuesDescription>
            </div>
            <div className='col-md-4'>
              <MissionVisionAndValuesSubTitle>
                VISÃO
              </MissionVisionAndValuesSubTitle>

              <MissionVisionAndValuesImage
                src={process.env.PUBLIC_URL + '/imgs/visao_branco.png'}
              >
              </MissionVisionAndValuesImage>

              <MissionVisionAndValuesDescription>
                Ser referência nacional no segmento têxtil, reconhecida como empresa inovadora e criativa, oferecendo excelência em atendimento e produtos de qualidade.
              </MissionVisionAndValuesDescription>
            </div>
            <div className='col-md-4'>
              <MissionVisionAndValuesSubTitle>
                VALORES
              </MissionVisionAndValuesSubTitle>

              <MissionVisionAndValuesImage
                src={process.env.PUBLIC_URL + '/imgs/valores_branco.png'}
              >
              </MissionVisionAndValuesImage>

              <MissionVisionAndValuesDescription>
                Ética, lealdade, transparência, comprometimento e respeito em todos nossos processos.
              </MissionVisionAndValuesDescription>
            </div>
          </div>
        </MissionVisionAndValuesSection>
    </>
  )
}

export { MissionVisionAndValues }
export default MissionVisionAndValues
