import React from 'react'

import { FaAngleLeft } from 'react-icons/fa'

import { DivIconCarrouselCollectionStyled } from './DivIconCarrouselCollectionStyled'

const PrevIconCarrouselCollection = () => {
  return (
    <>
      <DivIconCarrouselCollectionStyled>
        <FaAngleLeft />
      </DivIconCarrouselCollectionStyled>
    </>
  )
}

export { PrevIconCarrouselCollection }
export default PrevIconCarrouselCollection
