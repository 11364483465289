import React, { ReactElement, useEffect, useState } from 'react'

import styled from 'styled-components'
import { FcDecision } from 'react-icons/fc'

import { BrazilMap } from '@src/components/brasil_map'
import { StateNumber } from '@src/consts'
import { IRepresentative, RepresentativeClientAPI } from '@api/representative'
import { RepresentativeList } from './components'
import { RenderIf } from '@ui/components'

const HeadDivOurLocation = styled.div`
  width:100%;
  margin:0;
  padding:0;
  margin-left:auto;
  margin-right:auto;
  display:block;
  text-align:center;
  font-size:20px;
`
const BodyDivOurLocation = styled.div`
  width:100%;
  margin-top:20vw;
  margin-left:auto;
  margin-right:auto;
  display:block;
  height:115vw;
  overflow-y:auto;
  overflow-x:none;
  text-align:center;
  justify-content:center;

  p{
    font-size:10px;
  }
`

const DivMap = styled.div `
  width:100%;
  overflow-x:auto;
  margin-top:5%;
  margin-bottom:5%;
`

interface IOurRepresentativesSection {
  showTitle?: boolean,
}

const representativeClientAPI = new RepresentativeClientAPI()

const OurRepresentativesSectionMobile = (props: IOurRepresentativesSection): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true)
  const [stateSelected, setStateSelected] = useState<StateNumber>()
  const [representative, setRepresentative] = useState<IRepresentative[]|[]>([])
  const [representativeToShow, setRepresentativeToShow] = useState<IRepresentative[]|[]>([])
  
  const handleStateSelected = (id: StateNumber) => {
    setStateSelected(id);
  }

  useEffect(() => {
    setLoading(true)
    representativeClientAPI.getRepresentative()
    .then((response) => setRepresentative(response))
    .then(() => setLoading(false))
  }, [])

  useEffect(() => {
    setLoading(true)

    const representativeFiltered = Object.values(representative).filter(
      arr => arr.state_id === stateSelected
    )

    setRepresentativeToShow(representativeFiltered)
    setLoading(false)
  }, [stateSelected, representative])

  return (
    <>
      <div id="our-representatives-section" style={{ paddingTop: 100, paddingBottom: 100 }}>
        <div className="container-fluid">

          {props.showTitle ?? 
            (
              <>
                <div className="row" style={{ paddingBottom: 50 }}>
                  <div className="offset-lg-2 col-md-10 offset-md-1">
                    <HeadDivOurLocation>
                      <hr />
                      <h2>
                        Nossos Representantes
                      </h2>
                    </HeadDivOurLocation>
                  </div>
                </div>
              </>
            )
          }


          <DivMap>
            <div className="col-md-4 px-0">
              <div className="map_container">
                <div className="map-responsive">
                  <BrazilMap
                    handleStateSelected={handleStateSelected}
                  />
                </div>
              </div>
            </div>
          </DivMap>

          <div>
            <div className="row">
              <div className="col-lg-4 offset-lg-2 col-md-5 offset-md-1">
                <div className="contact_form-container">
                  <BodyDivOurLocation>
                    <RenderIf condition={Boolean(representativeToShow.length) && Boolean(stateSelected) && !loading}>
                      {representativeToShow.map((representative: IRepresentative) => (
                        <>
                          <RepresentativeList representative={representative}/>
                        </>
                      ))}
                    </RenderIf>

                    <RenderIf condition={Boolean(!representativeToShow.length) && !Boolean(stateSelected) && !loading}>
                      <div style={{ textAlign: 'center' }}>
                        <h1>Selecione <br /> uma região</h1>
                      </div>
                    </RenderIf>

                    <RenderIf condition={Boolean(!representativeToShow.length) && Boolean(stateSelected) && !loading}>
                      <div style={{ textAlign: 'center' }}>
                        <h1>Ainda não temos representantes nesta região</h1>
                        <FcDecision style={{ width: '15%', height: '15%' }}/>
                      </div>
                    </RenderIf>
                  </BodyDivOurLocation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { OurRepresentativesSectionMobile }
export default OurRepresentativesSectionMobile
