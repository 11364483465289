import React from "react";

import styled from "styled-components";

interface ButtonProps {
  onClick: () => void;
}

const ButtonWrapper = styled.button`
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 0px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 15px;
  &:hover{
    background-color: white;
    color: #252525;
  }
`;

const ButtonShowInHome = (props: ButtonProps) => {
  return <ButtonWrapper onClick={props.onClick}>Exibir no início</ButtonWrapper>;
};

export { ButtonShowInHome };
export default ButtonShowInHome;
