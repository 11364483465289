import { AxiosResponse } from 'axios'

import { ClienteAPI } from '@api/ClientAPI'
import { IStamp, IStampBackEnd } from './types'
import { FormStampValues } from '@src/components/stamp_detail/components/modal_edit_stamp/ModalEditStamp'

class StampClientAPI extends ClienteAPI {
  protected baseURL: string = '/api/stamp'

  async getStamps (): Promise<IStamp[]> {
    const response : AxiosResponse<any> = await this.axios.get(
      `${this.baseURL}`
    )

    return response.data.data.map((data: IStampBackEnd) => {
      return {
        id: data.id,
        nameStamp: data.name_stamp,
        descriptionStamp: data.description_stamp,
        imageStamp: data.image_stamp,
        collectionId: data.collection_id,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
      }
    })
  }

  async createStamp(values: any): Promise<IStampBackEnd> {
    const formData = new FormData();
    formData.append('name_stamp', values.name_stamp);
    formData.append('description_stamp', values.description_stamp);
    formData.append('collection_id', values.collection_id);
    formData.append('image_stamp', values.image_stamp);

    const response : AxiosResponse<any> = await this.axios.post(`${this.baseURL}`, formData)

    return response.data.data
  }

  async getStamp(id: number): Promise<IStampBackEnd[]> {
    const response : AxiosResponse<any> = await this.axios.get(
      `${this.baseURL}/${id}`
    )

    return response.data.data
  }

  async deleteStamp(id: number): Promise<IStampBackEnd> {
    const response : AxiosResponse<any> = await this.axios.delete(
      `${this.baseURL}/${id}`
    )

    return response.data.data
  }

  async updateStamp(id: number, values: FormStampValues): Promise<IStampBackEnd> {
    const formData = new FormData();
    formData.append('name_stamp', values.name_stamp);
    formData.append('description_stamp', values.description_stamp);
    formData.append('image_stamp', values.image_stamp);
    const response : AxiosResponse<any> = await this.axios.post(`${this.baseURL}/${id}`, formData);

    return response.data.data
  }
}

export { StampClientAPI }
export default StampClientAPI
