import styled from "styled-components"

const ImgStamp = styled.img`
  width: 100%;
  max-height: 400px;
`

const ButtomBack = styled.button`
  border: 5px solid #cccccc;
  padding-right: 7px;
  padding-bottom: 2px;
  border-radius: 50%;
  background-color: #cccccc;
  &:hover{
    border-color: #a3a3a3;
    background-color: #a3a3a3;
  }
`

interface IPropsBackgroundImage1 {
  image: string,
}

const BackgroundImage1 = styled.div<IPropsBackgroundImage1>`
  position: absolute;
  background-image: url(${props => props.image});
  background-size: 2700px 1500px;

  height: 20vh;
  width: 100vw;

  background-position: 0 50%;

  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.8);
  top: 18vh;
  transform: translateY(-50%);
  z-index: 2;
`

const BackgroundImage3 = styled.div<IPropsBackgroundImage1>`
  position: absolute;
  background-image: url(${props => props.image});
  background-size: 2700px 1500px;
  overflow: hidden;

  height: 100vh;
  width: 40vw;
  right: 5vw;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.8);
  background-position: -35vw 50%;

  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
`

const BackgroundStampSolidColor = styled.div`
  background-color: #252525;
  width: 100vw;
  position: absolute;
  z-index: -3;
  height: 450px;
  top: 12vh;
`

export {
  ImgStamp,
  ButtomBack,
  BackgroundImage1,
  BackgroundImage3,
  BackgroundStampSolidColor,
}
