import React, { useState } from 'react'

import { Form, Field, useFormikContext } from 'formik';
import styled from 'styled-components';
import { ImagePreview } from '@src/components/blog/components/form/BlogFormStyleComponents';
import { ICollection } from '@api/collection/types';

const ModalButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const ModalButton = styled.button`
  padding: 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
`;

const ModalCancelButton = styled(ModalButton)`
  background-color: #000;
  color: white;
`;

const ModalSubmitButton = styled(ModalButton)`
  background-color: #000;
  color: white;
`;

const ModalForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const OverflowForm = styled.div `
  max-height:60vh;
  overflow-y:auto;
`

const ModalLabel = styled.label`
  margin-bottom: 5px;
  margin-top: 10px;
  width:100%;
  margin-left:auto;
  margin-right:auto;
  display:block;
`;

const ModalInput = styled(Field)`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid gray;
  margin-bottom: 10px;
  width:100%;
  margin-left:auto;
  margin-right:auto;
  display:block;
`;

const ModalTextArea = styled.div `
  textarea {
    width:90%;
    width:100%;
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
`

interface IModalCreateCollectionFormFields {
  onClose(): void,
  isSubmitting: boolean,
}

const ModalCreateCollectionFormFields = (props: IModalCreateCollectionFormFields) => {
  const [imageData, setImageData] = useState("");
  const { setFieldValue } = useFormikContext();

  const handleFileInputChange = (e:any) => {

    const file = e.target.files[0]
    setFieldValue("image_collection", file)
    const reader = new FileReader()
  
    reader.readAsDataURL(file)
  
    reader.onloadend = () => {
  
      const base64data = ''+reader.result
      setImageData(base64data)
    }
  }

  return (
    <>

      <ModalForm>

        <OverflowForm>
        
          <ModalLabel htmlFor="title">Título</ModalLabel>
          <ModalInput type="text" name="name_collection" id="title" />

          <ModalLabel htmlFor="description">Descrição</ModalLabel>
          <ModalTextArea>
            <Field style={{ height: 300 }} as="textarea" name="description_collection" id="description" />
          </ModalTextArea>


          <ModalLabel htmlFor="photo">Foto</ModalLabel>

          <input type="file" name="image_collection" onChange={handleFileInputChange}/>
          <ModalInput type="hidden" name="image_collection" id="photo" />

          {imageData && 
            <ImagePreview>
              <img style={{ width: '400px' }} src={imageData} alt="Imagem"/>
            </ImagePreview>
          }


        </OverflowForm>
        <ModalButtonGroup>
          <ModalCancelButton type="button" onClick={props.onClose}>
            Cancelar
          </ModalCancelButton>

          <ModalSubmitButton type="submit" disabled={props.isSubmitting}>
            Criar
          </ModalSubmitButton>
        </ModalButtonGroup>
      </ModalForm>
 
    </>
  )
}

export { ModalCreateCollectionFormFields }
export default ModalCreateCollectionFormFields
