import React from "react";

import styled from "styled-components";

const Spinner = styled.div`
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top-color: #333;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 0.6s linear infinite;
  margin: auto;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Loading = () => {
  return (
    <Container>
      <Spinner />
    </Container>
  );
};

export { Loading };
export default Loading;
