import styled from "styled-components"

const CertificatesSection = styled.div `
  width: 100%;
  background-color: #252525;
  height: 200vw;
  margin-top:10vh;
  padding-top: 90px;
  padding-bottom: 90px;
  margin-bottom:35vw;
`

const CertificatesTitle = styled.h2`
  width: 100%;
  font-size: 16px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-top:5vh

  @media(max-width:800px){
    width:100%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
`

const CertificatesDescription = styled.p `
  width: 50%;
  font-size: 14px;
  color: #fff;
  margin-left:auto;
  margin-right:auto;
  display:block;
  text-align:justify;

  @media(max-width:800px){
    width:80%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
    display:block;
    margin-bottom:50px;
  }

`

const CertificatesImages = styled.p `
  width:100%;
  img{
    width:50%;
    margin-left:auto;
    margin-right:auto;
    display:block;
  }

  @media(max-width:800px){
    img{
      width:80%;
      height:auto;
      margin-left:auto;
      margin-right:auto;
      display:block;
    }
  }
`

export {
    CertificatesSection,
    CertificatesTitle,
    CertificatesDescription,
    CertificatesImages
}
  