import React, { ReactElement, ReactNode } from 'react'

interface IRenderIfProps {
  // Condição que define se o conteúdo será renderizado ou não.
  condition: Boolean,

  // Conteúdo do componente para apresentação.
  children: ReactNode | void
}

/**
 * Componente para renderização condicional. Exibe o conteúdo interno somente para condição verdadeira.
 *
 * @param {IRenderIfProps} props Propriedades do componente.
 * @returns {ReactElement | null} Conteúdo renderizado (ou não)
 */
const RenderIf = (props: IRenderIfProps): ReactElement | null => {
  return (
    <>
      {props.condition && (
        <>
          {props.children}
        </>
      )}
    </>
  )
}

export { RenderIf }
export default RenderIf
