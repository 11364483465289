import { Loading } from './Loading'
import { 
  Button, 
  Container, 
  GlobalStyle, 
  Header,
  Logo,
  Title,
  PostContainer,
  PostContent,
  PostTitle,
  PostDescription,
  PostImage,
  DivVerticalBar,
  VerticalBar,
  ButtomBack,
  FormStyle,
  EditPostButton,
  DeletePostButton,
  BlogOptions
} from './BlogStyledComponents'
import { CreatePostModal } from './CreatePostModal'
import { ButtonShowInHome } from './ButtonShowInHome'

export {
  Loading,
  Button, 
  Container, 
  GlobalStyle, 
  Header,
  Logo,
  Title,
  PostContainer,
  PostContent,
  PostTitle,
  PostDescription,
  PostImage,
  DivVerticalBar,
  VerticalBar,
  ButtomBack,
  CreatePostModal,
  FormStyle,
  EditPostButton,
  DeletePostButton,
  BlogOptions,
  ButtonShowInHome,
}
