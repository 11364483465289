import React, { ReactElement } from 'react'

import styled from 'styled-components';

import {
  DivAboutUs,
  TitleAboutUs,
  DescriptionAboutUs,
  Container,
  Image,
} from './components'

interface IAboutUsSection {
  title: string,
  description: string,
  textButton: string,
  imageSrc: string,
}

const DivVerticalBar = styled.div`
  position: absolute;
  right: -13px;
`

const VerticalBar = styled.div`
  width: 2px;
  height: 480px; /* altura da barra */
  background-color: #000000ae;
`;

const AboutUsSection = (props: IAboutUsSection): ReactElement => {
  return (
    <>
      <div id='about' style={{ paddingTop: 70, paddingBottom: 10 }}>
        <Container>
          <Image src={process.env.PUBLIC_URL + props.imageSrc} alt="Foto quem somos" />
          <DivAboutUs>
            <TitleAboutUs>
              {props.title}
            </TitleAboutUs>

            <DescriptionAboutUs>
              {props.description}
            </DescriptionAboutUs>
          </DivAboutUs>

          <DivVerticalBar>
            <VerticalBar></VerticalBar>
          </DivVerticalBar>
        </Container>
      </div>
    </>
  )
}

export { AboutUsSection }
export default AboutUsSection
