import React, { ReactElement } from 'react'
import styled from 'styled-components'

const LogoStyle = styled.img `
  width:18%;
  margin-left:auto;
  margin-right:auto;
  display:block;
  margin-top:20px;

  @media(max-width:800px){
    width:80%;
  }
`

const TitleHome = (): ReactElement => {
  return (
    <>
      <LogoStyle src={process.env.PUBLIC_URL + '/imgs/logo_header.png'}/>
    </>
  )
}

export { TitleHome }
export default TitleHome
