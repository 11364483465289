import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    background-color: #F9F9F9;
    color: #444;
  }
`;

export const Container = styled.div`
  max-width: 80vw;
  margin: 0 auto;
  padding: 2rem;

  @media(max-width:800px){
    width:100vw;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #fff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  div {
    display: flex;
    gap: 20%;
  }

  @media(max-width:800px){
    width:100%;
    font-size:12px;
    width:100%;
    display:block;
  }
`;

export const Logo = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: #1A1A1A;
`;

export const Button = styled.button`
  background-color: #1A1A1A;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  @media(max-width:800px){
    font-size:13px;
    width:100%;
    margin-top:5vw;

  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 5vw;
  color: #1A1A1A;

  @media(max-width:800px){
    margin-top:5vw;
    margin-bottom:5vw;
  }
`;

export const PostContainer = styled.div`
  display: block;
  align-items: center;
  background-color: bisque;
  color: #252525;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  height: 610px;
  margin-bottom:5vw;


  @media(max-width:800px){

    width:100%;
    margin-bottom:5vw;
    min-height: 100px;
    height:auto;
  }
`;

export const PostContent = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  margin-left:1px;
  justify-content: center;

  @media(max-width:800px){
    width:100%;
  }
`

export const PostTitle = styled.h2`

  color: #252525;
  text-transform: uppercase;
  text-align: start;
  text-align: center;

  @media(max-width:800px){
    width:100%;
    font-size:15px;
  }
`;

export const PostDescription = styled.p`

  width: 90%;
  font-size: 16px;
  color: #252525;
  text-align: justify;

  @media(max-width:800px){
    width:100%;
    font-size:12px;
  }
`

export const PostImage = styled.img`

  max-width: 500px;
  min-width: 500px;

  max-height: 450px;
  min-height: 450px;
  padding-right: 30px;
`;

export const ButtomBack = styled.button`
  border: 1px solid #cccccc;
  padding-right: 15px;
  border-radius: 50%;
  background-color: #cccccc;
  width:50px;
  height:50px;
  &:hover{
    border-color: #a3a3a3;
    background-color: #a3a3a3;
  }
`
export const DivVerticalBar = styled.div`
  right:70vw;
  position:relative;
`

export const VerticalBar = styled.div`
  width: 2px;
  height: 480px; /* altura da barra */
  background-color: #000000ae;
`;

export const FormStyle = styled.form `
  max-height:80vh;
  overflow-y:auto;
`

export const EditPostButton = styled.button `
  background: transparent;
  color: white;
  cursor: pointer;
  border-radius: 5%;
  border: 1px solid white;
  padding: 4px 10px;
  
  &:hover {
    background: white;
    color: black;
  }

  margin-right:10px;

  @media(max-width:800px){
    align-itens:center;
    margin-right:15px;
    width:30%;
  }
`

export const DeletePostButton = styled.button `
  background: transparent;
  color: red;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5%;
  border: 1px solid red;
  padding: 4px 10px;

  &:hover {
    background: red;
    color: white;
  }

  @media(max-width:800px){
    align-itens:center;
    margin-right:15px;
    width:30%;
  }
`
export const BlogOptions = styled.div `
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  position:relative;
  width:100%;
`