import {
  DivOurProcessSectionStyled,
  TitleOurProcessSectionStyled,
  SubTitleOurProcessSectionStyled,
  DescriptionOurProcessSectionStyled,
  DivButtonOurProcessSectionStyled,
} from './OurProcessSectionStyledComponents'

export {
  DivOurProcessSectionStyled,
  TitleOurProcessSectionStyled,
  SubTitleOurProcessSectionStyled,
  DescriptionOurProcessSectionStyled,
  DivButtonOurProcessSectionStyled,
}