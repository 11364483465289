import React, { ReactElement } from 'react'

import {
  DescriptionOurProcessSectionStyled,
  DivButtonOurProcessSectionStyled,
  DivOurProcessSectionStyled,
  SubTitleOurProcessSectionStyled,
  TitleOurProcessSectionStyled,
} from './components'

interface IOurProcessSection {
  title: string,
  buttonText?: string
}

const OurProcessSection = (props: IOurProcessSection): ReactElement => {
  return (
    <>
      <div id='ourProcess' style={{ paddingTop: 100 }}>
        <DivOurProcessSectionStyled>
          <div className="container">
              <TitleOurProcessSectionStyled>
                {props.title}
              </TitleOurProcessSectionStyled>

              <SubTitleOurProcessSectionStyled>
                {'Etapas desenvolvimento coleções:'}
              </SubTitleOurProcessSectionStyled>

              <DescriptionOurProcessSectionStyled>
                {'Inicialmente há uma pesquisa de comportamento dos consumidores, cores e padronagens. Com a pesquisa feita, a equipe criativa e de desenvolvimento confirma essas tendências na Europa visitando grandes feiras e eventos de moda e conseguinte definimos nossas cartelas de cores e próximas estampas a serem desenvolvidas. Por fim, criamos e desenvolvemos a coleção.'}
              </DescriptionOurProcessSectionStyled>

              <SubTitleOurProcessSectionStyled>
                {'Etapas processo produção de tecidos:'}
              </SubTitleOurProcessSectionStyled>

              <DescriptionOurProcessSectionStyled>
                <strong>Fiação: </strong>{'Etapa na qual as fibras são transformadas em fios.'} <br></br>
                <strong>Tecelagem: </strong>{'Nesta fase os fios são entrelaçados e transformados em tecidos planos.'} <br></br>
                <strong>Desengomadeira: </strong>{'Etapa responsável por retirar a goma excedente da urdidura dos tecidos e separar as fibras, tornando-os mais flexíveis.'}<br></br>
                <strong>Alvejamento: </strong>{'No Alvejamento é eliminado as impurezas presentes no tecido cru.'}<br></br>
                <strong>Tingimento e estamparia: </strong>{'Nessa etapa os tecidos tomam vida!'} <br></br>
                <strong>Polimerizadeira: </strong>{'Processo destinado à secagem e cura das estampas.'} <br></br>
                <strong>Sanfonizadeira: </strong>{'Processo de estabilização do tecido, diminuindo a porcentagem de encolhimento futuro tecido.'}<br></br>
                <strong>Controle de qualidade: </strong>{'Essa é para nós da Designtex, uma das etapas mais importantes e que está sempre em otimização no nosso processo de produção. É aqui onde os tecidos são avaliados criteriosamente, para garantir a máxima qualidade dos nossos produtos.'}
              </DescriptionOurProcessSectionStyled>

              <SubTitleOurProcessSectionStyled>
                {'Como comprar:'}
              </SubTitleOurProcessSectionStyled>

              <DescriptionOurProcessSectionStyled>
                {'Para adquirir nossos tecidos basta solicitar um representante comercial da sua região ou diretamente com a nossa equipe comercial.'}
              </DescriptionOurProcessSectionStyled>
          </div>
          <DivButtonOurProcessSectionStyled>
              <div>
                <button style={{ paddingLeft: 30, paddingRight: 30 }}>
                  <a href="#our-representatives-section">
                    {props.buttonText ? props.buttonText : 'Falar com representante'}
                  </a>
                </button>
              </div>
          </DivButtonOurProcessSectionStyled>
        </DivOurProcessSectionStyled>
      </div>
    </>
  )
}

export { OurProcessSection }
export default OurProcessSection
