import { ReactElement } from 'react';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import {
  Collections,
  Home,
  Login, 
  StampDetail,
  Stamps,
  NotFoundPage,
  Blog,
  CollectionDetail,
  AdminArea,
} from './components';
import { isAdmin } from '@src/contexts';

const App = (): ReactElement => {
  return (
    <>
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path='*' element={<NotFoundPage />} />

              <Route path='/' element={<Home />} />

              <Route path='/admin' element={<Login />} />

              <Route path='/stamps' element={<Stamps />} />

              <Route path='/stamps/:id' element={<StampDetail />} />

              <Route path='/collections' element={<Collections />} />

              <Route path='/collections/:id' element={<CollectionDetail />} />

              <Route path='/blog' element={<Blog />} />

              <Route path="admin-area" element={isAdmin() ? <AdminArea /> : <Navigate replace to={"/"} />} />
            </Routes>
          </BrowserRouter>
        </div>
    </>
  );
}

export { App };
export default App;