import {
    TitleCardAbout,
    TextCardAbout,
    DivButtonCardAbout,
    ButtonCardAbout,
    ButtonLinkAbout
} from './CardAboutStyledComponents'

export {
    TitleCardAbout,
    TextCardAbout,
    DivButtonCardAbout,
    ButtonCardAbout,
    ButtonLinkAbout
}
