import React, { useState } from 'react'

import { Form, Field, useFormikContext } from 'formik';
import styled from 'styled-components';
import { ImagePreview } from '@src/components/blog/components/form/BlogFormStyleComponents';

const ModalButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const ModalButton = styled.button`
  padding: 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
`;

const ModalCancelButton = styled(ModalButton)`
  background-color: #000;
  color: white;
`;

const ModalSubmitButton = styled(ModalButton)`
  background-color: #000;
  color: white;
`;

const ModalForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const OverflowForm = styled.div `
  max-height:60vh;
  overflow-y:auto;
`

const ModalLabel = styled.label`
  margin-bottom: 5px;
  margin-top: 10px;
  width:100%;
  margin-left:auto;
  margin-right:auto;
  display:block;
`;

const ModalInput = styled(Field)`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid gray;
  margin-bottom: 10px;
  width:100%;
  margin-left:auto;
  margin-right:auto;
  display:block;
`;

const ModalTextArea = styled.div `
  textarea {
    width:90%;
    width:100%;
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
`
export interface FormEditValues {
  id:number,
  name_collection: string;
  description_collection: string;
  image_collection: string;
}

interface IModalEditCollectionFormFields {
  onClose(): void,
  isSubmitting: boolean,
  imageCollection: string
}

const ModalEditCollectionFormFields = (props: IModalEditCollectionFormFields) => {

  const [image, setImage] = useState(props.imageCollection);

  const { setFieldValue } = useFormikContext();

  const handleFileInputChange = (e:any) => {

    const file = e.target.files[0]
    setFieldValue("image_collection", file)

    const reader = new FileReader()
  
    reader.readAsDataURL(file)
  
    reader.onloadend = () => {
  
      const base64data = ''+reader.result
      setImage(base64data)
    }
  }

  return (
    <>
      <ModalForm>

        <OverflowForm>
        
          <ModalLabel htmlFor="title">Título</ModalLabel>
          <ModalInput 
            type="text" 
            name="name_collection" 
            id="title"
          />

          <ModalLabel htmlFor="description">Descrição</ModalLabel>
          <ModalTextArea>
            <Field
              style={{ height: 300 }} 
              as="textarea"
              name="description_collection"
              id="description" 
            />
          </ModalTextArea>


          <ModalLabel htmlFor="photo">Foto</ModalLabel>

          <input type="file" name="image_collection" onChange={handleFileInputChange}/>
          <ModalInput type="hidden" name="image_collection" id="photo" />

          {image && 
            <ImagePreview>
              <img style={{ width: '400px' }} src={image} alt="Imagem"/>
            </ImagePreview>
          }


        </OverflowForm>
        <ModalButtonGroup>
          <ModalCancelButton type="button" onClick={props.onClose}>
            Cancelar
          </ModalCancelButton>

          <ModalSubmitButton type="submit" disabled={props.isSubmitting}>
            Editar
          </ModalSubmitButton>
        </ModalButtonGroup>
      </ModalForm>
 
    </>
  )
}

export { ModalEditCollectionFormFields }
export default ModalEditCollectionFormFields
