import {
  TitleAboutUs,
  DescriptionAboutUs,
  Container,
  ImageAboutUs,
} from './AboutUsMobileStyledComponents'

export {
  TitleAboutUs,
  DescriptionAboutUs,
  Container,
  ImageAboutUs,
}
