import React, { ReactElement, useEffect, useState } from 'react'

import styled from 'styled-components'

import { Container, DescriptionAboutUs, DivAboutUs, Image, TitleAboutUs,  } from './components'
import { LoadingRenderIf } from '@src/ui/components'
import { IHome } from '@src/api/home/types'

interface ITendenciesSection {
  title: string,
  description: string,
  textButton: string,
  imageSrc: string,
  home: IHome,
}

const DivVerticalBar = styled.div`
  position: absolute;
  left: -13px;
`

const VerticalBar = styled.div`
  width: 2px;
  height: 480px; /* altura da barra */
  background-color: #000000ae;
`;

const TendenciesSection = (props: ITendenciesSection): ReactElement => {
  return (
    <>
      <LoadingRenderIf height={506} condition={Boolean(props.home.post)}>
        <Container>
          <DivVerticalBar>
            <VerticalBar></VerticalBar>
          </DivVerticalBar>

          <DivAboutUs>
            <TitleAboutUs>
              {props.home.post && props.home.post.name_post}
            </TitleAboutUs>

            <DescriptionAboutUs>
              {props.home.post && props.home.post.description_post}
            </DescriptionAboutUs>
          {props.home.post && <Image src={process.env.PUBLIC_URL + props.home.post.image_post} alt="Foto Blog" />}
          </DivAboutUs>

        </Container>
      </LoadingRenderIf>
    </>
  )
}

export { TendenciesSection }
export default TendenciesSection
