import React, { ReactElement } from 'react'

import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'

import { CardAbout } from './components'
import { DivCenter } from '@src/styled_components'

const ColBody = styled(Col)`
	display: flex;
	flex-direction: column;
	align-items: center;
`

interface IOurServicesSection {
	title: string,
	description: string,
	menuFixed: boolean,
}

const OurServicesSection = (props: IOurServicesSection): ReactElement => {
    return (
        <>
					<DivCenter menuFixed={props.menuFixed}>
							<Row>
								<DivCenter>
									<h1>{props.title}</h1>
									<p>{props.description}</p>
								</DivCenter>
							</Row>

							<Row style={{ width: '68vw', paddingTop: 40 }}>
								<ColBody>
									<CardAbout
										scrImg='https://fastly.picsum.photos/id/478/200/200.jpg?hmac=YfKBYcZHT991lmrKfB0pYNaztmUvQecXbVrc5V4mj8E'
										title='Venda de Máquinas'
										text='Neque porro quisquam est qui dolorem ipsum'
									/>
								</ColBody>

								<ColBody>
									<CardAbout
										scrImg='https://fastly.picsum.photos/id/642/200/200.jpg?hmac=MJkhEaTWaybCn0y7rKfh_irNHvVuqRHmxcpziWABTKw'
										title='Venda de Tesouras'
										text='There are many variations of passages of Lorem Ipsum'
									/>
								</ColBody>

								<ColBody>
									<CardAbout
										scrImg='https://fastly.picsum.photos/id/550/200/200.jpg?hmac=1cWp9LPVpvHr7sCXaCMeMnsIHNCLfqPwnIkIA0GUzcM'
										title='Curso dos mais diversos modelos'
										text='Contrary to popular belief, Lorem Ipsum'
									/>
								</ColBody>

								<ColBody>
									<CardAbout
										scrImg='https://fastly.picsum.photos/id/459/200/200.jpg?hmac=WxFjGfN8niULmp7dDQKtjraxfa4WFX-jcTtkMyH4I-Y'
										title='Análise de moda'
										text='It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout'
									/>
								</ColBody>
							</Row>
					</DivCenter>
        </>
    )
}

export { OurServicesSection }
export default OurServicesSection
