import { Home } from "./home";
import { StampDetail } from "./stamp_detail";
import { Stamps } from './stamps'
import { Collections } from './collections'
import { Login } from './login'
import { NotFoundPage } from './not_found_page'
import { Blog } from './blog'
import { CollectionDetail } from './collection_detail'
import { AdminArea } from './admin_area'

export {
  Home,
  StampDetail, 
  Stamps,
  Collections,
  Login,
  Blog,
  NotFoundPage,
  CollectionDetail,
  AdminArea,
}
