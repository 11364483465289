import React from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { ModalEditStampFormFields } from './components';
import { IStamp } from '@src/api/home/types';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: FormStampValues) => void;
  initialValues: FormStampValues,
  id: number,
  stamp: IStamp,
}

export interface FormStampValues {
  name_stamp: string;
  description_stamp: string;
  image_stamp: any;
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  z-index: 500;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 50%;
`;

const ModalTitle = styled.h2`
  margin-top: 0;
`;

const validationSchema = Yup.object().shape({
  name_stamp: Yup.string().required('O título é obrigatório'),
  description_stamp: Yup.string().nullable(),
  image_stamp: Yup.string().nullable(),
});

const ModalEditStamp = (props: ModalProps) => {
  if (!props.isOpen) {
    return null;
  }

  return (
    <>
      <ModalContainer>
        <ModalContent>
          <ModalTitle>Adicionar novo item</ModalTitle>
          <Formik
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <ModalEditStampFormFields
                stamp={props.stamp}
                onClose={() => props.onClose()}
                isSubmitting={isSubmitting}
              />
            )}
          </Formik>
        </ModalContent>
      </ModalContainer>
    </>
  );
};

export { ModalEditStamp };
export default ModalEditStamp;
