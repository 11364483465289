import { AxiosResponse } from 'axios'

import { ClienteAPI } from '@api/ClientAPI'

export interface IRepresentative {
  id: number
  code: number
  name_representative: string
  cellphone_number_representative: string
  email_representative: string
  state_id: number
  created_at: string
  updated_at: string
}

class RepresentativeClientAPI extends ClienteAPI {
  protected baseURL: string = '/api/representative'

  async getRepresentative (): Promise<IRepresentative[]> {
    const response : AxiosResponse<any> = await this.axios.get(
      `${this.baseURL}`
    )

    return response.data.data;
  }
}

export { RepresentativeClientAPI }
export default RepresentativeClientAPI
