import React from 'react';

import styled from 'styled-components';
import { AiOutlineCloseCircle } from 'react-icons/ai';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}

const ModalOverlay = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h3`
  margin: 0;
`;

const ModalCloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 24px;

  &:hover {
    color: #e74c3c;
  }
`;

const ModalMessage = styled.p`
  margin-bottom: 20px;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModalButton = styled.button`
  margin-left: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: #e74c3c;
  color: white;

  &:hover {
    background-color: #c0392b;
  }

  &:first-child {
    margin-left: 0;
  }
`;

const ConfirmModal: React.FC<ModalProps> = ({
  show,
  onClose,
  onConfirm,
  title,
  message,
}) => {
  return (
    <ModalOverlay show={show}>
      <ModalWrapper>
        <ModalHeader>
          

          <ModalTitle>{title}</ModalTitle>

          <ModalCloseButton onClick={onClose}>
            <AiOutlineCloseCircle />
          </ModalCloseButton>
        </ModalHeader>

        <ModalMessage>{message}</ModalMessage>

        <ModalActions>
          <ModalButton onClick={onClose}>Cancelar</ModalButton>
          <ModalButton onClick={onConfirm}>Confirmar</ModalButton>
        </ModalActions>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export { ConfirmModal };
export default ConfirmModal;
