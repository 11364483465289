import React, { useState } from "react";
import { Form, Field, useFormikContext} from "formik";
import {
  FormGroup,
  Input,
  TextArea,
  ImagePreview,
  ModalActions
} from './BlogFormStyleComponents'
import { FormStyle } from "../BlogStyledComponents";
import { IPost } from "@src/api/blog/types";

interface IForm {
  onClose(): void;
  post: IPost
}

const EditBlogFormComponent = (props: IForm) => {

    const [image, setImage] = useState(props.post.image_post);
    
    const { setFieldValue } = useFormikContext();

    const handleImage = (image:string) => {
      setImage(image)
    }


    const handleFileInputChange = (e:any) => {
  
      const file = e.target.files[0]
      setFieldValue("image_post", file)
      const reader = new FileReader()
    
      reader.readAsDataURL(file)
    
      reader.onloadend = () => {
    
        const base64data = ''+reader.result
        handleImage(base64data)
      }
    }
    
    return (
      <Form>
        <FormStyle>
          <FormGroup>
            <Field
              as={Input}
              type="text"
              name="name_post"
              placeholder="Título"
            />
          </FormGroup>
  
          <FormGroup>
            <Field
              as={TextArea}
              name="description_post"
              placeholder="Conteúdo"
            />
          </FormGroup>
  
          <FormGroup>
            <Input type="file" onChange={handleFileInputChange}/>
            <Field type="hidden" name="image_post" value={props.post && props.post.image_post}></Field>
            {image && <ImagePreview><img src={image} alt="Imagem"/></ImagePreview>}
          </FormGroup>
        </FormStyle>

        <ModalActions>
            <button type="button" onClick={props.onClose}>
              Cancelar
            </button>
            <button type="submit">Editar</button>
        </ModalActions>
          
        </Form>
    );
  }

  export { EditBlogFormComponent}