import React from 'react'
import styled from 'styled-components';

import { IShowInterface } from '@src/interfaces';
import { LinkStyled } from '@src/styled_components';

const TransparentButton = styled.button<IShowInterface>`
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-size: 1em;
  padding: 0.5em 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 38%;
  margin-left: 2%;
  display: ${props => props.show ? 'show' : 'none'};
  &:hover {
    background-color: white;
    color: black;
  }
`;

interface IViewMoreButtonCardCollection {
  id: number,
  showButton: boolean,
}

const ViewMoreButtonCardCollection = (props: IViewMoreButtonCardCollection) => {
  return (
    <>
      <TransparentButton show={props.showButton}>
        <LinkStyled to={'/collections/' + props.id}>
          Ver mais
        </LinkStyled> 
        
      </TransparentButton>
    </>
  )
}

export { ViewMoreButtonCardCollection }
export default ViewMoreButtonCardCollection
