import {
  DivCenter,
  LinkStyled,
  DivCenterRow,
  FormikStyles,
} from './StyledComponents'

export {
  DivCenter,
  LinkStyled,
  DivCenterRow,
  FormikStyles,
}
