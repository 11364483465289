import React from 'react';
import { IState } from '../types';

const Amapa = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick} d="m 260.769,66.023156 c 3.8349,-4.788158 8.79324,-8.576249 12.7991,-13.224205 1.75034,-2.886256 3.43676,-4.132595 5.93865,-4.863527 1.36048,-1.366469 0.7359,-3.785632 1.10386,-5.678447 -0.76366,-0.838347 -1.35457,-2.029861 -2.22664,-2.646575 -0.99259,-0.08879 -2.12712,0.169001 -3.03839,-0.118352 -1.30495,-1.404354 -2.86845,-2.614797 -4.1339,-4.011836 -2.15241,-6.618271 -4.5275,-13.195742 -5.71384,-20.074944 -1.99173,-9.307097 -2.15055,-5.084229 -4.23803,-2.694703 -1.89331,2.261054 -3.97742,4.394485 -5.75197,6.734975 -2.17919,4.369109 -4.35839,8.738218 -6.53758,13.107327 -1.71492,1.11744 -3.42985,2.234883 -5.14477,3.352328 -1.4828,-0.470312 -2.96182,-1.251763 -4.44692,-1.533208 -4.79877,2.860083 -3.39481,-3.099681 -4.61322,-1.645561 -0.83557,0.646548 -3.16443,2.417752 -4.33214,2.171548 -2.60394,0.20477 -3.7511,-0.08068 -6.2436,-0.757161 -3.3e-4,1.040982 -0.36041,4.077489 1.15977,3.597984 1.14715,0.14345 2.47104,-0.492629 3.62131,0.0082 3.61471,1.043297 7.56377,3.356319 10.11497,7.322839 4.58458,7.718114 4.36102,8.17007 5.13813,13.923417 0.58204,4.309185 2.91437,2.859459 4.04981,4.539599 1.2544,1.856164 1.73521,3.698395 2.9847,6.021742 1.82746,3.202236 1.66695,4.37289 3.5547,4.410115 1.50738,0.02972 2.06302,-2.206341 2.97507,-3.406861 1.09428,-1.440386 1.85013,-3.122803 2.98093,-4.534694 z"></path>
      <text x="249" y="45">AP</text>
    </>
  );
};

export { Amapa };
export default Amapa;