import React, { ReactElement } from 'react'

import styled from 'styled-components'

import { 
  ImageTendenciesSectionMobile,
  DescriptionTendenciesSectionMobile,
  TitleTendenciesSectionMobileUs,
  Container 
} from './components'
import { LoadingRenderIf } from '@src/ui/components'
import { IHome } from '@src/api/home/types'

interface ITendenciesSection {
  title: string,
  description: string,
  textButton: string,
  imageSrc: string,
  home: IHome,
}

const DivVerticalBar = styled.div`
  position: absolute;
  left: -13px;
`

const VerticalBar = styled.div`
  width: 2px;
  height: 480px; /* altura da barra */
  background-color: #000000ae;
`;

const TendenciesSectionMobile = (props: ITendenciesSection): ReactElement => {
  return (
    <>
      <LoadingRenderIf condition={Boolean(props.home.post)}>
        <Container>

          <DivVerticalBar>
            <VerticalBar></VerticalBar>
          </DivVerticalBar>

     
          <TitleTendenciesSectionMobileUs>
            {props.home.post && props.home.post.name_post}
          </TitleTendenciesSectionMobileUs>
            
          <DescriptionTendenciesSectionMobile>
            {props.home.post && props.home.post.description_post}
          </DescriptionTendenciesSectionMobile>
            
          {props.home.post && 
            <ImageTendenciesSectionMobile 
              src={process.env.PUBLIC_URL + props.home.post.image_post} 
              alt="Foto quem somos" 
            />
          }
         

        </Container>
      </LoadingRenderIf>
    </>
  )
}

export { TendenciesSectionMobile }
export default TendenciesSectionMobile
