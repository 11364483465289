import React, { useState } from "react";
import { Form, Field, useFormikContext} from "formik";
import {
  FormGroup,
  Input,
  TextArea,
  ImagePreview,
  ModalActions
} from './BlogFormStyleComponents'
import { FormStyle } from "../BlogStyledComponents";

interface IForm {
  onClose(): void;
}

const CreateBlogFormComponent = (props: IForm) => {

    const [imageData, setImageData] = useState("");
    const { setFieldValue } = useFormikContext();
  
    const handleFileInputChange = (e:any) => {
  
      const file = e.target.files[0]
      setFieldValue("image_post", file)
      const reader = new FileReader()
    
      reader.readAsDataURL(file)
    
      reader.onloadend = () => {
    
        const base64data = ''+reader.result
        setImageData(base64data)
      }
    }
  
    return (
      <Form>
        <FormStyle>
          <FormGroup>
            <Field
              as={Input}
              type="text"
              name="name_post"
              placeholder="Título"
            />
          </FormGroup>
  
          <FormGroup>
            <Field
              as={TextArea}
              name="description_post"
              placeholder="Conteúdo"
            />
          </FormGroup>
  
          <FormGroup>
            <Input type="file" onChange={handleFileInputChange}/>
            <Field type="hidden" name="image_post"></Field>
            {imageData && <ImagePreview><img src={imageData} alt="Imagem"/></ImagePreview>}
          </FormGroup>
        </FormStyle>

        <ModalActions>
            <button type="button" onClick={props.onClose}>
              Cancelar
            </button>
            <button type="submit">Postar</button>
          </ModalActions>
          
        </Form>
    );
  }

  export { CreateBlogFormComponent }