import React from 'react';
import { IState } from '../types';

const SaoPaulo = (props: IState) => {
  return (
    <>
			<path onClick={props.onClick} d="m 305.56275,356.22896 c 2.59228,-1.64314 4.93719,-3.75132 7.69269,-5.08759 2.45384,-1.46001 4.82665,-2.72988 7.33102,-3.29805 1.31842,-0.13647 2.76782,0.76696 4.09291,0.41411 3.11319,-0.829 4.78572,-2.08645 7.85869,-5.62617 5.84421,-6.06847 1.27436,-7.26676 -0.78399,-8.72719 -4.80838,2.20283 -8.42647,2.87519 -12.74728,3.97531 -1.4402,0.15821 -3.26863,-0.76012 -3.80592,-1.85683 -1.76489,-3.60249 -2.97177,-7.07815 -4.7913,-12.2496 -0.68593,-2.33048 -1.46861,-4.76581 -1.88767,-6.82125 -0.41906,-2.05544 -0.84394,-3.9157 -1.06055,-6.45065 -0.224,-1.78361 -0.68535,-6.04563 -2.03445,-7.18124 -0.92898,-0.78197 -2.4579,-0.0373 -3.63698,0.20687 -1.14222,0.2365 -2.23387,0.68503 -3.37688,0.91767 -2.62759,0.53481 -5.32557,1.53759 -7.96986,1.0926 -2.96719,-0.49933 -8.22973,-4.26188 -11.12789,-5.07074 -4.79723,-1.33889 -9.9749,-1.13743 -13.19846,0.21161 -1.45326,0.60818 -3.76888,6.51503 -4.79138,8.39976 -3.04861,5.61938 -4.89738,10.01338 -8.16308,14.49488 -1.21651,1.66942 -2.5531,3.31312 -4.20847,4.54868 -2.2162,1.65415 -7.83802,3.37538 -7.44079,3.66938 0.39724,0.29399 7.14186,-1.40445 10.73649,-1.1929 4.90751,0.28881 9.54371,2.37928 14.36559,3.33665 3.74434,0.74343 8.1613,0.18902 11.29904,1.86724 1.70399,0.97552 2.23542,1.86424 2.89205,3.71466 0.78273,2.20579 -0.1066,5.20674 0.55601,7.45154 0.7439,2.5202 3.02944,3.50301 3.79287,6.01737 0.25645,0.84461 0.3652,3.03235 0.95022,3.69332 1.51417,1.71075 4.32623,-0.77309 5.90951,0.87391 0.74623,0.77626 -0.43617,2.32105 0.3727,3.20875 0.94998,1.04256 2.79514,1.58666 4.28027,1.38152 3.52695,-0.48717 5.52394,-3.78545 8.89493,-5.9136 z"></path>
			<text x="285" y="332">SP</text>
    </>
  );
};

export { SaoPaulo };
export default SaoPaulo;
