import { StateName } from "./StateName";
import { StateNumber } from "./StateNumber";
import { StateUF } from "./StateUF";

const states = [
    { id: StateNumber.AC, name: StateName.AC, uf: StateUF.AC },
    { id: StateNumber.AL, name: StateName.AL, uf: StateUF.AL },
    { id: StateNumber.AP, name: StateName.AP, uf: StateUF.AP },
    { id: StateNumber.AM, name: StateName.AM, uf: StateUF.AM },
    { id: StateNumber.BA, name: StateName.BA, uf: StateUF.BA },
    { id: StateNumber.CE, name: StateName.CE, uf: StateUF.CE },
    { id: StateNumber.DF, name: StateName.DF, uf: StateUF.DF },
    { id: StateNumber.ES, name: StateName.ES, uf: StateUF.ES },
    { id: StateNumber.GO, name: StateName.GO, uf: StateUF.GO },
    { id: StateNumber.MA, name: StateName.MA, uf: StateUF.MA },
    { id: StateNumber.MT, name: StateName.MT, uf: StateUF.MT },
    { id: StateNumber.MS, name: StateName.MS, uf: StateUF.MS },
    { id: StateNumber.MG, name: StateName.MG, uf: StateUF.MG },
    { id: StateNumber.PA, name: StateName.PA, uf: StateUF.PA },
    { id: StateNumber.PB, name: StateName.PB, uf: StateUF.PB },
    { id: StateNumber.PR, name: StateName.PR, uf: StateUF.PR },
    { id: StateNumber.PE, name: StateName.PE, uf: StateUF.PE },
    { id: StateNumber.PI, name: StateName.PI, uf: StateUF.PI },
    { id: StateNumber.RJ, name: StateName.RJ, uf: StateUF.RJ },
    { id: StateNumber.RN, name: StateName.RN, uf: StateUF.RN },
    { id: StateNumber.RS, name: StateName.RS, uf: StateUF.RS },
    { id: StateNumber.RO, name: StateName.RO, uf: StateUF.RO },
    { id: StateNumber.RR, name: StateName.RR, uf: StateUF.RR },
    { id: StateNumber.SC, name: StateName.SC, uf: StateUF.SC },
    { id: StateNumber.SP, name: StateName.SP, uf: StateUF.SP },
    { id: StateNumber.SE, name: StateName.SE, uf: StateUF.SE },
    { id: StateNumber.TO, name: StateName.TO, uf: StateUF.TO }
];

export { states }
export default states
