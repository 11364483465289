import React, { ReactElement } from 'react'

import {
  TitleCardAbout,
  TextCardAbout,
  DivButtonCardAbout,
  ButtonCardAbout,
  ButtonLinkAbout,
} from './components'

interface ICardAbout {
  scrImg: string,
  title: string,
  text: string,
  textButton?: string,
}

const CardAbout = (props: ICardAbout): ReactElement => {
  return (
    <>
      <div className="col-lg col-sm-7" style={{ minWidth: '80%' }}>
          <div style={{ width: '100%', float: 'left', textAlign: 'center'}}>
            <img
              style={{ maxWidth: 100, height: 'auto', borderRadius: '20%' }}
              src={props.scrImg}
              alt="Quem somos"
            />
          </div>

          <TitleCardAbout>
            {props.title}
          </TitleCardAbout>

          <TextCardAbout>
            {props.text}
          </TextCardAbout>
          <DivButtonCardAbout>
            <ButtonCardAbout>
              <ButtonLinkAbout to={'/about'}>
                {props.textButton ? props.textButton : 'Saiba mais'}
              </ButtonLinkAbout>
            </ButtonCardAbout>
          </DivButtonCardAbout>
      </div>
    </>
  )
}

export { CardAbout }
export default CardAbout
