import React from 'react';
import { IState } from '../types';

const RioDeJaneiro = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick}  d="m 363.1,338.2328 c -0.0989,-0.51798 -0.82693,-1.16771 -0.15989,-1.52532 1.41996,-1.35823 2.83993,-2.71645 4.25989,-4.07468 2.2642,-0.71602 4.79084,-1.08547 6.54236,-2.83667 0.8029,-1.10993 0.22161,-2.9683 0.65536,-4.37163 0.25563,-1.46596 1.89871,-3.55233 1.11345,-4.76511 -2.65501,-0.33392 -4.3289,-0.72891 -6.46223,-1.06224 -0.73333,-1.4 -1.84008,-3.79576 -2.57341,-5.19576 -1.13938,1.07281 -1.89546,3.86104 -2.64428,5.22203 -0.99781,2.1121 -1.21129,4.72686 -2.83125,6.40938 -2.10933,2.1908 -5.20042,3.4986 -8.2,4 -2.08387,0.34833 -4.18551,-0.95315 -6.29077,-0.77508 -3.67506,0.31085 -6.64821,2.0917 -10.59824,3.17834 -0.26524,0.21021 -4.06632,1.16243 -3.04305,0.97227 2.8224,1.38251 3.92047,1.68102 4.27851,3.48532 0.43577,2.196 -2.5134,4.51542 -3.19301,5.90894 0.62984,0.95526 3.90098,-2.15861 4.69564,-2.5089 0.50603,-0.28559 3.20451,-1.38137 3.6768,-0.77517 1.60225,1.25939 4.33171,0.16742 6.27411,0.11428 0.53333,-1 0.53078,-2.62421 1.6,-3 0.95799,-0.33669 1.86667,0.8 2.8,1.2 -0.33333,0.53333 -0.66667,1.06667 -1,1.6 3.56206,0.19651 7.13314,0.52715 10.7,0.3 0.79269,-0.0892 0.72066,-0.60731 0.4717,-1.21318 -0.0239,-0.0956 -0.0478,-0.19121 -0.0717,-0.28682 z"></path>
      <circle onClick={props.onClick} r="12" cx="364" cy="339"></circle>
			<text x="357" y="343">RJ</text>
    </>
  );
};

export { RioDeJaneiro };
export default RioDeJaneiro;
