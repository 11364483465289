import React from 'react';
import { IState } from '../types';

const Roraima = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick} d="m 156.29028,68.019096 c 4.67931,-1.988204 -0.12418,-10.666555 10.32783,-11.26589 1.83984,-0.1055 3.04194,0.379201 5.51291,0.764346 0.069,-1.457882 0.39368,-7.513956 0.18783,-9.0114 -0.20581,-1.497443 -2.17888,-0.886775 -3.88749,-1.604662 -2.9137,-2.004347 -3.60967,-4.508091 -4.90279,-7.066947 -1.43623,-2.842048 -2.74829,-5.873666 -3.08935,-9.039685 -0.38465,-3.570722 1.01485,-7.116117 1.23297,-10.703361 1.08612,-0.941004 2.17224,-1.882009 3.25836,-2.823013 -0.40015,-1.882008 -0.8003,-3.764017 -1.20045,-5.646025 -1.881,-1.240819 -5.47796,-1.429412 -4.39448,-4.3669975 0.12147,-1.7496304 0.24294,-3.4992608 0.36441,-5.2488912 -1.71492,0 -3.42985,0 -5.14477,0 1.55912,1.9274484 0.0233,3.2815506 -1.44728,4.4870662 -4.33942,4.6764345 -17.98037,9.8959195 -21.082,8.7530705 -0.2683,-2.332216 -2.37086,2.393413 -3.28353,0.596362 1.34212,2.644214 0.18957,5.899268 -2.70665,6.345448 -2.3295,-1.915206 -4.30895,-5.103085 -7.56114,-3.218799 -3.12217,0.22867 -5.92518,-1.40308 -8.60189,-2.759866 -1.57069,-0.466831 -4.99248,0.07945 -1.89293,1.516781 2.16684,0.932005 4.2283,1.962703 3.53082,4.572657 0,1.793017 0,3.586035 0,5.379053 3.17614,1.071151 2.57339,6.821091 5.18841,6.882203 6.54256,0.152896 6.52233,0.756564 8.9075,3.546429 5.61657,6.569528 3.49195,14.688396 5.04055,24.775964 0.71231,4.639968 4.29374,10.456375 8.59444,10.753658 2.6538,0.183442 4.77082,-4.041744 6.62733,-5.400138 6.44957,-2.977631 3.60533,1.641529 10.42139,-0.217363 z"></path>
      <text x="137" y="41">RR</text>
    </>
  );
};

export { Roraima };
export default Roraima;