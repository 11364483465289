import React from 'react';
import { IState } from '../types';

const SantaCatarina = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick}  d="m 287.45669,399.80963 c -0.83307,-2.04011 1.41704,-2.50552 1.70191,-4.08433 0.34732,-1.99088 0.69465,-3.98177 1.04197,-5.97265 -0.77048,-1.8543 -2.29092,-3.52785 -2.14479,-5.63715 -0.14253,-2.59072 -0.2235,-4.87357 -0.36603,-7.46429 2.0717,0.50126 1.04411,-1.23292 0.68764,-1.70282 -0.82328,-1.08526 -1.89742,-0.84578 -2.89897,-0.80115 -2.05186,0.0915 -3.74177,1.83635 -5.76951,2.1631 -2.20073,0.35462 -4.32805,-0.70881 -6.68223,-0.26188 -4.9135,0.93281 -9.5345,4.66455 -14.70504,5.1328 -4.71762,0.42723 -9.50858,-0.81646 -14.03997,-2.19669 -3.15394,-0.96066 -5.62648,-2.04376 -8.96514,-4.17828 -0.51999,4.39616 -1.06867,8.03867 -1.06867,10.86168 9.74943,-0.18659 19.15738,1.18565 26.29132,6.77664 3.00847,2.42635 7.16335,5.90324 9.19979,9.21642 3.0297,-0.32167 6.34609,-1.26391 7.65726,0.94871 1.43615,2.42353 -1.25553,5.18066 -3.1161,7.85583 0.78266,1.19557 1.52983,1.94276 2.43564,3.01519 3.37268,-3.52877 7.62551,-8.28973 10.99819,-11.8185 -0.20398,-0.33007 0.38482,-2.16962 -0.25724,-1.85259 z"></path>
      <text x="267" y="392">SC</text>
    </>
  );
};

export { SantaCatarina };
export default SantaCatarina;
