import React, { ReactElement, useEffect, useState } from 'react'

import { FaAngleLeft } from 'react-icons/fa'
import { useParams, useNavigate } from 'react-router-dom'
import { FaTrashAlt } from 'react-icons/fa'
import { BsPencil } from 'react-icons/bs'
import styled from 'styled-components'

import {
  ImgStamp,
  ButtomBack,
  BackgroundImage1,
  BackgroundImage3,
  ModalEditStamp,
} from './components'

import { StampClientAPI } from '@api/stamp/StampClientAPI'
import { ConfirmModal, LoadingRenderIf, RenderIf, SuccessModal } from '@ui/components'
import { IStampBackEnd } from '@src/api/stamp/types'
import { OurRepresentativesSection } from '@src/components/home/components'
import { FormStampValues } from './components/modal_edit_stamp/ModalEditStamp'
import { isAdmin } from '@src/contexts'

const DivBlackCenter = styled.div`
  display: flex;
  color: white;
  background-color: #252525;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 5%;
  height: 450px;
  gap: 5%;
`

const DivOptionsStamp = styled.div`
  position: relative;
  right: 2%;
  bottom: 40%;
  display: flex;
  flex-direction: row;
  gap: 4px;
`

const DeleteStampButton = styled.button`
  background: transparent;
  color: red;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5%;
  border: 1px solid red;
  padding: 4px 10px;
  &:hover {
    background: red;
    color: white;
  }
`

const EditStampButton = styled.button`
  background: transparent;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5%;
  border: 1px solid white;
  padding: 4px 10px;
  &:hover {
    background: white;
    color: black;
  }
`

const stampClientAPI = new StampClientAPI()

const StampDetail = (): ReactElement => {
  const [stamp, setStamp] = useState<IStampBackEnd[]>([])
	const [loading, setLoading] = useState<Boolean>(true)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const {id} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true)
    
    stampClientAPI.getStamp(Number(id))
      .then((response) => {
        setStamp(response)
      })
      .then(() => setLoading(false))
  }, [])

  const deleteStamp = () => {
    const stampId = stamp[0].id
    stampClientAPI.deleteStamp(stampId)
    .then(() => handleShowSuccessModal(true))
  }

  const handleUpdateImage = (values: FormStampValues) => {
    const stampId = stamp[0] && stamp[0].id
    stampClientAPI.updateStamp(stampId, values)
    .then((response) => {
      handleShowEditModal(false)
      handleShowSuccessModal(true)
    })
  }

  const handleConfirmDelete = () => {
    setShowDeleteModal(true)
  }

  const handleShowEditModal = (bool: boolean) => {
    setShowEditModal(bool)
  }

  const handleShowSuccessModal = (bool: boolean) => {
    setShowSuccessModal(bool)
  }

  return (
    <>
      <LoadingRenderIf condition={!loading && Boolean(stamp)}>
        {stamp.map((stamp) => (
          <>
            <div style={{ zIndex: -9, position: 'relative', top: 450, opacity: 0.2 }}>
              <BackgroundImage1 image={stamp.image_stamp}/>
              <BackgroundImage3 image={stamp.image_stamp}/>
            </div>

            <div style={{ zIndex: 99 }}>
              <div style={{ position: 'relative', top: 40, paddingLeft: 80 }}>
                <ButtomBack onClick={() => navigate(-1)}>
                  <FaAngleLeft />
                </ButtomBack>
              </div>

              <DivBlackCenter>
                <div style={{ marginLeft: '4%', width: '30%', minWidth: '30%'}}>
                    <ImgStamp
                      src={process.env.PUBLIC_URL + stamp.image_stamp}
                      alt={stamp.name_stamp}
                    />
                </div>

                <div style={{ wordBreak: 'break-all', textOverflow: 'clip' }}>
                  <h1>{stamp.name_stamp}</h1>

                  <p>{stamp.description_stamp}</p>
                </div>

                <RenderIf condition={isAdmin()}>
                  <DivOptionsStamp>
                    <EditStampButton onClick={() => handleShowEditModal(true)}>
                      <BsPencil />
                    </EditStampButton>

                    <DeleteStampButton onClick={() => handleConfirmDelete()}>
                      <FaTrashAlt />
                    </DeleteStampButton>
                  </DivOptionsStamp>
                </RenderIf>

                <RenderIf condition={!isAdmin()}>
                  <DivOptionsStamp></DivOptionsStamp>
                </RenderIf>
              </DivBlackCenter>
            </div>
          </>
        ))}
      </LoadingRenderIf>

      <div  style={{ paddingTop: 100, marginLeft: 100, marginRight: 100, gap: 100 }}>
        <div>
          <h1>Onde comprar</h1>
        </div>

        <div>
          <OurRepresentativesSection showTitle={false}/>
        </div>
      </div>

      <ConfirmModal
        show={showDeleteModal}
        message='Deseja excluir a estampa?'
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => {
          setShowDeleteModal(false)
          deleteStamp()
        }}
        title={'Excluir'}
      />

      <SuccessModal
        message='Operação realizada com sucesso!'
        show={showSuccessModal}
        onClose={() => navigate('/stamps')}
      />

      <ModalEditStamp
        id={stamp[0] && stamp[0].id}
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        onSubmit={(values) => handleUpdateImage(values)}
        stamp={stamp[0]}
        initialValues={{
          name_stamp: stamp[0] && stamp[0].name_stamp,
          description_stamp: stamp[0] && stamp[0].description_stamp,
          image_stamp: stamp[0] && stamp[0].image_stamp,
        }}
      />
    </>
  )
}

export { StampDetail }
export default StampDetail
