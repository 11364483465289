import jwt_decode from "jwt-decode";

import { useUser } from "../UserContext";

interface TokenPayload {
  sub: number;
  exp: number,
}

const isAdmin = (): boolean => {
  return checkAccess(1);
};

const isRepresentative = (): boolean => {
  return checkAccess(2);
};

const checkAccess = (userId: number) => {
  try {
    const [user] = useUser();
    
    if (!user.isLoggedIn) {
      return false;
    } 

    const token = user.token;

    if (token) {
      if (isValidToken(token)) {
        const payload: TokenPayload = getTokenPayload(token);
        if (payload.exp * 1000 < Date.now()) {
          removeUserAuth();
          return false;
        }

        if (payload.sub == 1) {
          return true;
        }

        if (payload.sub == userId) {
          return true;
        }
      }
    }

    return false;
  } catch (error) {
    return false;
  }
}


const isValidToken = (token: string): boolean => {
  if (!token || token.trim() === "") {
    return false;
  }
  return true;
};

const getTokenPayload = (token: string): TokenPayload => {  
  const payload: TokenPayload = jwt_decode(token);
  return payload;

}

const removeUserAuth = (): void => {
  localStorage.removeItem('user');
}

export { isAdmin, removeUserAuth, isRepresentative };
