import { Header } from './header'
import { Carrousel } from './carrousel'
import { MenuBar } from './menu_bar'
import { OurServicesSection } from './our_services_section'
import { AboutUsSection } from './about_us_section'
import { TendenciesSection } from './tendencies_section'
import { CollectionSection } from './collection_section'
import { OurProcessSection } from './our_process_section'
import { Footer } from './footer'
import { OurRepresentativesSection } from './our_representatives_section'

export {
    Header,
    Carrousel,
    MenuBar,
    OurServicesSection,
    AboutUsSection,
    TendenciesSection,
    CollectionSection,
    OurProcessSection,
    OurRepresentativesSection,
    Footer,
}
