import React from 'react';
import { IState } from '../types';

const RioGrandeDoSul = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick}  d="m 242.11056,462.62126 c 1.35817,-1.18772 3.20101,-1.99871 3.63103,-3.90789 1.77754,-4.00665 3.51215,-8.04062 5.62956,-11.88333 -2.92146,-0.1739 -1.3621,-2.74001 -0.68597,-4.5874 0.12352,-3.09896 2.52997,-4.85973 5.00078,-6.24816 2.31112,-0.58398 0.39097,-3.46969 2.37742,-4.52836 1.90599,-2.6023 3.34604,-5.49998 4.96926,-8.27882 1.49243,0.016 5.95991,-0.25711 4.43823,1.79461 -2.02741,2.61551 -2.75274,5.99927 -5.04318,8.44202 -1.7924,2.33901 -3.33721,4.93398 -5.94576,6.48142 -1.70112,0.76129 -5.17623,5.74248 -1.38973,4.03472 1.82656,-0.25826 2.63073,-2.24722 3.97667,-3.31906 2.24404,-2.46528 4.74731,-4.74284 6.83015,-7.32486 3.20057,-5.89487 6.73649,-12.45041 10.29854,-18.12453 -1.59742,-1.66563 -2.40411,-3.73135 -3.97752,-5.9823 2.42463,-2.5904 4.50502,-3.73714 3.48904,-5.32886 -1.10179,-1.72618 -2.43138,-0.49631 -6.4418,0.3727 -6.3042,-8.33932 -10.66585,-11.53132 -18.65439,-14.43941 -4.74964,-1.72902 -12.46981,-2.11555 -16.60352,-2.16628 -5.39303,2.77958 -8.56156,5.02766 -13.0352,9.24847 -9.9071,6.63516 -16.68815,18.28787 -24.13756,26.66425 1.66139,1.25186 2.5652,-0.008 3.94433,-0.94836 1.75116,-0.74549 3.45717,-0.29857 4.45373,1.29602 2.34542,2.41306 4.69084,4.82613 7.03626,7.23919 0.21862,0.95263 -0.64161,3.95346 0.91008,2.37523 1.06858,-0.73293 2.13715,-1.46587 3.20573,-2.1988 4.64671,5.21745 10.77044,8.68175 15.60581,13.05644 4.47965,0.33278 5.43298,6.12758 9.60358,9.43945 -1.16497,2.49324 -2.60007,4.92295 -3.60134,7.45469 -0.40421,2.16749 0.51498,4.53918 2.68342,1.94168 0.50538,-0.0356 1.06622,-1.57398 1.43239,-0.57448 z"></path>
      <text x="233" y="419">RS</text>
    </>
  );
};

export { RioGrandeDoSul };
export default RioGrandeDoSul;
