import { Link } from "react-router-dom"
import styled from "styled-components"

const TitleCardAbout = styled.h2`
  width: 100%;
  float: left;
  font-size: 24px;
  color: #090808;
  text-transform: uppercase;
  text-align: center;
  padding-top: 20px;
`

const TextCardAbout = styled.p`
  width: 100%;
  float: left;
  font-size: 16px;
  color: #090808;
  text-align: center;
  margin-left: 0px;
  margin-top: 10px;
`

const DivButtonCardAbout = styled.div`
  width: 60%;
  margin: 0 auto;
  text-align: center;
`

const ButtonCardAbout = styled.div`
  width: 100%;
  float: left;
  margin-top: 10px;
`

const ButtonLinkAbout = styled(Link)`
  width: 100%;
  float: left;
  font-size: 18px;
  color: #ffffff;
  background-color: #ffb607;
  text-align: center;
  padding: 10px 0px;
  border-radius: 5px;
  text-transform: uppercase;
  text-decoration: none;
  &:hover{
    color: #ffffff;
    background-color: #000;
  }
`

export {
    TitleCardAbout,
    TextCardAbout,
    DivButtonCardAbout,
    ButtonCardAbout,
    ButtonLinkAbout,
}
