import { AxiosResponse } from 'axios'

import { ClienteAPI } from '@api/ClientAPI'
import { IAttachStamps, ICollection, ISendCollection, IUpdateCollection } from './types'


class CollectionClientAPI extends ClienteAPI {
  protected baseURL: string = '/api/collection'

  async getCollections (): Promise<ICollection[]> {
    const response : AxiosResponse<any> = await this.axios.get(
      `${this.baseURL}`
    )

    return response.data.data
  }

  async getCollection (id: number): Promise<ICollection> {
    const response : AxiosResponse<any> = await this.axios.get(
      `${this.baseURL}/${id}`
    )

    return response.data.data[0]
  }

  async sendCollection (values: ISendCollection): Promise<any> {
    const formData = new FormData();
    formData.append('name_collection', values.name_collection);
    formData.append('description_collection', values.description_collection);
    formData.append('image_collection', values.image_collection);

    const response : AxiosResponse<any> = await this.axios.post(`${this.baseURL}`, formData)
    return response.data.data
  }


  async updateCollection (values: IUpdateCollection): Promise<any> {
    const formData = new FormData();
    formData.append('name_collection', values.name_collection);
    formData.append('description_collection', values.description_collection);
    formData.append('image_collection', values.image_collection);

    const response : AxiosResponse<any> = await this.axios.post(`${this.baseURL}/${values.id}`, formData)
    return response.data.data
  }

  async deleteCollection (collectionId: number): Promise<any> {
    const response : AxiosResponse<any> = await this.axios.delete(`${this.baseURL}/${collectionId}`)
    return response.data.data
  }

  async attachStamps (values: IAttachStamps): Promise<any> {
    const formData = new FormData();
    
    formData.set('collection_id', values.collection_id);
    formData.append('stamps', values.stamps);

    const response : AxiosResponse<any> = await this.axios.post(`${this.baseURL}/attach/stamps`, formData)
    return response.data.data
  }
}

export { CollectionClientAPI }
export default CollectionClientAPI
