import React, { ReactElement } from 'react'

import styled from 'styled-components'

interface IStampsBackgroundImageDiv {
  top?: number,
  right?: number,
  left?: number,
  bottom?: number,
}

const StampsBackgroundImageDiv = styled.div<IStampsBackgroundImageDiv>`
  z-index: -10;
  position: absolute;
  top:    ${props => props.top    ? props.top    + 'px' : '0px'};
  right:  ${props => props.right  ? props.right  + 'px' : '0px'};
  opacity: .2;
`

const StampsBackgroundImages = (): ReactElement => {
  return (
    <>
      <StampsBackgroundImageDiv
        top={100}
        right={30}
      >
        <img
          src={process.env.PUBLIC_URL + '/imgs/fio.png'}
          alt="tes"
          width={70}
        />
      </StampsBackgroundImageDiv>

      <StampsBackgroundImageDiv
        top={100}
        right={120}
      >
        <img
          src={process.env.PUBLIC_URL + '/imgs/caixa-de-costura.png'}
          alt="tes"
          width={60} 
        />
      </StampsBackgroundImageDiv>

      <StampsBackgroundImageDiv
        top={175}
        right={85}
      >
        <img
          src={process.env.PUBLIC_URL + '/imgs/de-costura.png'}
          alt="tes"
          width={55} 
        />
      </StampsBackgroundImageDiv>

      <StampsBackgroundImageDiv
        top={405}
        style={{ left: 10 }}
      >
        <img
          src={process.env.PUBLIC_URL + '/imgs/manequim.png'}
          alt="tes"
          width={505} 
          style={{ opacity: '.3' }}
        />
      </StampsBackgroundImageDiv>
    </>
  )
}

export { StampsBackgroundImages }
export default StampsBackgroundImages
