import styled from "styled-components";

const DivIconCarrouselCollectionStyled = styled.div`
  background-color: black;
  width: 40px;
  height: 50px;
  padding: 10px 25px 10px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`

export { DivIconCarrouselCollectionStyled }
export default DivIconCarrouselCollectionStyled
