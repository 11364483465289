import React from 'react';

import { FaAngleLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { LoginForm } from './components';

export const ButtomBackStyled = styled.button`
  border: 1px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: white;
  &:hover {
    background-color: #999999;
  }
  position: absolute;
  top: 3vh;
  left: 2vw;
`

const Wrapper = styled.div`
  height: 100vh;
  background-image: url('https://4kwallpapers.com/images/wallpapers/color-burst-colorful-explosion-cgi-cosmic-black-background-2560x2560-6671.jpg');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Login = () => {
  return (
    <Wrapper>
      <ButtomBackStyled>
        <Link to={'/'}>
          <FaAngleLeft
            style={{ color: '#252525', marginBottom: 2, marginRight: 1 }}
          />
        </Link>
      </ButtomBackStyled>

      <LoginForm />
    </Wrapper>
  );
};

export { Login };
export default Login;
