import React from "react";

import styled from "styled-components";
import { Formik } from "formik";

import { BlogClientAPI } from "@api/blog";

import { IPost } from "@src/api/blog/types";
import { EditBlogFormComponent } from "./form/EditBlogFormComponent";

interface IEditPostModalProps {
  onClose(): void;
  actualIndex: number,
  handleIndex(index: number): void,
  post: IPost,
  openSucessModal(): void
}

const blogClientAPI = new BlogClientAPI()

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

const ModalHeader = styled.h2`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 24px;
  text-align: center;
`;

export const EditPostModal = (props: IEditPostModalProps) => {

  const initialValues = {
    name_post: props.post.name_post,
    description_post: props.post.description_post,
    image_post: props.post.image_post
  };

  const handleSubmit = (values: any) => {    
    blogClientAPI.updatePost(values, props.post.id)
    props.handleIndex(props.actualIndex + 1)
    props.onClose();
    props.openSucessModal()
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>Editar Postagem</ModalHeader>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <EditBlogFormComponent onClose={props.onClose} post={props.post}/>
        </Formik>
      </ModalContent>
    </ModalOverlay>
  );
};