import React from 'react';

import styled from 'styled-components';
import { FaDownload } from 'react-icons/fa';

import { PdfClientAPI } from '@api/pdf';

const StyledButton = styled.a`
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  width: 8vw;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  &:hover{
    color: white;
    background-color: grey;
  }
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;

const pdfClientAPI = new PdfClientAPI()

interface IDownloadButton {
  collectionId: number,
}

const DownloadButton = (props: IDownloadButton) => {
  return (
    <StyledButton
      href={`${pdfClientAPI.hostBack}/${pdfClientAPI.baseURL}/${props.collectionId}`}
      target='_blank'
    >
      <IconWrapper>
        <FaDownload />
      </IconWrapper>
      Download
    </StyledButton>
  );
};

export { DownloadButton };
export default DownloadButton;
