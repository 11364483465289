import { AxiosResponse } from 'axios'

import { ClienteAPI } from '@api/ClientAPI'

class CarrouselImageClientAPI extends ClienteAPI {
  protected baseURL: string = '/api/carousel-image'

  async updateImage (id: number, image: File): Promise<any> {
    const formData = new FormData();
    formData.append('image_base', image);
    const response : AxiosResponse<any> = await this.axios.post(`${this.baseURL}/${id}`, formData)

    return response.data.data;
  }
}

export { CarrouselImageClientAPI }
export default CarrouselImageClientAPI
