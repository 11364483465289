import React, { useState } from 'react'

import { Form, Field, useFormikContext } from 'formik';
import styled from 'styled-components';
import { ImagePreview } from '@src/components/blog/components/form/BlogFormStyleComponents';
import { IStamp } from '@src/api/home/types';

const ModalButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const ModalButton = styled.button`
  padding: 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
`;

const ModalCancelButton = styled(ModalButton)`
  background-color: #ccc;
  color: white;
`;

const ModalSubmitButton = styled(ModalButton)`
  background-color: #007bff;
  color: white;
`;

const ModalForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const ModalLabel = styled.label`
  margin-bottom: 5px;
  margin-top: 10px;
`;

const ModalInput = styled(Field)`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid gray;
  margin-bottom: 10px;
`;

const ModalTextArea = styled(Field)`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid gray;
  margin-bottom: 10px;
  height: 40%;
`

interface IModalEditStampFormFields {
  onClose(): void,
  isSubmitting: boolean,
  stamp: IStamp,
}

const ModalEditStampFormFields = (props: IModalEditStampFormFields) => {
  const [imageData, setImageData] = useState(props.stamp.image_stamp);
  const { setFieldValue } = useFormikContext();

  const handleFileInputChange = (e:any) => {

    const file = e.target.files[0]
    setFieldValue("image_stamp", file)

    const reader = new FileReader()
  
    reader.readAsDataURL(file)
  
    reader.onloadend = () => {
  
      const base64data = ''+reader.result
      setImageData(base64data)
    }
  }

  return (
    <>
      <ModalForm>
        <ModalLabel htmlFor="title">Título</ModalLabel>
        <ModalInput type="text" name="name_stamp" id="title" />

        <ModalLabel htmlFor="description">Descrição</ModalLabel>
        <Field style={{ height: 300 }} as="textarea" name="description_stamp" id="description" />

        <ModalLabel htmlFor="photo">Foto</ModalLabel>

        <input type="file" name="image_stamp" onChange={handleFileInputChange}/>
        <ModalInput type="hidden" name="image_stamp" id="photo" />

        {imageData && 
          <ImagePreview>
            <img style={{ width: '400px' }} src={imageData} alt="Imagem"/>
          </ImagePreview>
        }

        <ModalButtonGroup>
          <ModalCancelButton type="button" onClick={props.onClose}>
            Cancelar
          </ModalCancelButton>

          <ModalSubmitButton type="submit" disabled={props.isSubmitting}>
            Enviar
          </ModalSubmitButton>
        </ModalButtonGroup>
      </ModalForm>
    </>
  )
}

export { ModalEditStampFormFields }
export default ModalEditStampFormFields
