import React, { ReactElement } from 'react'
import { Row } from 'react-bootstrap'

import styled from 'styled-components'

import { DivCenter } from '@src/styled_components'

const DescriptionInicialTextSection = styled.p`
  width: 60%;
  font-size: 1.7em;
  color: #252525;
  text-align: center;

  @media(max-width:800px){
    width:80%;
    margin-left:auto;
    margin-right:auto;
    display:block;
    font-size:16px;
    padding:10px;
  }
`

interface IInicialTextSection {
  description:string
}

const InicialTextSection = (props:IInicialTextSection): ReactElement => {
  return (
    <>
      <DivCenter>
          <Row className="justify-content-md-center">
            <DescriptionInicialTextSection>
              { props.description }
            </DescriptionInicialTextSection>
          </Row>
      </DivCenter>
    </>
  )
}

export { InicialTextSection }
export default InicialTextSection

