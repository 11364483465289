import { AxiosResponse } from 'axios'

import { ClienteAPI } from '@api/ClientAPI'

class BlogClientAPI extends ClienteAPI {
  protected baseURL: string = '/api/post'

  async getPosts (): Promise<any> {
    const response : AxiosResponse<any> = await this.axios.get(
      `${this.baseURL}`
    )
    return response.data.data
  }

  async sendPost (values: any): Promise<any> {
    const formData = new FormData();
    formData.append('name_post', values.name_post);
    formData.append('description_post', values.description_post);
    formData.append('image_post', values.image_post);

    const response : AxiosResponse<any> = await this.axios.post(`${this.baseURL}`, formData)
    return response.data.data
  }

  async updatePost (values: any, id: number): Promise<any> {
    const formData = new FormData();
    formData.append('name_post', values.name_post);
    formData.append('description_post', values.description_post);
    formData.append('image_post', values.image_post);

    const response : AxiosResponse<any> = await this.axios.post(`${this.baseURL}/${id}`, formData)
    return response.data.data
  }


  async deletePost (id: number): Promise<any> {
    const response : AxiosResponse<any> = await this.axios.delete(`${this.baseURL}/${id}`)
    return response.data.data
  }
}

export { BlogClientAPI }
export default BlogClientAPI