import axios, { AxiosInstance } from 'axios'


export const crateAxiosHTTP = (): AxiosInstance => {
  const axiosHttp = axios.create({
    baseURL: 'https://designtex.com.br',
  })

  axiosHttp.interceptors.request.use(
    (config) => {
      const user = localStorage.getItem('user')
      if (user) {
        const userObject = JSON.parse(user);

        if (userObject.token) {
          config.headers.Authorization = `Bearer ${userObject.token}`;
        }
      }
  
      return config;
    },
    (error) => Promise.reject(error),
  );


  // // Bloco do interceptador de erros - Tratamento de erro
  // axiosHttp.interceptors.response.use(res => res, error => {
  //   const errorLimitePlanoExcedido = get<boolean>(error.response, 'data.limite_plano_excedido', false)
  //   const errorMensagem = get<string | null>(error.response, 'data.mensagem', null)

  //   if (has(error.response, 'status')) {
  //     switch (error.response.status) {
  //       // UNAUTHORIZED - Sessão expirada
  //       case 401:
  //         return new Promise((resolve) => {
  //           warningAlert(dialogoSessaoExpirada)
  //             .then(() => {
  //               window.location.href = '/login'
  //               resolve(Promise.reject(error))
  //             })
  //         })

  //       // FORBIDDEN - Não permitido/Erro de permissão
  //       case 403:
  //         // Condição amarrada para mensagem de plano execedido captacao
  //         if (errorLimitePlanoExcedido) {
  //           return avaliacaoPlanLimitExceeded(errorMensagem || '')
  //         }

  //         if (has(error.response, 'data.detail')) {
  //           return new Promise((resolve) => {
  //             errorAlert({
  //               title: 'Permissão negada!',
  //               text: get(error.response, 'data.detail'),
  //             })
  //               .then(() => {
  //                 resolve(Promise.reject(error))
  //               })
  //           })
  //         }

  //         return new Promise((resolve) => {
  //           errorAlert(dialogoPermissaoNegada)
  //             .then(() => {
  //               resolve(Promise.reject(error))
  //             })
  //         })

  //       // ERROS 400-500 - Tratamento padrão
  //       default:
  //         const message = error.response?.data?.message
  //         const mensagem = error.response?.data?.mensagem
  //         const mensagemErro = message || mensagem

  //         if (mensagemErro &&
  //           mensagemErro.includes('Cadastro aluno > Turma > Detalhamento da turma > Alterar situação ou Apagar')) {
  //           ipAlertaErroCancelamento()
  //           break
  //         }

  //         if (mensagemErro && mensagemErro.includes('garantida pelo isaac')) {
  //           ipAlertaErro()
  //           break
  //         }

  //         if (has(error.response, 'data.message')) {
  //           // @ts-ignore: o helper não possui declaração de tipos
  //           errorAlert(buildErrorAlertPayload(error, 'data.message'))
  //           break
  //         }
  //         if (has(error.response, 'data.mensagem') && error.response.data.mensagem !== 'Erro interno no servidor') {
  //           // @ts-ignore: o helper não possui declaração de tipos
  //           errorAlert(buildErrorAlertPayload(error, 'data.mensagem'))
  //           break
  //         }
  //         if (Array.isArray(error.response?.data)) {
  //           // @ts-ignore: o helper não possui declaração de tipos
  //           errorAlert(buildErrorAlertPayload(error, 'data[0]'))
  //           break
  //         }

  //         errorAlert(buildErrorAlertPayload(error))
  //     }
  //   }

  //   return Promise.reject(error)
  // })

  return axiosHttp
}
