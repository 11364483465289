import React from 'react';
import { IState } from '../types';

const Piaui = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick}  d="m 342.86666,191.0427 c 1.71157,-0.76053 3.72216,-1.64622 5.08494,-3.157 1.45907,-1.61755 2.60356,-3.66178 3.00814,-5.62005 0.73993,-3.58142 -0.70047,-5.49338 1.00819,-7.22229 1.22486,-1.23938 3.4035,-0.87239 5.16844,-0.78369 3.00159,0.15087 5.61418,2.68076 8.59973,2.33615 3.141,-0.36257 5.87335,-3.26492 8.83816,-4.66384 5.90432,-5.31311 4.76125,-4.19589 6.80894,-6.44034 1.59387,-1.74702 5.51619,-2.01461 6.87422,-4.36474 1.33942,-2.31791 -0.65227,-5.05496 0.34423,-8.02387 1.23526,-2.46056 2.65663,-5.77513 1.6777,-8.48797 -0.57701,-1.59901 -3.06556,-1.7705 -3.95572,-3.21874 -1.98466,-3.22896 -1.96609,-7.34702 -2.42002,-11.10986 -0.67003,-5.55422 0.3916,-11.24415 -0.43018,-16.77795 -0.64587,-4.34923 -4.03259,-6.99132 -3.50843,-12.71565 0.10022,-1.094478 0.19783,-1.779789 -2.16065,-2.23737 -1.45908,-0.143546 -1.40169,2.23455 -2.04818,3.18981 -2.71209,2.20372 -4.44852,1.29913 -6.19009,2.73226 -2.41369,1.98621 -4.22325,4.78066 -5.36732,7.68962 -2.47138,6.28383 0.82915,14.88422 -2.82753,20.05875 -1.27568,1.80518 6.66294,5.91192 0.7688,10.36343 -3.89092,2.46893 -7.83077,-1.08747 -11.30576,0.37541 -2.7318,1.15002 -4.12988,4.50372 -6.59477,6.1493 -3.57761,2.38843 -11.71491,1.76833 -11.01893,6.561 0.67283,4.63325 -4.93668,7.88965 -5.80067,12.35718 -0.46621,2.41064 0.17994,4.91032 0.41825,7.35403 0.26356,2.70273 0.0503,5.5726 1.11751,8.06964 1.16199,2.71873 2.78446,5.3586 5.50949,6.95133 3.4523,1.41612 5.45178,1.94566 8.40151,0.63545 z"></path>
      <text x="358" y="160">PI</text>
    </>
  );
};

export { Piaui };
export default Piaui;
