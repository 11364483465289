import React from 'react'
import styled from 'styled-components';

import { IShowInterface } from '@src/interfaces';

const TransparentButton = styled.button<IShowInterface>`
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-size: 1em;
  padding: 0.5em 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: ${props => props.show ? 'show' : 'none'};
  &:hover {
    background-color: white;
    color: black;
  }
  position: absolute;
  z-index: 100;
  top: 40%;
  left: 34%;
`;

interface IViewMoreButtonCardStamp {
  showButton: boolean,
}

const ViewMoreButtonCardStamp = (props: IViewMoreButtonCardStamp) => {
  return (
    <>
      <TransparentButton show={props.showButton}>
        Ver mais
      </TransparentButton>
    </>
  )
}

export { ViewMoreButtonCardStamp }
export default ViewMoreButtonCardStamp
