import React from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { ModalEditCollectionFormFields } from './components';
import { ICollection } from '@api/collection/types';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: IEditCollectionValues) => void;
  initialValues: IEditCollectionValues 
}

export interface IEditCollectionValues {
  id:number,
  name_collection: string;
  description_collection: string;
  image_collection: string;
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  z-index: 500;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 50%;
`;

const ModalTitle = styled.h2`
  margin-top: 0;
`;

const validationSchema = Yup.object().shape({
  name_collection: Yup.string().required('O título é obrigatório'),
  description_collection: Yup.string().nullable(),
  image_collection: Yup.string().nullable(),
});

const ModalEditCollection = (props: ModalProps) => {
  
  if (!props.isOpen) {
    return null;
  }

  return (
    <>
      <ModalContainer>
        <ModalContent>
          <ModalTitle>Editar Coleção</ModalTitle>
          <Formik
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <ModalEditCollectionFormFields
                onClose={() => props.onClose()}
                isSubmitting={isSubmitting}
                imageCollection={props.initialValues.image_collection}
              />
            )}
          </Formik>
        </ModalContent>
      </ModalContainer>
    </>
  );
};

export { ModalEditCollection };
export default ModalEditCollection;
