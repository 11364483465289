import React from 'react';
import { IState } from '../types';

const RioGrandeDoNorte = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick}  d="m 440.08193,141.8455 c 0.93731,-0.19208 1.64446,0.33175 2.77439,0.73601 1.51759,0.77865 7.76505,0.81048 11.64757,1.21572 -0.9147,-2.95401 -0.90023,-6.20869 -2.2294,-8.99836 -0.50783,-1.06584 -1.2653,-2.03181 -2.1334,-2.82734 -1.57341,-1.3898 -3.64876,-1.87266 -5.6876,-1.99881 -1.90687,-0.21248 -3.81126,-0.4706 -5.73456,-0.37878 -0.78937,-0.0354 -1.6221,0.0704 -2.38464,-0.0523 -2.4895,-1.68813 -4.48795,-4.65979 -7.46851,-5.0644 -1.39215,-0.18898 -2.58071,0.42206 -3.55079,1.4383 -1.99274,2.08756 -2.97902,3.77929 -4.49238,5.64981 -2.15346,2.66168 -6.25737,4.65598 -6.52779,7.93001 0.95051,3.10284 2.51822,3.32872 4.18673,2.8371 1.59896,-0.47113 4.2471,-1.59473 6.21436,-2.70568 1.64445,-0.92865 2.75874,-2.95671 4.61933,-3.28044 0.69288,-0.12055 1.53332,0.0727 2.03174,0.56882 0.45517,0.45312 0.56364,1.21181 0.52784,1.85307 -0.1362,2.43956 -4.05443,4.69745 -2.78033,6.78232 0.71619,1.17195 2.30165,0.73241 4.10562,0.34837 0.68597,0.82338 1.72681,2.22386 2.98151,2.31322 0.96913,0.069 2.10132,-0.57184 2.53289,-1.44231 0.52149,-1.05183 -0.93759,-2.24394 -0.46568,-3.4911 0.2745,-0.72543 1.07326,-1.27751 1.8331,-1.43323 z"></path>
      <circle onClick={props.onClick}  r="12" cx="442" cy="126"></circle>
      <text x="434" y="130">RN</text>
    </>
  );
};

export { RioGrandeDoNorte };
export default RioGrandeDoNorte;
