import React, { ReactElement, ReactNode } from 'react'

import { PulseLoader } from 'react-spinners';
import styled from 'styled-components';

interface ILoadingRenderIfProps {
  // Condição que define se o conteúdo será renderizado ou não.
  condition: boolean,

  width?: number,

  height?: number,

  // Conteúdo do componente para apresentação.
  children: ReactNode | void
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingRenderIf = (props: ILoadingRenderIfProps): ReactElement | null => {
  return (
    <>
      {props.condition ? (
        <>
          {props.children}
        </>
      ) : (
        <LoadingContainer
          style={{
            height: props.height ? props.height : 'auto',
            width: props.width ? props.width : 'auto',
          }}
        >
          <PulseLoader
            color={'#252525'}
            loading={!props.condition}
          />
        </LoadingContainer>
      )}
    </>
  )
}

export { LoadingRenderIf }
export default LoadingRenderIf
