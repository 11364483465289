import React from 'react';
import { IState } from '../types';

const Tocantins = (props: IState) => {
  return (
    <>
      <path onClick={props.onClick}  d="m 322.14907,216.92168 c 2.12498,-9.05841 -4.27296,-6.94687 0.33801,-12.23516 1.97776,-2.26829 -3.02148,-4.45747 -3.10597,-7.01263 -0.15778,-4.77157 4.40851,-10.47661 4.92235,-13.4501 0,0 -3.77329,-1.77787 -4.81878,-3.45523 -0.77625,-1.24541 -0.34908,-3.81793 -1.15853,-5.51193 -0.55019,-1.15142 -1.83321,-1.8332 -2.42016,-2.96632 -0.72995,-1.40917 -1.18923,-3.00199 -1.27696,-4.58657 -0.10849,-1.95935 0.5524,-3.88797 0.94323,-5.81101 0.32238,-1.58623 1.79599,-3.23046 1.15474,-4.71669 -0.63385,-1.46909 -3.07005,-1.21127 -4.17495,-2.36851 -2.05911,-2.15666 -3.40893,-5.06416 -4.04942,-7.97635 -0.67917,-3.08808 0.0751,-6.17341 0.34899,-9.47924 0.20927,-2.52596 0.19594,-5.66535 -0.99981,-8.11419 -0.98027,-2.00756 -2.82945,-3.58147 -4.74152,-4.73699 -1.64849,-0.99623 -2.82284,-1.26538 -5.51643,-1.72016 1.1855,2.21273 3.28774,2.97213 3.39066,7.1118 0.0388,1.55932 -0.94842,3.60143 -2.15397,5.16746 -1.82192,2.36671 -5.24017,5.09511 -6.22682,6.44307 -1.24551,1.7016 -3.32963,4.62976 -3.97456,7.33474 -0.52308,2.19388 0.51951,4.53182 0.19266,6.76339 -0.4021,2.74535 -1.36378,5.42569 -2.61323,7.90309 -2.08161,4.12739 -6.50443,6.95062 -7.99984,11.32779 -1.68941,1.81187 -6.88769,16.67328 -6.44193,25.70469 -0.0277,5.11757 0.1017,10.37468 1.72428,15.26591 1.76803,-1.93409 4.36561,-2.30806 4.22182,1.11486 -0.15113,3.28101 2.85424,4.64394 5.25178,5.34131 2.93291,0.85309 5.87411,-2.17297 8.92679,-2.06879 3.19081,0.10889 4.37943,1.50864 9.16157,2.79349 4.38112,0.76344 6.36051,-6.02115 10.3089,-7.06932 3.4801,-0.92385 8.00457,3.44255 10.7871,1.00759 z"></path>
      <text x="289" y="190">TO</text>
    </>
  );
};

export { Tocantins };
export default Tocantins;