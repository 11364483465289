import React, { useEffect, useState } from 'react'


import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { SuccessModal } from '@src/ui/components';
import CollectionClientAPI from '@src/api/collection/CollectionClientAPI';
import { ICollection } from '@src/api/collection/types';
import SelectCollectionAdminArea from './SelectCollectionAdminArea';
import { HomeClientAPI } from '@src/api/home';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 5px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const collectionClientAPI = new CollectionClientAPI()
const homeClientAPI = new HomeClientAPI()

const CollectionEdit = () => {
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false)
  const [collections, setCollections] = useState<ICollection[] | []>([])
  const [collectionSelectedId, setCollectionSelectedId] = useState<number>()
  const navigate = useNavigate();

  useEffect(() => {
    collectionClientAPI.getCollections()
      .then((response) => setCollections(response))
  }, [])

  const handleSubmit = () => {
    if (collectionSelectedId && collectionSelectedId != -1) {
      homeClientAPI.updateCollectionHome(collectionSelectedId)
        .then((response) => {
          setShowModalSuccess(true)
        })
    }
  }

  return (
    <>
      <Container>
        <SelectCollectionAdminArea
          collections={collections}
          setCollection={(id: number) => setCollectionSelectedId(id)}
        />
      </Container>
      <Button onClick={() => handleSubmit()}>Salvar</Button>

      <SuccessModal
        message='Operação realizada com sucesso!'
        show={showModalSuccess}
        onClose={() => {
          navigate(0)
        }}
      />
    </>
  )
}

export { CollectionEdit }
export default CollectionEdit
