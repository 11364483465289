import { AxiosResponse } from 'axios'

import { ClienteAPI } from '@api/ClientAPI'

export interface IRepresentative {
  id: number
  code: number
  name_representative: string
  cellphone_number_representative: string
  email_representative: string
  state_id: number
  created_at: string
  updated_at: string
}

interface IUserForm {
  email: string,
  password: string,
}

class LoginClientAPI extends ClienteAPI {
  protected baseURL: string = '/api/login'

  async login (values: IUserForm): Promise<any> {
    const response : AxiosResponse<any> = await this.axios.post(
      `${this.baseURL}`, values
    )

    return response.data.data;
  }
}

export { LoginClientAPI }
export default LoginClientAPI
